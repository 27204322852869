import { createSlice } from '@reduxjs/toolkit'
// types
import { IMessage } from './model.types';

export interface IMessagesState {
  isLoading: boolean,
  data: IMessage[],
}

const initialState: IMessagesState = {
  isLoading: false,
  data: [],
}

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {},
})

const { reducer: messagesReducer } = messagesSlice;

export default messagesReducer;
