import { AnyAction, combineReducers } from '@reduxjs/toolkit';
// slices
import authReducer from 'core/models/auth/slice';
import downloadsReducer from 'core/models/packages/slice';
import errorReducer from 'core/models/error/slice';
import modalReducer from 'core/models/modal/slice';
import statisticsReducer from 'core/models/statistics/slice';
import historyReducer from 'core/models/events/slice';
import messagesReducer from 'core/models/messages/slice';
import handbookReducer from 'core/models/handbook/slice';
import usersReducer from 'core/models/users/slice';
// constants
import { logoutThunkActionType } from 'core/models/auth/thunks';

const appReducer = combineReducers({
  auth: authReducer,
  downloads: downloadsReducer,
  history: historyReducer,
  messages: messagesReducer,
  modal: modalReducer,
  statistics: statisticsReducer,
  error: errorReducer,
  handbook: handbookReducer,
  users: usersReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === `${logoutThunkActionType}/fulfilled`) {
    state = undefined;
  }
  return appReducer(state, action)
}

export default rootReducer;