// styles
import * as S from './style';

interface IEllipsisTitle {
  value: string,
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  className?: string,
}

const EllipsisTitle = ({
  value,
  type,
  className,
}: IEllipsisTitle) => {
  switch (type) {
    case 'h1': return <S.H1 title={value} className={className}>{value}</S.H1>
    case 'h2': return <S.H2 title={value} className={className}>{value}</S.H2>
    case 'h3': return <S.H3 title={value} className={className}>{value}</S.H3>
    case 'h4': return <S.H4 title={value} className={className}>{value}</S.H4>
    case 'h5': return <S.H5 title={value} className={className}>{value}</S.H5>
    case 'h6': return <S.H6 title={value} className={className}>{value}</S.H6>
  }
}

export default EllipsisTitle;
