export const localeDateFormat = 'MM/DD/YYYY, hh:mm:ss';

export const ISODateFormat = 'YYYY-MM-DDThh:mm:ss'

export const filtersAPIOutputDateFormat = 'YYYY-MM-DD hh:mm:ss'

export const filtersAPIInputDateFormat = 'YYYY-MM-DD hh:mm'

export const APIIntputDateFormat = 'YYYY-MM-DD'

export const userDateFormat = 'DD.MM.YYYY';

export const userTimeFormat = 'hh:mm:ss';

export const shortUserTimeFormat = 'hh:mm';

export const filterDateFormat = `${userDateFormat}, ${shortUserTimeFormat}`;

export const userDateTimeFormat = `${userDateFormat} ${userTimeFormat}`;
