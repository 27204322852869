// types
import { AnyFunctionType } from 'core/types/simple.types';
// styles
import * as S from './style';

interface IOptionsButton {
  onClick: AnyFunctionType,
  isActive: boolean,
}
const OptionsButton = ({
  onClick,
  isActive,
}: IOptionsButton) => {
  return (
    <S.Button type='button' onClick={onClick} $isActive={isActive}>
      <span></span>
      <span></span>
      <span></span>
    </S.Button>
  )
}

export default OptionsButton;