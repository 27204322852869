import styled, { keyframes } from "styled-components";
// icons
import loaderIcon from 'assets/icons/loader.svg';

const LoaderRotation = keyframes`
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
`

export const Loader = styled.div`
  width: 32px;
  height: 32px;
  background: url(${loaderIcon}) no-repeat center/cover;
  opacity: 1;
  animation: 2s infinite ${LoaderRotation} linear;
`;