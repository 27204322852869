// hooks
import { useElemSize } from 'core/hooks/useElemSize';
// styles
import * as S from './style';

interface ITooltip {
  text: string,
}

const Tooltip = ({
  text,
}: ITooltip) => {
  const [textRef, textWidth] = useElemSize();

  return (
    <S.Wrapper>
      <S.Icon />
      <S.Text $left={-textWidth / 2} ref={textRef}>{text}</S.Text>
    </S.Wrapper>
  )
}

export default Tooltip;