import styled from "styled-components";
// Components
import { PreviewCell } from "components/common";
// styles
import { solidBorderProperty } from "styles/common";

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

interface WrapperProps {
  $lastRowItemsCount: number,
}

export const Wrapper = styled.div<WrapperProps>`
  padding-bottom: 12px;
  
  &>${Inner} {
    padding: 0 12px;
    border-right: ${solidBorderProperty};
  }

  &:nth-child(4n + 1) {
    &>${Inner} {
      padding-left: 0;
    }
  }

  &:nth-child(4n) {
    &>${Inner} {
      padding-right: 0;
      border-right: none;
    }
  }

  &:not(:nth-last-of-type(-n+${({ $lastRowItemsCount }) => $lastRowItemsCount})) {
    border-bottom: ${solidBorderProperty};
  }

  &:last-child {
    &>${Inner} {
      border-right: none;
    }
  }
`;

export const StyledPreviewCell = styled(PreviewCell)`
  height: 248px;
`;
