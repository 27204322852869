import { fileFormats } from "core/constants/files";
import { MimeTypes } from "core/types/advanced.types";

export const cutSubStrArr = (str: string, subStrArr: string[]): string => {
  let newStr = str;
  subStrArr.forEach(subStr => {
    newStr = newStr.replaceAll(subStr, '')
  });

  return newStr;
}

export const isFile = (file: unknown): file is Blob | ArrayBuffer => {
  if (file instanceof Blob) return true;
  if (file instanceof ArrayBuffer) return true;

  return false;
}

export const getFileMimeType = (filename: string): MimeTypes | '' => {
  const formatsStr = '\\' + fileFormats.join('|\\');
  return filename.match(new RegExp(formatsStr, 'g'))?.[0] as MimeTypes || '';
}

export const getIsSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
