import { useCallback, useRef } from 'react';
// types
import { IModalState } from 'core/models/modal/slice';
// config
import { notificationTabs } from 'core/constants/tabs';
// hooks
import { useScrollAutoFetch } from 'core/hooks/useScrollAutoFetch';
// hocs
import { WithUserCheckType, withModalFetch, withUserCheck } from 'core/hocs';
// components
import NotificationsList from 'components/lists/NotificationsList';
import Settings from './components/Settings';
// styles
import * as S from './style';
import { IPagination } from 'core/models/types/RequestProps.types';
import { useAppDispatch } from 'core/store';
import { fetchNotificationsThunk } from 'core/models/events/thunks';
import { getNotificationsSettings } from 'core/config/userData';

interface INotificationsModal extends WithUserCheckType {
  tab: number,
  data: IModalState['data']['notifications'],
}

const NotificationsModal = ({
  tab,
  data,
  user,
}: INotificationsModal) => {
  const wrapperRef = useRef<HTMLParagraphElement | null>(null);
  const dispatch = useAppDispatch();

  const fetchNotifications = useCallback(({ pageSize, pageCount }: IPagination) => {
    dispatch(fetchNotificationsThunk({ data: { pageSize, pageCount, } }));
  }, [dispatch]);

  const [listRef] = useScrollAutoFetch('notifications', { fetchAction: fetchNotifications, scrollTarget: wrapperRef.current, listItemsCount: data?.length || 0 });

  return (
    <S.Wrapper ref={wrapperRef}>
      {tab === notificationTabs[0].value &&
        <NotificationsList notifications={data || []} listRef={listRef} />
      }
      {tab === notificationTabs[1].value &&
        <Settings settings={getNotificationsSettings(user)} />
      }
    </S.Wrapper>
  )
}

export default withModalFetch(withUserCheck(NotificationsModal), 'notifications', { withFetch: false, });
