import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { iconInputWrapperStyle, simpleText } from "styles/common";

export const InputField = styled.input`
  width: 100%;
  ${iconInputWrapperStyle};
  ${simpleText};

  &:focus {
    border: 2px solid ${theme.color.blue};
  }
`