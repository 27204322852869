import styled from "styled-components";
// styles
import { getIconElemStyle, simpleText } from "styles/common";
import theme, { ThemeIconsType } from "core/theme/theme";

interface ButtonProps {
  $icon: ThemeIconsType,
}

export const Button = styled.button<ButtonProps>`
  gap: 0 4px;
  padding: 4px 8px 4px 4px;
  ${simpleText};
  color: ${theme.color.white};

  &:disabled {
    color: ${theme.color.silverGray};

    &::before {
      color: ${theme.color.silverGray};
    }
  }

  ${({ $icon }) => $icon ? getIconElemStyle($icon, 24, 'white') : ''};
`;