import styled from "styled-components";
// styles
import { ellipsisStyles } from "styles/common";

export const H1 = styled.h1`
  ${ellipsisStyles};
`;

export const H2 = styled.h2`
  ${ellipsisStyles};
`;

export const H3 = styled.h3`
  ${ellipsisStyles};
`;

export const H4 = styled.h4`
  ${ellipsisStyles};
`;

export const H5 = styled.h5`
  ${ellipsisStyles};
`;

export const H6 = styled.h6`
  ${ellipsisStyles};
`;