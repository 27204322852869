import { createGlobalStyle } from 'styled-components';
// Load material icons font
import 'assets/font.css';
import 'assets/icons.css';
// Reset custom browser styles
// import './normalize.css';
// Fix iPhone input zoom
// import './iPhoneFix.css';
// React Toastify
// import 'react-toastify/dist/ReactToastify.min.css';

import theme from '../core/theme/theme';

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%; 
  }

  *, *:before, *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
		font: inherit;
		color: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
		line-height: 17px;
    color: ${theme.color.black};
    -webkit-overflow-scrolling: touch;
  }

  ol, ul {
    list-style: none;
  }

  p, ul, li {
    margin: 0;
    padding: 0;
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 0;
    background-color: transparent;
    &:focus {
      outline: 0;
      box-shadow: 0;
    }
  }  


  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 0;
    &:disabled {
      cursor: initial;
    }

    &>img {
      display: block;
    }
  }

	a {
		text-decoration: none;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
      appearance: textfield; /* Firefox */
  }

`;

export default GlobalStyles;
