// types
import { IMessage } from 'core/models/messages/model.types';
import { ReplyToMessageType } from 'components/modals/Messages';
import { WithUserCheckType } from 'core/hocs/withUserCheck';
import { AnyFunctionType } from 'core/types/simple.types';
// hooks
import { useModalSelector } from 'core/store/selectorHooks';
// utils
import { chatStatuses } from 'core/config/statuses.config';
import { UTCFormatDate } from 'core/utils/date';
// hocs
import { withUserCheck } from 'core/hocs';
// components
import ChatAvatar from 'components/common/ChatAvatar';
// styles
import * as S from './style';
import { RefCallback } from 'react';

interface IMessageState extends WithUserCheckType {
  message: IMessage,
  selected?: boolean,
  replyToMessage: ReplyToMessageType,
  messageRefCallback?: RefCallback<HTMLParagraphElement | null>,
  onHover?: AnyFunctionType,
}

const Message = ({
  message,
  user,
  selected = false,
  messageRefCallback,
  replyToMessage,
  onHover,
}: IMessageState) => {
  const { package: packageEntity } = useModalSelector('data');

  const isAuthorMessage = message.sender.id === packageEntity?.correspondent.userId;
  const isCurrentUserMessage = user.id === message.sender.id;
  const isCurrentUserAuthor = user.id === packageEntity?.correspondent.userId;
  const messageStatus = chatStatuses.find(item => item.value === message.newEditingStatus);

  return (
    <S.Message ref={messageRefCallback} $nested={Boolean(message.answerMessageId)} $selected={selected} onMouseEnter={onHover}>
      <S.Info>
        <ChatAvatar avatar={'message.senderName.avatar'} />
        <S.InfoHeader>
          <h4>{message.sender.fullName} {isCurrentUserMessage && '(Вы)'}</h4>
          <p>{message.sender.position} / {UTCFormatDate(message.createdDate)}</p>
        </S.InfoHeader>
      </S.Info>
      <S.Text>{message.content}</S.Text>
      {messageStatus &&
        <S.StyledStatus status={messageStatus} />
      }
      {(isCurrentUserAuthor ? !isCurrentUserMessage : isAuthorMessage) &&
        <S.Reply
          type="button"
          onClick={() => replyToMessage({ answerMessageId: message.id, answeredUser: message.sender, })}
        >
          Ответить
        </S.Reply>
      }
    </S.Message>
  )
}

export default withUserCheck(Message);