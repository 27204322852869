// types
import { IStatus } from 'core/types/status.types';
import { AnyFunctionType } from 'core/types/simple.types';
// styles
import * as S from './style';

interface IStatusState {
  status: IStatus,
  checked?: boolean,
  className?: string,
  small?: boolean,
  onClick?: AnyFunctionType,
}

const Status = ({
  status,
  checked = false,
  className,
  small = false,
  onClick,
}: IStatusState) => {

  return (
    <S.Status
      className={className}
      type='button'
      onClick={onClick ? (e) => onClick(status.value, e) : undefined}
      $color={status.color}
      $checked={checked}
      $small={small}
      $isButton={Boolean(onClick)}
    >
      {status.title}
    </S.Status>
  )
}

export default Status;