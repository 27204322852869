// types
import { ClickEventType } from 'core/types/simple.types';
import { UserPermissionsType } from 'core/models/users/model.types';
import type { IOptionCell } from '../PackageItem';
import { StatusesTypes } from 'core/models/handbook/model.types';
// actions
import { changePackageStatusThunk } from 'core/models/packages/thunks';
import { openMessagesModal } from 'core/models/modal/slice';
// hooks
import { useElemSize } from 'core/hooks/useElemSize';
import { useAppDispatch } from 'core/store';
// config
import { downloadStatuses } from 'core/config/statuses.config';
// hocs
import { withUserCheck } from 'core/hocs';
// components
import { Cell } from 'components/common';
// styles
import * as S from './style';

interface IStatusCell extends IOptionCell {
  permissions: UserPermissionsType,
};

const StatusCell = ({
  permissions,
  packageEntity,
  optionsOpenned,
  setOptionsOpenned,
}: IStatusCell) => {
  const dispatch = useAppDispatch();
  const [statusCellRef, , statusCellHeight] = useElemSize();

  const onStatusButtonClick = (_: unknown, e: ClickEventType) => {
    e.stopPropagation();
    setOptionsOpenned(!optionsOpenned);
  }

  const onStatusClick = (status: StatusesTypes) => {
    switch (status) {
      case 'new':
      case 'at_work': {
        dispatch(changePackageStatusThunk({ data: { id: packageEntity.uploadId, status, }, actionData: {} }));
        break;
      }
      case 'published':
        dispatch(openMessagesModal({ package: packageEntity, statusAction: 'published', }));
        break;
      case 'send_to_social_networks':
        dispatch(openMessagesModal({ package: packageEntity, statusAction: 'send_to_social_networks', }));
        break;
      case 'rejected':
        dispatch(openMessagesModal({ package: packageEntity, statusAction: 'rejected', }));
        break;
      default: break;
    }
    setOptionsOpenned(false);
  }

  return (
    <S.StatusCell ref={statusCellRef}>
      <Cell
        title='Статус обработки загрузки'
        value={downloadStatuses.find(item => item.value === packageEntity.editingStatus) || ''}
        onClick={permissions.downloads.editStatus ? onStatusButtonClick : undefined}
      />
      {optionsOpenned &&
        <S.StyledStatusesMenu
          statuses={downloadStatuses.filter(item => item.value !== packageEntity.editingStatus)}
          onClick={onStatusClick}
          $top={statusCellHeight}
        />
      }
    </S.StatusCell>
  )
}

export default withUserCheck(StatusCell);
