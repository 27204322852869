import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// utils
import { getCookie, removeCookie } from 'core/utils/cookies/cookie';
import history from 'core/utils/history';
// types
import { UIErrorsType } from 'core/types/UIErrors.types';
// thunks
import { loginThunk, logoutThunk } from './thunks';
// config
import { routesPublic } from 'core/constants/routes';

interface ISetLoginErrorsData { errors: UIErrorsType, }

export interface IAuthState {
  token: string,
  loginErrors: UIErrorsType,
}

const initialState: IAuthState = {
  token: getCookie('token'),
  loginErrors: {},
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginErrors: {
      reducer: (state, { payload }: PayloadAction<ISetLoginErrorsData>) => {
        state.loginErrors = payload.errors;
      },
      prepare: (data: ISetLoginErrorsData) => {
        return { payload: data };
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loginThunk.rejected, (state, { payload }) => {
      if (payload && payload.status === 401) {
        state.loginErrors[payload.message] = payload.dataMessage;
      }
    });
    builder.addCase(loginThunk.fulfilled, (state) => {
      state.loginErrors = initialState.loginErrors;
    });

    builder.addCase(logoutThunk.fulfilled, (state, { payload }) => {
      removeCookie('token');
      history.push(routesPublic.AUTH);
    });
  },
})

const { actions, reducer: authReducer } = authSlice;

export const { setLoginErrors } = actions;

export default authReducer;
