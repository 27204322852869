import styled from "styled-components";
// components
import { Tabs } from "components/common";
// constants
import { modalWrapperPadding } from "core/constants/values";
// styles
import theme from "core/theme/theme";
import { simpleText, solidBorderProperty } from "styles/common";

interface InnerProps {
  $width: string,
  $height: string,
  $left: string,
}

export const Inner = styled.div<InnerProps>`
  min-width: 440px;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: 0 0 12px;
  position: fixed;
  left: ${({ $left }) => $left};
  z-index: ${theme.zIndex.superPuper};
  overflow-y: auto;
  background-color: ${theme.color.white};
`;

interface WrapperProps {
  $aside: boolean,
}

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: ${solidBorderProperty};
`;

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${theme.zIndex.modal};
  display: flex;
  align-items: flex-start;
  justify-content: ${({ $aside }) => $aside ? 'flex-end' : 'center'};
  width: 100%;
  height: 100%;
  padding: ${({ $aside }) => $aside ? 0 : `${modalWrapperPadding}px`};
  background-color: rgba(0, 0, 0, 0.25);

  &+${Inner} {
    right: ${({ $aside }) => $aside ? 0 : 'auto'};
    top: ${({ $aside }) => $aside ? 0 : `${modalWrapperPadding}px`};
    max-height: ${({ $aside }) => $aside ? '100%' : `calc(100% - ${modalWrapperPadding * 2}px)`};

    &>${Header} {
      padding: ${({ $aside }) => $aside ? '20px 16px 0' : '16px'};
      align-items: ${({ $aside }) => $aside ? 'flex-start' : 'center'};
    }
  }
`;

export const Title = styled.h4`
  ${simpleText};

  &>span {
    color: ${theme.color.silverGray};
  }
`;

export const StyledTabs = styled(Tabs)`
  padding-left: 8px;
`;