import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { ellipsisStyles, simpleText, smallText } from "styles/common";

const previewWidth = 60;

const InfoWidthArg = previewWidth + 8;

interface NotificationProps {
  $read: boolean,
}

export const Wrapper = styled.div<NotificationProps>`
  display: flex;
  align-items: flex-start;
  gap: 0 8px;
  padding: 8px;
  background-color: ${({ $read }) => $read ? 'transparent' : theme.color.lightSmokeBlue};
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover {
    background-color: ${theme.color.smokeBlue};
  }
`;

export const Preview = styled.img`
  width: ${previewWidth}px;
  height: 60px;
  min-width: ${previewWidth}px;
  min-height: 60px;
`;

export const Info = styled.div`
  width: calc(100% - ${InfoWidthArg}px);
`;

export const InfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  &>p {
    ${smallText};
    color: ${theme.color.silverGray};
  }
`;

export const Message = styled.p`
  ${ellipsisStyles};
`;


export const InfoText = styled.div`
  ${simpleText};
  margin-bottom: 2px;
`;

export const InfoFooter = styled.p`
  ${smallText};
  color: ${theme.color.silverGray};

  &>span {
    font-weight: 700;
  }
`;
