import { useNavigate } from 'react-router-dom';
// types
import { EventEntryType } from 'core/models/events/model.types';
// actions
import { closeModal, setMessagesModalHoverId } from 'core/models/modal/slice';
// thunks
import { readNotificationThunk } from 'core/models/events/thunks';
// hooks
import { useAppDispatch } from 'core/store';
// utils
import { formatDate } from 'core/utils/date';
// constants
import { ISODateFormat, shortUserTimeFormat } from 'core/constants/dateFormats';
import { searchQuery } from 'core/constants/queryParams';
import { uploadNotificationMessage } from 'core/constants/values';
// components
import { StatusEvent } from 'components/common';
// styles
import * as S from './style';

interface IDescription {
  notification: EventEntryType
}

const Description = ({ notification }: IDescription) => {
  switch (notification.eventType) {
    case 'new_message': return (
      <S.Message title={notification.message.message || ''}>
        {notification.message.message || ''}
      </S.Message>
    )
    case 'upload': return (
      <S.Message>{uploadNotificationMessage}</S.Message>
    )
    case 'change_status': return (
      <StatusEvent oldStatusValue={notification.oldStatus} newStatusValue={notification.newStatus} />
    )
    default: return <p></p>
  }
}

interface INotificationItem {
  notification: EventEntryType
}

const NotificationItem = ({
  notification
}: INotificationItem) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const readNotification = (item: EventEntryType) => {
    if (item.isEventNew) {
      dispatch(readNotificationThunk({ data: { eventId: item.eventId } }));
    }
    if (item.eventType === 'new_message' && item.message) {
      dispatch(setMessagesModalHoverId({ id: item.message?.id, }));
    }
    navigate({ search: `?${searchQuery}=id:${item.uploadId}` });
    dispatch(closeModal());
  }

  return (
    <S.Wrapper $read={!notification.isEventNew} onClick={() => readNotification(notification)}>
      <S.Preview src={notification.previewUrl} />
      <S.Info>
        <S.InfoHeader>
          <p>ID {notification.uploadId}</p>
          <p>{formatDate(notification.timestamp, ISODateFormat, shortUserTimeFormat)}</p>
        </S.InfoHeader>
        <S.InfoText>
          <Description notification={notification} />
        </S.InfoText>
        <S.InfoFooter>
          От <span>{notification.eventAuthor}</span>
        </S.InfoFooter>
      </S.Info>
    </S.Wrapper>
  )
}

export default NotificationItem;
