// types
import { IMessage } from "core/models/messages/model.types";

export const combineMessages = (messages: IMessage[]): IMessage[][] => {
  const combined = messages.reduce<Record<number, IMessage[]>>((acc, item) => {
    let combinedValue = [];
    if (!((item.answerMessageId || item.id) in acc)) {
      combinedValue = [item];
    } else if (item.answerMessageId !== null) {
      combinedValue = [...acc[item.answerMessageId], item];
    } else {
      combinedValue = [item, ...acc[item.id]];
    }

    return {
      ...acc,
      [item.answerMessageId || item.id]: combinedValue,
    }
  }, {});

  return Object.values(combined);
}
