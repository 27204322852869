// types
import { ValueOf } from "core/types/simple.types";

export enum routesPrivate {
  DOWNLOADS = '/downloads',
  STATISTICS = '/statistics',
}

export enum routesPublic {
  AUTH = '/auth',
  NOTFOUND = '/error',
}

const combinedRoutes = { ...routesPrivate, ...routesPublic };

type CombinedRoutesType = typeof combinedRoutes;
export type RoutesType = ValueOf<CombinedRoutesType>;
