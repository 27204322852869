import styled from "styled-components";
// types
import { ErrorIconType } from "core/models/error/model.types";
// icons
import logoSettings from 'assets/icons/logoSettings.svg';
import logo from 'assets/icons/logo.svg';
// components
import { Button } from 'components/UI';
// styles
import { bigTitleText, superTitleText } from "styles/common";
import theme from "core/theme/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Inner = styled.main`
  width: 676px;
  margin: 0 auto;
`;

interface IconProps {
  $iconType: ErrorIconType
}

export const Icon = styled.div<IconProps>`
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 50px;
  background: url(${({ $iconType }) => $iconType === 'logo' ? logo : logoSettings}) no-repeat center/cover;
`;

export const Message = styled.div`
  margin-bottom: 40px;
  white-space: pre-line;

  &>h4 {
    ${superTitleText};
    text-align: center;
  }

  &>p {
    ${bigTitleText};
    text-align: center;
    font-weight: 400;
    margin-top: 5px;

    &:nth-of-type(1) {
      margin-top: 20px;
    }

    &>a {
      padding-bottom: 2px;
      color: ${theme.color.blue};
      border-bottom: 2px solid ${theme.color.darkSmokeBlue};
    }
  }
`;

export const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
`;
