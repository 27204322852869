import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { getIconElemStyle } from "styles/common";

export const Button = styled.button`
  width: 20px;
  height: 20px;

  ${getIconElemStyle(theme.icons.close)}
`;