import styled, { css } from "styled-components";
// constants
import { packageItemAvarageHeight } from "core/constants/values";
// components
import { PreviewCell } from "components/common";
// styles
import theme from "core/theme/theme";
import { templateBorder } from "styles/common";

interface InnerProps {
  $border: boolean,
}

export const Inner = styled.div<InnerProps>`
  display: grid;
  grid-template-columns: 195px 1fr 0.6fr 0.8fr 0.6fr 0.2fr;
  gap: 0 20px;
  padding: 20px 0;
  cursor: pointer;
  ${({ $border }) => $border ? templateBorder : ''};
`;

const wrapperBorderStyle = css`
  border: 1px solid ${theme.color.libertyBlue};
  padding-left: 11px;
  padding-right: 11px;

  &>${Inner} {
    padding-top: 19px;
    padding-bottom: 19px;
  }
`;

interface WrapperProps {
  $border: boolean,
}

export const Wrapper = styled.li<WrapperProps>`
  min-height: ${packageItemAvarageHeight}px;
  padding: 0 12px;
  ${({ $border }) => $border ? wrapperBorderStyle : templateBorder};

  &:hover {
    ${wrapperBorderStyle};
  }
`;

export const StyledPreviewCell = styled(PreviewCell)`
  height: 150px;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`;
