// utils
import createAsyncThunkExtended, { createAsyncThunkExtendedNoActionData, createAsyncThunkExtendedNoData } from "core/store/extendedThunk";
// api
import { fetchNotificationsReq, fetchPackageHistoryReq, readAllNotificationsReq, readNotificationReq, registerDownloadEventReq, updateNotificationsSettingReq } from "./events.api";

export const fetchPackageHistoryThunk = createAsyncThunkExtendedNoActionData(
  'downloads/history/fetch',
  fetchPackageHistoryReq,
);

export const fetchNotificationsThunk = createAsyncThunkExtendedNoActionData(
  'notifications/fetch',
  fetchNotificationsReq,
);

export const readNotificationThunk = createAsyncThunkExtendedNoActionData(
  'notification/read',
  readNotificationReq,
);

export const readAllNotificationsThunk = createAsyncThunkExtendedNoData(
  'notifications/read',
  readAllNotificationsReq,
);

export const updateNotificationsSettingsThunk = createAsyncThunkExtended(
  'notifications/settings/update',
  updateNotificationsSettingReq,
  (response, _, { settingType, value }) => {
    return { data: response, settingType, value, }
  }
);

export const registerDownloadEventThunk = createAsyncThunkExtendedNoActionData(
  'events/download/register',
  registerDownloadEventReq,
)
