// utils
import { formatDate, getDisabledPeriods } from "core/utils/date";
// config
import { APIIntputDateFormat, filtersAPIInputDateFormat, filtersAPIOutputDateFormat, shortUserTimeFormat, userDateFormat } from "core/constants/dateFormats";
// components
import { InputDate } from "components/UI";
import InputTime from "components/UI/InputTime";
// styles
import * as S from "./style";
import { useEffect, useState } from "react";

interface IDateFilter {
  startDate: string | undefined,
  endDate: string | undefined,
  withTime?: boolean,
  setFilterValue: (startDate: string, endDate: string) => void;
}

const DateFilter = ({
  withTime,
  startDate,
  endDate,
  setFilterValue,
}: IDateFilter) => {
  const [startDisabledPeriod, endDisabledPeriod] = getDisabledPeriods(startDate || '', endDate || '');
  const [startDateOldValue, setStartDateOldValue] = useState(startDate);
  const [endDateOldValue, setEndDateOldValue] = useState(endDate);
  const [startDateValue, setStartDateValue] = useState(startDate ? formatDate(startDate, filtersAPIInputDateFormat, APIIntputDateFormat) : '');
  const [startTimeValue, setStartTimeValue] = useState(startDate ? formatDate(startDate, filtersAPIInputDateFormat, shortUserTimeFormat) : '');
  const [endDateValue, setEndDateValue] = useState(endDate ? formatDate(endDate, filtersAPIInputDateFormat, APIIntputDateFormat) : '');
  const [endTimeValue, setEndTimeValue] = useState(endDate ? formatDate(endDate, filtersAPIInputDateFormat, shortUserTimeFormat) : '');

  const onStartDateChange = (value: string) => {
    setStartDateValue(value);
  }

  const onEndDateChange = (value: string) => {
    setEndDateValue(value);
  }

  const onStartTimeChange = (value: string) => {
    setStartTimeValue(value);
  }

  const onEndTimeChange = (value: string) => {
    setEndTimeValue(value);
  }

  useEffect(() => {
    const startFullDateValue = `${startDateValue}${startTimeValue ? ` ${startTimeValue}` : ''}`;
    const endFullDateValue = `${endDateValue}${endTimeValue ? ` ${endTimeValue}` : ''}`;

    if (startDateValue && endDateValue && (startFullDateValue !== startDate || endFullDateValue !== endDate) && (startDate || endDate || !startDateOldValue || !endDateOldValue)) {
      setStartDateOldValue(startDateValue);
      setEndDateOldValue(endDateValue);
      setFilterValue(startFullDateValue, endFullDateValue);
    }
  }, [endDate, endDateOldValue, endDateValue, endTimeValue, setFilterValue, startDate, startDateOldValue, startDateValue, startTimeValue]);

  return (
    <S.Wrapper>
      <S.Label>Начало</S.Label>
      <S.InputsWrapper>
        <InputDate
          value={formatDate(startDateValue, filtersAPIOutputDateFormat, userDateFormat)}
          disabledPeriod={startDisabledPeriod}
          type='start'
          changeHandler={onStartDateChange}
        />
        {withTime &&
          <InputTime
            initValue={startTimeValue}
            setFilterValue={onStartTimeChange}
            isDisabled={!startDateValue}
            placeholder={startDateValue ? '00:00' : 'чч:мм'}
          />
        }
      </S.InputsWrapper>
      <S.Label>Конец</S.Label>
      <S.InputsWrapper>
        <InputDate
          value={formatDate(endDateValue, filtersAPIOutputDateFormat, userDateFormat)}
          disabledPeriod={endDisabledPeriod}
          type='finish'
          changeHandler={onEndDateChange}
        />
        {withTime &&
          <InputTime
            initValue={endTimeValue}
            setFilterValue={onEndTimeChange}
            isDisabled={!endDateValue}
            placeholder={endDateValue ? '23:59' : 'чч:мм'}
          />
        }
      </S.InputsWrapper>
    </S.Wrapper>
  )
}

export default DateFilter;