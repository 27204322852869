// types
import { EventEntryType } from 'core/models/events/model.types';
import { NullableRefType } from 'core/types/simple.types';
// thunks
import { readAllNotificationsThunk } from 'core/models/events/thunks';
// hooks
import { useModalSelector, useUsersSelector } from 'core/store/selectorHooks';
import { useAppDispatch } from 'core/store';
// utils
import { combineNotifications } from './utils/combineNotifications';
import { toWordsFormat } from 'core/utils/date';
// constants
import { loadingMessage } from 'core/constants/values';
// components
import NotificationItem from './components/NotificationItem';
// styles
import * as S from './style';

interface INotificationsList {
  notifications: EventEntryType[],
  listRef: NullableRefType,
}

const NotificationsList = ({
  notifications,
  listRef,
}: INotificationsList) => {
  const dispatch = useAppDispatch();
  const combinedNotifications = combineNotifications(notifications);
  const { notificationsIsLoading } = useModalSelector(undefined);
  const { notificationsCounter } = useUsersSelector(undefined);

  const readAllHandler = () => {
    dispatch(readAllNotificationsThunk());
  }

  if (notificationsIsLoading && !notifications.length) return (
    <S.NoItems>{loadingMessage}</S.NoItems>
  )

  if (!combinedNotifications.length) return (
    <S.NoItems>Уведомлений нет</S.NoItems>
  )

  return (
    <S.Wrapper ref={listRef}>
      <S.ReadAll type='button' onClick={readAllHandler} disabled={!notificationsCounter}>Прочитать все</S.ReadAll>
      {combinedNotifications.map((group, groupKey) =>
        <S.Group key={groupKey}>
          <S.Date>{toWordsFormat(group[0].timestamp)}</S.Date>
          {group.map((item, key) =>
            <NotificationItem key={key} notification={item} />
          )}
        </S.Group>
      )}
    </S.Wrapper>
  )
}

export default NotificationsList;