import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { ToastContainer } from "react-toastify";

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__progress-bar {
    background: ${theme.color.blue};
  }
`;

export default StyledToastContainer;
