import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { TooltipPointerStyle, getIconElemStyle, invisibleStyle, visibleStyle } from "styles/common";

export const Wrapper = styled.div`
  position: relative;
  z-index: ${theme.zIndex.elem};
`;

export const Icon = styled.div`
  cursor: pointer;

  ${getIconElemStyle(theme.icons.help, 16)}

  &:hover {
    &+p {
      ${visibleStyle};
    }
  }
`;

interface TextProps {
  $left: number,
}

export const Text = styled.p<TextProps>`
  ${invisibleStyle};
  width: fit-content;
  white-space: nowrap;
  padding: 8px 12px;
  background-color: ${theme.color.black};
  color: ${theme.color.white};
  font-size: ${theme.fontSize.S};
  line-height: 16px;
  position: absolute;
  top: -40px;
  left: ${({ $left }) => `calc(50% + ${$left}px)`};

  &::after {
    ${TooltipPointerStyle};
    bottom: -5px;
  }
`;
