import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { getIconElemStyle, solidBorderProperty } from "styles/common";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;
  padding-left: 12px;
  border-left: ${solidBorderProperty};
`;

export const UserInfo = styled.div``;

export const UserName = styled.p`
  color: ${theme.color.black};
  font-size: 14px;
  line-height: 20px;
`;

export const UserRole = styled.p`
  color: ${theme.color.silverGray};
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
`;

export const LogoutButton = styled.button`
  ${getIconElemStyle(theme.icons.logout, 24)}
`;