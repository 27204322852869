import styled from "styled-components";
// style
import theme from "core/theme/theme";
import { simpleText } from "styles/common";

interface TextProps {
  $isLimitExceeded: boolean,
}

export const Text = styled.p<TextProps>`
  ${simpleText};

  &>span {
    &:first-child {
      color: ${({ $isLimitExceeded }) => $isLimitExceeded ? theme.color.red : ''}
    }
  }
`;