// types
import { ClickEventType } from 'core/types/simple.types';
// styles
import * as S from './style';

interface IVideoLine {
  progressPercent: number,
  bufferedPercent: number,
  videoTarget: HTMLVideoElement,
}

const VideoLine = ({
  progressPercent,
  bufferedPercent,
  videoTarget,
}: IVideoLine) => {
  const onLineClick = (e: ClickEventType) => {
    const lineCoordinates = e.currentTarget.getBoundingClientRect();
    const clickOffset = e.clientX - lineCoordinates.left;

    videoTarget.currentTime = clickOffset * videoTarget.duration / lineCoordinates.width;
  }

  return (
    <S.Wrapper onClick={onLineClick}>
      <S.MainLine />
      <S.BufferLine style={{width: `${bufferedPercent}%`}} />
      <S.ProgressLine style={{ width: `${progressPercent}%` }} />
    </S.Wrapper>
  )
}

export default VideoLine;