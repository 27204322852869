// types
import { IOptionsInfo } from 'components/common/OptionsInfo';
// hooks
import { useElemSize } from 'core/hooks/useElemSize';
// styles
import * as S from './style';

interface IArticle {
  title: string,
  value: string | number,
  hoverOptions?: IOptionsInfo['options'],
}

const Article = ({
  title,
  value,
  hoverOptions,
}: IArticle) => {
  const [valueRef, valueWidth] = useElemSize();
  const [optionsRef, optionsWidth] = useElemSize();

  return (
    <S.Article>
      <p>{title}&#160;</p>
      <S.ArticleValue>
        <p ref={valueRef}>{value}</p>
        {hoverOptions &&
          <S.StyledOptionsInfo
            options={hoverOptions}
            optionsRef={optionsRef}
            $left={-optionsWidth / 2 + valueWidth / 2}
          />
        }
      </S.ArticleValue>
    </S.Article>
  )
}

export default Article;