import { useRef, useState } from "react";
// types
import { ClickEventType, IOption, InputEventType, InputFocusEvent } from "core/types/simple.types";
// config
import { loadingOptions, noDataOptions } from "core/config/commonData";
// components
import { Input } from "components/UI";
// styles
import * as S from './styles';

interface IInputSelect {
  placeholder: string,
  initValue?: string,
  options: IOption[],
  optionsIsLoading?: boolean,
  fetchOptions: (value: string) => void,
  setFilterValue: (value: string) => void,
}

const InputSelect = ({
  placeholder,
  initValue,
  options,
  optionsIsLoading,
  fetchOptions,
  setFilterValue,
}: IInputSelect) => {
  const optionName = options?.length ? options.find(item => item.value === initValue)?.name : initValue;
  const [value, setValue] = useState(optionName || '');
  const [selectOpenned, setSelectOpenned] = useState(false);
  const [isFirstFocus, setIsFirstFocus] = useState(true);
  const selectRef = useRef<HTMLUListElement | null>(null);

  const onInputChange = (e: InputEventType) => {
    const value = e.currentTarget.value;
    setValue(value);

    if (fetchOptions && value.length >= 3) {
      setSelectOpenned(true);
      fetchOptions(value);
    }
  }

  const onInputBlur = (e: InputFocusEvent) => {
    if ((e.relatedTarget !== selectRef.current) || !selectRef.current) {
      setSelectOpenned(false);
    } else {
      e.target.focus();
    }
  }

  const onInputFocus = () => {
    if (isFirstFocus) {
      setIsFirstFocus(false);
    } else {
      setSelectOpenned(true);
    }
  }

  const onInputClick = (e: ClickEventType) => {
    if (!selectOpenned && e.target === document.activeElement) {
      setSelectOpenned(true);
    }
  }

  const optionClickHandler = (value: string) => {
    setFilterValue(value)
  }

  const getSelectOptions = () => {
    if (optionsIsLoading && !options.length) return loadingOptions;
    if (!options.length) return noDataOptions;
    return options;
  }

  return (
    <>
      <Input
        value={value}
        placeholder={placeholder}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onClick={onInputClick}
        onChange={onInputChange}
        autoFocus
      />
      {options && selectOpenned && value.length >= 3 &&
        <S.StyledSelect
          selectedValue={value}
          options={getSelectOptions()}
          selectRef={selectRef}
          optionClickHandler={optionClickHandler}
        />
      }
    </>
  )
}

export default InputSelect;
