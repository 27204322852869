import styled from "styled-components";
// components
import FileContent from "./components/FileContent";
// styles
import { solidBorderProperty } from "styles/common";

export const Wrapper = styled.div`
  height: calc(100% - 53px);
`;

export const StyledFileContent = styled(FileContent)`
  height: calc(100% - 51px);
`;

export const Operations = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 0;
  border-top: ${solidBorderProperty};
`;