import { useCallback, useEffect } from 'react';
// types
import { FilesType, IPackageMedia } from 'core/models/packages/model.types';
import { AnyFunctionType } from 'core/types/simple.types';
import { RotatePositionType } from 'core/types/advanced.types';
import { IRotationData } from 'components/modals/Gallery/components/FileContent';
// utils
import { getFileMimeType } from 'core/utils/base/baseUtils';
import { registerScrollEvent } from 'core/utils/events/documentEvents';
// hooks
import { useElemSize } from 'core/hooks/useElemSize';
// components
import IconButton from 'components/common/IconButton';
import Video from 'components/common/Video';
// styles
import * as S from './style';
import theme from 'core/theme/theme';

interface IFileView extends IRotationData {
  className?: string,
  file: IPackageMedia,
  prevButtonDisabled?: boolean,
  nextButtonDisabled?: boolean,
  withNav?: boolean,
  onNextClick: AnyFunctionType,
  onPrevClick: AnyFunctionType,
}

const FileView = ({
  className,
  file,
  rotatePosition,
  prevButtonDisabled,
  nextButtonDisabled,
  withNav,
  refreshImg,
  onNextClick,
  onPrevClick,
  setRotatePosition,
}: IFileView) => {
  const [wrapperRef, wrapperWidth, wrapperHeight, setElemWidth] = useElemSize(false);

  const onWindowResize = useCallback(() => {
    if (wrapperRef.current?.clientWidth) {
      setElemWidth(wrapperRef.current.clientWidth);
    }
  }, [setElemWidth, wrapperRef]);

  useEffect(() => {
    registerScrollEvent('resize', onWindowResize, window);
  }, [onWindowResize]);

  const rotateImg = () => {
    if (rotatePosition === 270) {
      setRotatePosition(0);
    } else {
      setRotatePosition((rotatePosition + 90) as RotatePositionType);
    }
  }

  const prevClickHandler = () => {
    onPrevClick();
  }

  const nextClickHandler = () => {
    onNextClick();
  }

  return (
    <S.Wrapper className={className} ref={wrapperRef} >
      {file.mediaType === FilesType.photo &&
        <S.Controls>
          <IconButton
            text='Сбросить'
            icon={theme.icons.refresh}
            onClick={refreshImg}
            disabled={rotatePosition === 0}
          />
          <IconButton
            text='Повернуть'
            icon={theme.icons.redo}
            onClick={rotateImg}
          />
        </S.Controls>
      }
      {file.mediaType === FilesType.photo
        ? <S.Photo
          src={file.originalMediaUrl}
          alt=""
          $maxWidth={wrapperWidth}
          $maxHeight={wrapperHeight}
          $rotate={rotatePosition}
        />
        : file.originalMediaUrl && <Video
          src={file.originalMediaUrl}
          mimeType={getFileMimeType(file.title)}
          maxWidth={wrapperWidth}
          maxHeight={wrapperHeight}
        />
      }
      {withNav &&
        <S.NavButtons>
          <button type='button' onClick={prevClickHandler} disabled={prevButtonDisabled} />
          <button type='button' onClick={nextClickHandler} disabled={nextButtonDisabled} />
        </S.NavButtons>
      }
    </S.Wrapper>
  )
}

export default FileView;