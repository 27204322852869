import { useCallback, } from 'react';
// types
import { IMessage } from 'core/models/messages/model.types';
import { ReplyToMessageType } from 'components/modals/Messages';
import { AnyFunctionType, NullableRefType } from 'core/types/simple.types';
// actions
import { setMessagesModalHoverId } from 'core/models/modal/slice';
// hooks
import { useModalSelector } from 'core/store/selectorHooks';
import { useAppDispatch } from 'core/store';
// utils
import { combineMessages } from './utils/combineMessages';
// components
import Message from './components/Message';
// styles
import * as S from './style';

const scrollToMessage = (targetRef: HTMLParagraphElement | null, scrollRef: NullableRefType, scrollAction?: AnyFunctionType) => {
  if (scrollAction) scrollAction();
  const clientRect = targetRef?.getBoundingClientRect();
  if (!clientRect) return;

  const subOffset = 40;
  const messageTopWindowHeightDiff = clientRect.top - window.innerHeight + clientRect.height + subOffset;
  if (messageTopWindowHeightDiff < 0) return

  scrollRef.current?.scrollBy({ top: messageTopWindowHeightDiff });
}

interface IMessagesList {
  messages: IMessage[] | null,
  modalRef: NullableRefType,
  replyToMessage: ReplyToMessageType,
}

const MessagesList = ({
  messages,
  modalRef,
  ...props
}: IMessagesList) => {
  const dispatch = useAppDispatch();
  const { messagesModalHoverId } = useModalSelector(undefined);

  const clearMessagesModalHoverId = useCallback(() => {
    setTimeout(() => {
      dispatch(setMessagesModalHoverId({ id: null, }));
    }, 2000);
  }, [dispatch]);

  const messageRefCallback = useCallback((hoveredMessageRef: HTMLParagraphElement | null) => {
    scrollToMessage(hoveredMessageRef, modalRef, clearMessagesModalHoverId);
  }, [clearMessagesModalHoverId, modalRef]);

  if (!messages) {
    return <></>
  }

  const combimedMessages = combineMessages(messages);

  return (
    <S.Wrapper>
      {combimedMessages.map((group, groupKey) =>
        <S.Group key={groupKey}>
          {group.map((item, key) =>
            <Message
              key={key}
              message={item}
              selected={item.id === messagesModalHoverId}
              messageRefCallback={item.id === messagesModalHoverId ? messageRefCallback : undefined}
              {...props}
            />
          )}
        </S.Group>
      )}
    </S.Wrapper>
  )
}

export default MessagesList;