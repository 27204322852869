import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// utils
import history from 'core/utils/history';
// types
import { ITargetTask, IUser, RolesPermissionsType, TargetStateType } from './model.types';
// thunks
import { fetchUserThunk, getTaskResultThunk } from './thunks';
import { readAllNotificationsThunk, readNotificationThunk, updateNotificationsSettingsThunk } from '../events/thunks';
import { changeMediaOrientationThunk } from '../packages/thunks';
// config
import { routesPublic } from 'core/constants/routes';
import { maxTaskFetchCounter } from 'core/constants/values';

interface ISetNotificationsCounterData { counter: number | null, }
interface IChangeTaskStateData { task: ITargetTask, state: TargetStateType }
export interface IUsersState {
  user: IUser | null,
  isLoading: boolean,
  permissions: RolesPermissionsType | null,
  notificationsCounter: number | null,
  tasks: ITargetTask[],
}

const initialState: IUsersState = {
  user: null,
  isLoading: false,
  permissions: null,
  notificationsCounter: null,
  tasks: [],
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setNotificationsCounter: {
      reducer: (state, { payload }: PayloadAction<ISetNotificationsCounterData>) => {
        state.notificationsCounter = payload.counter;
      },
      prepare: (data: ISetNotificationsCounterData) => {
        return { payload: data };
      }
    },
    changeTaskState: {
      reducer: (state, { payload }: PayloadAction<IChangeTaskStateData>) => {
        const taskIndex = state.tasks.findIndex(({ taskId }) => taskId === payload.task.taskId);
        state.tasks[taskIndex].state = payload.state;
      },
      prepare: (data: IChangeTaskStateData) => {
        return { payload: data };
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUserThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserThunk.rejected, (state, { payload }) => {
      if (payload) {
        history.push(routesPublic.AUTH);
      }
    });
    builder.addCase(fetchUserThunk.fulfilled, (state, { payload }) => {
      if (!payload.id) {
        history.push(routesPublic.AUTH);
      }
      state.isLoading = false;
      state.user = payload;
    });
    builder.addCase(updateNotificationsSettingsThunk.fulfilled, (state, { payload }) => {
      if (state.user) {
        switch (payload.settingType) {
          case 'email': state.user = {
            ...state.user,
            enableMailNotification: payload.value,
          }; break;
          case 'push': state.user = {
            ...state.user,
            enablePushNotification: payload.value
          }; break;
        }
      }
    });
    builder.addCase(readAllNotificationsThunk.fulfilled, (state) => {
      state.notificationsCounter = 0;
    });
    builder.addCase(readNotificationThunk.fulfilled, (state) => {
      state.notificationsCounter = state.notificationsCounter ? state.notificationsCounter - 1 : state.notificationsCounter;
    });
    builder.addCase(changeMediaOrientationThunk.fulfilled, (state, { payload }) => {
      state.tasks = [
        ...state.tasks,
        {
          targetId: payload.uploadId,
          subTargetId: payload.rotationMedia[0].mediaId,
          taskId: payload.taskId,
          state: 'pending',
          data: { type: 'changeOrientation' },
          counter: 1,
        }
      ]
    });
    builder.addCase(getTaskResultThunk.fulfilled, (state, { payload }) => {
      const taskIndex = state.tasks.findIndex(({ taskId }) => taskId === payload.data.taskId);
      const task = state.tasks[taskIndex];

      if (task.counter >= maxTaskFetchCounter) {
        state.tasks[taskIndex].state = 'error';
      } else {
        state.tasks[taskIndex].counter = task.counter + 1;
      }

      if (payload.data.taskResult !== 'not ready') {
        if (task.data) {
          state.tasks[taskIndex].state = 'ready';
          state.tasks[taskIndex].data = { ...task.data, ...payload.data.taskResult[0] };
        }
      }
    })
  },
})

const { actions, reducer: usersReducer } = usersSlice;

export const { setNotificationsCounter, changeTaskState, } = actions;

export default usersReducer;
