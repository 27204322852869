// types
import { INotificationsSetting } from 'core/models/events/model.types';
// thunks
import { updateNotificationsSettingsThunk } from 'core/models/events/thunks';
// hooks
import { useAppDispatch } from 'core/store';
// components
import { Checkbox } from 'components/UI';
// styles
import * as S from './style';

interface ISettings {
  settings: INotificationsSetting[],
}

const Settings = ({
  settings
}: ISettings) => {
  const dispatch = useAppDispatch();

  const onCheckboxChange = (data: INotificationsSetting) => {
    dispatch(updateNotificationsSettingsThunk({ data: { settingType: data.type, value: !data.value }, actionData: {} }));
  }

  return (
    <S.Wrapper>
      <S.Title>Настройки</S.Title>
      <S.SettingsBlock>
        {settings.map((item, key) =>
          <Checkbox
            key={key}
            label={item.text}
            checked={item.value}
            onChange={() => onCheckboxChange(item)}
          />
        )}
      </S.SettingsBlock>
    </S.Wrapper>
  )
}

export default Settings;