import { useDispatch } from "react-redux";
// types
import { WithUserCheckType } from "core/hocs/withUserCheck";
// actions
import { openNotificationsModal } from "core/models/modal/slice";
// hooks
import { useUsersSelector } from "core/store/selectorHooks";
// hocs
import { withUserCheck } from 'core/hocs';
// styles
import * as S from './style';

interface INotificationsBlock extends WithUserCheckType { }

const NotificationsBlock = ({
  permissions,
}: INotificationsBlock) => {
  const dispatch = useDispatch();
  const { notificationsCounter } = useUsersSelector(undefined);
  const lastDecimalNumber = 99;

  const OpenNotification = () => {
    dispatch(openNotificationsModal());
  }

  // if (!permissions.notification.read) return <></>

  return (
    <S.Wrapper>
      {permissions.notification.read &&
        <>
          <S.Button onClick={OpenNotification}>
            {Boolean(notificationsCounter) &&
              <S.Counter $isBig={Number(notificationsCounter) > lastDecimalNumber}>
                {notificationsCounter}
              </S.Counter>
            }
          </S.Button>
        </>
      }
    </S.Wrapper>
  )
}

export default withUserCheck(NotificationsBlock);