import styled from "styled-components";
// components
import { Select } from "components/common";

export const StyledSelect = styled(Select)`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
`;
