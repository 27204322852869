import { createSlice } from '@reduxjs/toolkit';
// types
import { IHandbook } from '../handbook/model.types';
// thunks
import { fetchHandbookThunk, } from './thunks';

export interface IHandbookState {
  handbook: IHandbook | null,
}

const initialState: IHandbookState = {
  handbook: null,
}

const handbookSlice = createSlice({
  name: 'handbook',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchHandbookThunk.fulfilled, (state, { payload }) => {
      state.handbook = payload;
    });
  },
})

const { reducer: handbookReducer } = handbookSlice;

export default handbookReducer;
