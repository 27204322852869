import styled, { css } from "styled-components";
// styles
import theme from "core/theme/theme";
import { menuBoxShadow, templateBorder } from "styles/common";

export const Wrapper = styled.ul`
  overflow-y: auto;
  padding: 10px 8px;
  background-color: ${theme.color.white};
  position: relative;
  z-index: ${theme.zIndex.menu};
  ${menuBoxShadow};
`;

const optionDisabledStyles = css`
  cursor: default;
  pointer-events: none;
`;

const optionSelectedStyles = css`
  color: ${theme.color.white};
  background-color: ${theme.color.libertyBlue};
`;

interface OptionProps {
  $disabled: boolean,
  $selected: boolean,
}

export const Option = styled.li<OptionProps>`
  padding: 4px;
  cursor: pointer;

  ${({ $selected }) => $selected ? optionSelectedStyles : ''};

  ${({ $disabled }) => $disabled ? optionDisabledStyles : ''};

  &:not(:last-child) {
    ${templateBorder}
  }

  &:hover {
    background-color: ${({ $selected, $disabled }) => !$selected && !$disabled ? theme.color.lightSmokeBlue : ''};
  }
`;
