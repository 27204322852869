import styled from "styled-components";
// styles
import { getIconElemStyle, iconInputIconStyle, iconInputStyle, iconInputWrapperStyle } from "styles/common";
import theme from "core/theme/theme";

export const Wrapper = styled.div`
  gap: 0 8px;
  width: 300px;
  ${iconInputWrapperStyle};
`;

export const Input = styled.input`
  ${iconInputStyle};
`;

export const SearchButton = styled.button`
  ${iconInputIconStyle};
  ${getIconElemStyle(theme.icons.search, 20)}
`;
