import styled, { keyframes } from "styled-components";
// styles
import theme, { ThemeIconsType } from "core/theme/theme";
import { menuBoxShadow, simpleText, smallText } from "styles/common";

export const Wrapper = styled.div`
  width: 232px;
  padding: 8px 0;
  background-color: ${theme.color.hazeGray};
  z-index: ${theme.zIndex.menu};
  ${menuBoxShadow};
`;

interface OptionProps {
  $icon: ThemeIconsType,
}

export const Option = styled.div`
  width: 100%;
  padding: 8px 16px;
`;

export const OptionButton = styled.button<OptionProps>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0 4px;
  ${simpleText};

  &::before {
    content: ${({ $icon }) => `'${$icon}'`};
    font-family: "Tass-Icons";
    font-size: 24px;
    min-width: 24px;
    /* min-height: 24px; */
  }
`;

const opacityAnimation = keyframes`
  0% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

interface OptionNotificationProps {
  $display: boolean;
  $duration: number | undefined;
}

export const OptionNotification = styled.span<OptionNotificationProps>`
  display: ${({ $display }) => $display ? 'inline-block' : 'none'} ;
  margin-top: 6px;
  margin-left: 28px;
  animation: ${opacityAnimation} linear;
  animation-duration: ${({ $duration }) => `${$duration || 0}ms`};
  ${smallText};
  color: ${theme.color.green};
`;
