import styled from "styled-components";
// style
import theme from "core/theme/theme";
import { getIconElemStyle, simpleText } from "styles/common";

export const Wrapper = styled.li`
  display: flex;
  align-items: center;
  gap: 0 4px;
  padding: 4px 8px;
  ${simpleText};
  color: ${theme.color.blue};
  background-color: ${theme.color.smokeBlue};

  &>button {
    ${getIconElemStyle(theme.icons.close, 20)}
  }
`;