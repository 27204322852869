import { useState } from 'react';
import { useLocation } from 'react-router-dom';
// types
import { IPackage } from 'core/models/packages/model.types';
import { FilterChangeFuncType } from 'pages/Packages';
// utils
import { getSearchQueryValue } from 'core/utils/urlQuery';
// components
import PackageItem from './components/PackageItem';
// styles
import * as S from './style';

interface IPackagesList {
  downloads: IPackage[],
  onFilterChange: FilterChangeFuncType,
}

const PackagesList = ({
  downloads,
  onFilterChange,
}: IPackagesList) => {
  const [opennedCardId, setOpennedCardId] = useState<number | null>(null);
  const location = useLocation();
  const searchId = getSearchQueryValue(location.search, true);

  return (
    <S.Wrapper>
      {downloads.map((item, key) =>
        <PackageItem
          key={key}
          packageEntity={item}
          cardOpenned={(item.uploadId === opennedCardId) || (item.uploadId === Number(searchId))}
          onFilterChange={onFilterChange}
          setOpennedCardId={setOpennedCardId}
        />
      )}
    </S.Wrapper>
  )
}

export default PackagesList;