import styled from "styled-components";
// components
import { StatusesMenu } from "components/common";

export const StatusCell = styled.div`
  position: relative;
`;

interface StyledStatusesMenuProps {
  $top: number,
}

export const StyledStatusesMenu = styled(StatusesMenu) <StyledStatusesMenuProps>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: 0;
`;