import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { getIconElemStyle } from "styles/common";

export const ControlsWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 0 20px;
  margin-top: 10px;
`;

interface PlayButtonProps {
  $isPlaying: boolean,
}

export const PlayButton = styled.button<PlayButtonProps>`
  width: 14px;
  height: 18px;

  ${({ $isPlaying }) => $isPlaying
    ? getIconElemStyle(theme.icons.stop, 16, 'white')
    : getIconElemStyle(theme.icons.play, 16, 'white')}
`;

interface MuteButtonProps {
  $isMuted: boolean,
}

export const MuteButton = styled.button<MuteButtonProps>`
  width: 19px;
  height: 16px;

  ${({ $isMuted }) => $isMuted
    ? getIconElemStyle(theme.icons.muted, 17, 'white')
    : getIconElemStyle(theme.icons.volume, 18, 'white')}
`;

export const Time = styled.div`
  color: ${theme.color.white};
`;

export const StartWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.40);
`;

export const StartButton = styled.button`
  width: 57px;
  height: 60px;
  position: relative;
  z-index: ${theme.zIndex.menu};

  ${getIconElemStyle(theme.icons.play, 60, 'white')}
`;
