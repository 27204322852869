// constants
import { months, weekDays } from "core/config/commonData";
import { userDateTimeFormat } from "core/constants/dateFormats";
import { hoursToHasDateDiffer } from "core/constants/values";

const formatUnits = {
  year: 'YYYY',
  month: 'MM',
  day: 'DD',
  hours: 'hh',
  minutes: 'mm',
  seconds: 'ss',
}

export const formatDate = (value: string, inputFormat: string, outputFormat: string = userDateTimeFormat): string => {
  const yearPosition = inputFormat.search(formatUnits.year);
  const monthPosition = inputFormat.search(formatUnits.month);
  const dayPosition = inputFormat.search(formatUnits.day);
  const hoursPosition = inputFormat.search(formatUnits.hours);
  const minutesPosition = inputFormat.search(formatUnits.minutes);
  const secondsPosition = inputFormat.search(formatUnits.seconds);

  const zerodDate = zeroDate(value);
  const isPM = value.search('PM') !== -1;
  const isAM = value.search('AM') !== -1;
  const hoursValue = zerodDate.slice(hoursPosition, hoursPosition + 2);

  const res = outputFormat
    .replace('YYYY', zerodDate.slice(yearPosition, yearPosition + 4))
    .replace('MM', zerodDate.slice(monthPosition, monthPosition + 2))
    .replace('DD', zerodDate.slice(dayPosition, dayPosition + 2))
    .replace('hh', String((isPM && Number(hoursValue) !== 12)
      ? Number(hoursValue) + 12
      : (isAM && Number(hoursValue) === 12)
        ? '00'
        : hoursValue)
    )
    .replace('mm', zerodDate.slice(minutesPosition, minutesPosition + 2))
    .replace('ss', zerodDate.slice(secondsPosition, secondsPosition + 2));

  if (res.search(/\d/g) === -1) return '';

  return res
}

export const toLocaleString = (date: Date) => {
  const time = date.toLocaleTimeString();
  const day = date.getDate();
  const formatted = `${day < 10 ? `0${day}` : day}.${date.getMonth() + 1}.${date.getFullYear()} ${time}`;

  return formatted;
}

export const UTCFormatDate = (value: string | Date, outputFormat: string = userDateTimeFormat) => {
  const res = formatDate(toLocaleString(value instanceof Date ? value : new Date(value)), userDateTimeFormat, outputFormat);
  return res;
}

export const UTCFormatDateReverse = (value: string | Date, outputFormat: string = userDateTimeFormat) => {
  const date = value instanceof Date ? value : new Date(value);
  const offsetDate = addMinutes(date, date.getTimezoneOffset());

  return formatDate(toLocaleString(offsetDate), userDateTimeFormat, outputFormat);
}

const getDateWeekDayWord = (date: Date) => {
  const weekDay = date.getDay();
  return weekDays[weekDay as keyof typeof weekDays];
}

const getDateMonthWord = (date: Date) => {
  const month = date.getMonth();
  return months[month as keyof typeof months][1];
}

export const toWordsFormat = (value: string) => {
  const date = new Date(value);
  const weekDay = getDateWeekDayWord(date);
  const month = getDateMonthWord(date);

  return `${weekDay} ${date.getDate()} ${month}, ${date.getFullYear()}`;
}

const zeroMatches = (value: string, matches: RegExpMatchArray[]): string => {
  let newValue = value;

  matches.forEach(item => {
    const itemNum = item[0].match(/\d/)?.[0] || '';
    newValue = newValue.replace(item[0], item[0].replace(itemNum, `0${itemNum}`));
  });

  return newValue;
}

const zeroDate = (value: string): string => {
  let zerodDate = value;

  const matches = [
    [...value.matchAll(/\D\d\D/g)], // middle matches
    [...value.matchAll(/^\d\D/g)], // begin matches
  ];

  matches.forEach(item => {
    zerodDate = zeroMatches(zerodDate, item);
  })

  return zerodDate;
}

export const getDisabledPeriods = (startDateValue: string, endDateValue: string) => {
  const todayDate = new Date();

  const nextDay = new Date();
  nextDay.setDate(todayDate.getDate() + 1);
  const thirtyYearsAfterToday = new Date();
  thirtyYearsAfterToday.setDate(todayDate.getDate() + 10000);

  const startDate = new Date(startDateValue);
  const startPrevDate = new Date(startDate);
  startPrevDate.setDate(startDate.getDate() - 1);

  const endDate = new Date(endDateValue);
  const endNextDate = new Date(endDate);
  endNextDate.setDate(endDate.getDate() + 1);

  return [
    [{ from: endNextDate, to: nextDay, }, { from: nextDay, to: thirtyYearsAfterToday, }],
    [{ from: new Date(2000, 0, 0), to: startPrevDate }, { from: nextDay, to: thirtyYearsAfterToday, }]
  ]
}

export const getDatesDifferInHours = (dateStr1: string, dateStr2: string, abs: boolean = true,) => {
  const time1 = new Date(dateStr1).getTime();
  const time2 = new Date(dateStr2).getTime();
  return (abs ? Math.abs(time1 - time2) : (time1 - time2)) / 1000 / 60 / 60;
}

export const getIsDatesDiffer = (dateStr1: string, dateStr2: string, diffValue: number = hoursToHasDateDiffer): boolean => {
  const dayDiffHours = getDatesDifferInHours(dateStr1, dateStr2);
  if (dayDiffHours >= diffValue) return true;
  return false;
}

export const addMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() - minutes * 60000);
}
