import axios from "axios";
// utils
import { snakeify } from "./object";
import { getCookie } from "./cookies/cookie";

export const updateAxiosInstancesToken = (token: string) => {
  const tokenStr = `Bearer ${token}`;

  base.defaults.headers.common['Authorization'] = tokenStr;
  defaultInstance.defaults.headers.common['Authorization'] = tokenStr;
  mediaWeb.defaults.headers.common['Authorization'] = tokenStr;
  events.defaults.headers.common['Authorization'] = tokenStr;
  users.defaults.headers.common['Authorization'] = tokenStr;
}

const API_URL = process.env.REACT_APP_API_URL || process.env.API_URL;

axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.transformRequest = [(data) => JSON.stringify(snakeify(data))];

export const base = axios.create({
  baseURL: `${API_URL}/`,
});

export const users = axios.create({
  baseURL: `${API_URL}/users/`,
});

export const defaultInstance = axios.create({
  baseURL: `${API_URL}/default/`,
});

export const auth = axios.create({
  baseURL: `${API_URL}/auth/`,
});

export const mediaWeb = axios.create({
  baseURL: `${API_URL}/media/web/`,
});

export const events = axios.create({
  baseURL: `${API_URL}/events/`,
});

const token = getCookie('token');
updateAxiosInstancesToken(token);
