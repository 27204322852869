import { useEffect, useRef, useState } from "react";
// types
import { NullableRefType } from "core/types/simple.types";
// utils
import { getElemSizesWithoutPaddings } from "core/utils/base/calcValues";

export const useElemSize = <T extends HTMLElement = HTMLParagraphElement>(withPaddings: boolean = true):
  [NullableRefType<T>, number, number, React.Dispatch<React.SetStateAction<number>>, React.Dispatch<React.SetStateAction<number>>] => {
  const elemRef = useRef<null | T>(null);
  const [elemWidth, setElemWidth] = useState(0);
  const [elemHeight, setElemHeight] = useState(0);

  useEffect(() => {
    if (elemRef.current?.clientWidth) {
      setElemWidth(elemRef.current.clientWidth);
    }
  }, [elemRef.current?.clientWidth]);

  useEffect(() => {
    if (elemRef.current?.clientHeight) {
      setElemHeight(elemRef.current.clientHeight);
    }
  }, [elemRef.current?.clientHeight]);

  if (withPaddings) return [elemRef, elemWidth, elemHeight, setElemWidth, setElemHeight];
  else return [elemRef, ...getElemSizesWithoutPaddings(elemRef.current), setElemWidth, setElemHeight]
}