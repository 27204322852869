import styled from "styled-components";
// styles
import { solidBorderProperty } from "styles/common";

export const Wrapper = styled.div`
  padding: 24px 24px 12px;
`;

export const FormWrapper = styled.div`
  display: flex;
  gap: 0 12px;
  padding-bottom: 20px;
  border-bottom: ${solidBorderProperty};
`;
