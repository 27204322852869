// types
import { AnyFunctionType, NullableRefType } from 'core/types/simple.types';
import type { DownloadMediaType } from 'components/lists/PackagesList/components/PackageItem';
// components
import { Button } from 'components/UI';
// styles
import * as S from './style';

interface IDownloadControls {
  checkedItems: number[],
  controlsRef: NullableRefType,
  className?: string,
  onDownload: DownloadMediaType,
  cancel?: AnyFunctionType,
}

const DownloadControls = ({
  checkedItems,
  controlsRef,
  className,
  onDownload,
  cancel,
}: IDownloadControls) => {

  const downloadMedia = (archive: boolean) => {
    onDownload(archive, checkedItems);
    if (cancel) cancel();
  }

  return (
    <S.Wrapper className={className} ref={controlsRef} $display={Boolean(checkedItems.length)}>
      {Boolean(checkedItems.length) && <S.Title>Скачать файлы ({checkedItems.length})</S.Title>}
      <Button text='Скачать файлами' onClick={() => downloadMedia(false)} />
      <Button text='Скачать архивом' onClick={() => downloadMedia(true)} />
      {cancel &&
        <Button text='Отмена' transparent onClick={cancel} />
      }
    </S.Wrapper>
  )
}

export default DownloadControls;