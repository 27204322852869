import { useEffect, useState } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
// types
import { ITargetTask } from "core/models/users/model.types";
// thunks
import { getTaskResultThunk } from "core/models/users/thunks";
import { downloadPackageThunk, fetchPackageMediaThunk } from "core/models/packages/thunks";
// actions
import { changeTaskState } from "core/models/users/slice";
// hooks
import { useUsersSelector } from "core/store/selectorHooks";
import { useAppDispatch } from "core/store";

const getTaskSuccessAction = (task: ITargetTask | undefined, dispatch: ThunkDispatch<unknown, unknown, any>) => {
  if (!task?.data) return null;

  switch (task.data.type) {
    case 'changeOrientation': return () => {
      dispatch(fetchPackageMediaThunk({ data: { id: task.targetId }, actionData: {} }));
      dispatch(downloadPackageThunk({ data: { id: task.targetId, }, actionData: {} }));
    };
    default: return null;
  }
}

export const useTaskFetch = () => {
  const dispatch = useAppDispatch();
  const { tasks } = useUsersSelector(undefined);
  const [previousTasks, setPreviousTasks] = useState<ITargetTask[]>([]);

  useEffect(() => {
    let changedTasks = [];
    for (let i = 0; i < tasks.length; i += 1) {
      if (JSON.stringify(tasks[i]) !== JSON.stringify(previousTasks[i])) {
        changedTasks.push(tasks[i]);
      }
    }

    changedTasks.filter(task => task.state === 'pending').forEach(({ taskId, counter, }) => {
      setTimeout(() => {
        dispatch(getTaskResultThunk({ data: { taskId }, actionData: {} }));
      }, 1000 * Math.pow(2, counter));
    });

    changedTasks.filter(task => task.state === 'ready').forEach((task) => {
      const taskReadyAction = getTaskSuccessAction(task, dispatch);
      if (taskReadyAction) taskReadyAction();
      dispatch(changeTaskState({ task, state: 'done' }));
    });

    setPreviousTasks(tasks);
  }, [dispatch, previousTasks, tasks]);
}
