import styled from "styled-components";
// components
import Status from "components/common/Status";
// styles
import theme from "core/theme/theme";
import { simpleBoldText, simpleText, smallText, solidBorderProperty } from "styles/common";

interface MessageProps {
  $nested: boolean,
  $selected: boolean
}

export const Message = styled.div<MessageProps>`
  padding: 20px 0;
  padding-left: ${({ $nested }) => $nested ? '20px' : 0};
  background-color: ${({ $selected }) => $selected ? theme.color.darkSmokeBlue : ''};
  transition-property: background-color;
  transition-duration: 800ms;
  ${simpleText};

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: ${solidBorderProperty};
  }
`;

export const Info = styled.div`
  display: flex;
  gap: 0 12px;
`;

export const InfoHeader = styled.div`
  margin-bottom: 16px;
  &>h4 {
    ${simpleBoldText};
    margin-bottom: 2px;
  }

  &>p {
    ${smallText};
    color: ${theme.color.silverGray};
  }
`;

export const Text = styled.p`
  ${simpleText};
  white-space: pre-line;
  word-break: break-word;
`;

export const StyledStatus = styled(Status)`
  margin-top: 8px;
`;

export const Reply = styled.button`
  ${smallText};
  color: ${theme.color.blue};
  margin-top: 16px;
`;