// types
import { AnyFunctionType, ClickEventType } from 'core/types/simple.types';
// styles
import * as S from './style';

export interface ICheckbox {
  label?: string,
  checked: boolean,
  withBottomBorder?: boolean,
  onChange: AnyFunctionType,
  onClick?: (e: ClickEventType) => void,
}

const Checkbox = ({
  label,
  withBottomBorder = false,
  ...rest
}: ICheckbox) => {
  return (
    <S.Wrapper $withBottomBorder={withBottomBorder}>
      <S.Checkbox type='checkbox' {...rest} />
      {Boolean(label) && <S.Label onClick={rest.onChange}>{label}</S.Label>}
    </S.Wrapper>
  )
}

export default Checkbox;