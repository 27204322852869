import styled from "styled-components";
import theme from "core/theme/theme";
import { getIconElemStyle } from "styles/common";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`;

export const Button = styled.button`
  ${getIconElemStyle(theme.icons.bell, 20)};
  gap: 0 3px;

  &::before {
    margin-top: 2px;
  }
`;

interface CounterProps {
  $isBig: boolean,
}

export const Counter = styled.p<CounterProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ $isBig }) => $isBig ? 24 : 20}px;
  min-height: ${({ $isBig }) => $isBig ? 24 : 20}px;
  border-radius: 100%;
  background-color: ${theme.color.red};
  color: ${theme.color.white};
  font-size: 12px;
  line-height: 16px;
`;