import S from 'string';

type ObjectDataType = Record<string | number, any> | Array<any>

const formatData = <T extends ObjectDataType>(data: T, type: 'camelize' | 'underscore', recursive: boolean): T => {
  if (Array.isArray(data)) {
    const formattedData: ObjectDataType = [];

    for (let key in data) {
      const camelKey = S(key)[type]().s;
      if (data[key] && typeof data[key] === 'object' && recursive) {
        formattedData[Number(camelKey)] = formatData(data[key], type, true);
      } else {
        formattedData[Number(camelKey)] = data[key];
      }
    }

    return formattedData as T;
  }

  const formattedData: ObjectDataType = {};

  Object.keys(data).forEach(key => {
    const camelKey = S(key)[type]().s;
    if (data[key] && typeof data[key] === 'object' && recursive) {
      formattedData[camelKey] = formatData(data[key], type, true);
    } else {
      formattedData[camelKey] = data[key];
    }
  });

  return formattedData as T;
};

const camelize = <T extends ObjectDataType>(snakeData: T, recursive: boolean = true): T => {
  return formatData(snakeData, 'camelize', recursive);
};

const snakeify = <T extends ObjectDataType | undefined>(camelData: T, recursive: boolean = true): T => {
  if (!camelData) return camelData;
  return formatData(camelData, 'underscore', recursive);
};

const isObject = (obj: unknown): obj is Object => {
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    return true;
  }
  return false;
}

const isNotEmptyArray = (obj: unknown): obj is Array<any> => {
  if (Array.isArray(obj) && obj.length) return true;
  return false;
}

const singleNestObject = (obj: Record<string, any>): Record<string, any> => {
  const objEntries = Object.entries(obj);

  const result = objEntries.reduce<Record<string, any>>((acc, item) => {
    const newItem = isObject(item[1]) ? { ...singleNestObject(item[1] as Record<string, any>) } : { [item[0]]: item[1] };

    return {
      ...acc,
      ...newItem,
    }
  }, {});

  return result;
}

const objectEntries = <T, K = string>(obj: { [s: string]: T; }): [K, T][] => {
  return Object.entries(obj) as [K, T][];
}

export { camelize, snakeify, isObject, isNotEmptyArray, singleNestObject, objectEntries, };
