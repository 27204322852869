import { useLocation } from 'react-router-dom';
// types
import { IPackage, IPackageMedia } from 'core/models/packages/model.types';
import { WithUserCheckType } from 'core/hocs/withUserCheck';
// utils
import { UTCFormatDate, getIsDatesDiffer } from 'core/utils/date';
import { getSearchQueryValue } from 'core/utils/urlQuery';
// hooks
import { usePackagesSelector } from 'core/store/selectorHooks';
// hocs
import { withUserCheck } from 'core/hocs';
// constants
import { dataDiffMessage, emptyDataDiffMessage } from 'core/constants/values';
// components
import Cell from 'components/common/Cell';
// styles
import * as S from './style';

interface IFileCard extends WithUserCheckType {
  packageEntity: IPackage,
  file: IPackageMedia,
  lastRowItemsCount?: number,
  checkedItems: number[],
  openGallery: VoidFunction,
  checkFile: (id: number) => void,
  onDownload: (archive: boolean) => void,
}

const FileCard = ({
  packageEntity,
  permissions,
  file,
  lastRowItemsCount = 0,
  checkedItems,
  openGallery,
  checkFile,
  onDownload,
}: IFileCard) => {
  const location = useLocation();
  const isDatesDiffer = getIsDatesDiffer(packageEntity.shootingDate, file.shootingDate);
  const isLocationDiffer = packageEntity.location !== file.shootingLocation;
  const { isLoading } = usePackagesSelector(undefined);
  const searchParam = isLoading ? '' : getSearchQueryValue(location.search);

  const chooseCellTooltip = (isDiffer: boolean, value: string) => {
    if (!isDiffer) return '';
    if (!value) return emptyDataDiffMessage;
    return `${dataDiffMessage} ${UTCFormatDate(value)}`;
  }

  return (
    <S.Wrapper $lastRowItemsCount={lastRowItemsCount}>
      <S.Inner>
        <S.StyledPreviewCell
          uploadId={file.id}
          size={file.size}
          uploadType={file.mediaType}
          previewUrl={file.previewUrl}
          type={permissions.downloads.upload ? 'checkboxAndDownload' : 'simple'}
          checked={checkedItems.some(item => item === file.id)}
          onDownload={onDownload}
          onClick={openGallery}
          onCheckboxClick={() => checkFile(file.id)}
        />
        <Cell
          title='Файл'
          value={file.title || ''}
          selectedPart={searchParam || ''}
          ellipsis
        />
        <Cell
          title='Снято'
          value={UTCFormatDate(packageEntity.shootingDate)}
          colored={isDatesDiffer}
          tooltip={chooseCellTooltip(isDatesDiffer, file.shootingDate)}
          required
        />
        <Cell
          title='Устройство'
          value={file.device || ''}
          selectedPart={searchParam || ''}
        />
        <Cell
          title='Место'
          value={packageEntity.location || ''}
          colored={isLocationDiffer}
          tooltip={chooseCellTooltip(isLocationDiffer, file.shootingLocation)}
          selectedPart={searchParam || ''}
        />
        <Cell
          title='Описание'
          value={file.description || ''}
          selectedPart={searchParam || ''}
          ellipsis
        />
      </S.Inner>
    </S.Wrapper>
  )
}

export default withUserCheck(FileCard);