// styles
import * as S from './style';

export type ErrorsBlockTypes = 'formErrors' | 'fieldError';

interface IErrorsBlock {
  errors: string[],
  type?: ErrorsBlockTypes,
  right?: boolean,
  className?: string,
}

const ErrorsBlock = ({
  errors,
  type = 'formErrors',
  right = false,
  className,
}: IErrorsBlock) => {
  return (
    <S.Errors $type={type} $right={right} className={className}>
      {errors.map((error, i) => <p key={i}>{error}</p>)}
    </S.Errors>
  )
}

export default ErrorsBlock;