import JSZip from "jszip";
import saveAs from 'file-saver'
// types
import type { AppDispatch } from "core/store";
import { FilesType, IPackage, IPackageAPIFileData, IPackageFileData } from "./model.types";
import { MimeTypes } from "core/types/advanced.types";
// utils
import { getFileMimeType, getIsSafari, isFile } from "core/utils/base/baseUtils";
import { createLinkAndExecute } from "core/utils/events/eventHandlers";
// api
import { fetchFileReq } from "./packages.api";
// thunks
import { registerDownloadEventThunk } from "../events/thunks";

export const replacePackageElemById = <Elem extends Pick<IPackage, 'uploadId'>>(arr: Elem[], elem: Elem) => {
  const packageIndex = arr.findIndex(item => item.uploadId === elem.uploadId);
  return [...arr.slice(0, packageIndex), { ...arr[packageIndex], ...elem }, ...arr.slice(packageIndex + 1)];
};

const getMediaType = (mimeType: MimeTypes | '') => {
  switch (mimeType) {
    case 'tiff':
    case 'heif':
    case 'jpg':
    case 'png': return FilesType.photo;
    case 'mp4':
    case 'mov': return FilesType.video;
    default: return FilesType.video;
  }
}

export const downloadFile = async (fileData: IPackageFileData) => {
  let blob;

  if (fileData.file instanceof ArrayBuffer) {
    const fileMimeType = getFileMimeType(fileData.title);
    blob = new Blob([fileData.file], { type: `${getMediaType(fileMimeType)}/.${fileMimeType}` });
  } else {
    blob = fileData.file;
  }

  const href = URL.createObjectURL(blob);
  createLinkAndExecute(href, true, fileData.title);
  URL.revokeObjectURL(href);
}

export const downloadFilesZip = (archiveName: string, filesData: IPackageFileData[]) => {
  const zip = new JSZip();

  filesData.forEach((item) => {
    zip.file(item.title, item.file);
  })

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, `${archiveName}.zip`);
  });
}

export const fetchFiles = async (medias: IPackageAPIFileData[]) => {
  let filesData: IPackageFileData[] = [];

  for (let item of medias) {
    const file = await fetchFileReq({ url: item.originalMediaUrl });

    if (isFile(file)) {
      filesData.push({ file, title: item.title });
    }
  }

  return filesData;
}

interface IDownloadFilesHandlerParams {
  packageId: number,
  medias: IPackageAPIFileData[],
  archive: boolean,
  dispatch: AppDispatch,
}

export const downloadFilesHandler = ({ packageId, medias, archive, dispatch, }: IDownloadFilesHandlerParams) => {
  fetchFiles(medias).then(files => {
    dispatch(registerDownloadEventThunk({ data: { uploadId: packageId, titles: files.map(file => file.title), }, }))
    if (archive) {
      downloadFilesZip(`Пакет-${packageId}`, files);
    } else {
      files.forEach((file, i) => {
        if (getIsSafari()) {
          setTimeout(() => {
            downloadFile(file);
          }, 100 * i);
        } else {
          downloadFile(file);
        }
      })
    }
  });
}
