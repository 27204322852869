import { FieldProps } from "formik";
// components
import { TextArea, type ITextArea, } from 'components/UI';

const renderTextArea = (props: ITextArea) => <T,>({ field, }: FieldProps<T>) => {
  return <TextArea
    {...field}
    {...props}
  />
}

export default renderTextArea;