// types
import { IStatus, IStatusWithMessage } from "core/types/status.types";
// styles
import theme from "core/theme/theme";

const newStatus: IStatus = {
  id: 1,
  value: 'new',
  title: 'Новое',
  color: theme.color.nobelGray,
};

const inProccessStatus: IStatus = {
  id: 2,
  value: 'at_work',
  title: 'В работе',
  color: theme.color.blue,
};

const rejectedStatus: IStatus = {
  id: 3,
  value: 'rejected',
  title: 'Отклонено',
  color: theme.color.red,
};

const publishedStatus: IStatus = {
  id: 4,
  value: 'published',
  title: 'Опубликовано',
  color: theme.color.green,
};

const agreedStatus: IStatus = {
  id: 5,
  value: 'send_to_social_networks',
  title: 'Передано в соц.сети',
  color: theme.color.green,
};

export const downloadStatuses: IStatus[] = [newStatus, inProccessStatus, rejectedStatus, publishedStatus, agreedStatus ];

export const chatStatuses: IStatusWithMessage[] = [
  newStatus,
  inProccessStatus,
  { ...rejectedStatus, message: 'Материалы были отклонены...' },
  { ...publishedStatus, message: 'Спасибо за материалы. Опубликовано в...' },
  { ...agreedStatus, message: 'Спасибо за материалы. Опубликовано в...' },
];