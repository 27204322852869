// types
import { AnyFunctionType } from 'core/types/simple.types';
// styles
import * as S from './style';
import { ThemeIconsType } from 'core/theme/theme';

export type ButtonIconsType = 'redo' | 'refresh';

interface IIconButton {
  text: string,
  icon: ThemeIconsType,
  disabled?: boolean,
  onClick: AnyFunctionType,
}

const IconButton = ({
  text,
  icon,
  disabled = false,
  onClick,
}: IIconButton) => {
  return (
    <S.Button
      $icon={icon}
      type='button'
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </S.Button>
  )
}

export default IconButton;