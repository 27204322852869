import { useEffect } from "react";
// hooks
import { useAppDispatch } from "core/store";
import { useUsersSelector } from "core/store/selectorHooks";
// thunks
import { fetchUserThunk, } from "core/models/users/thunks";
// types
import { IUser, UserPermissionsType } from "core/models/users/model.types";

export type WithUserCheckType = {
  user: IUser,
  permissions: UserPermissionsType,
};

const withUserCheck = <T extends WithUserCheckType>(Component: React.ComponentType<T>, withFetch: boolean = false) => (props: Omit<T, 'user' | 'permissions'>) => {
  const dispatch = useAppDispatch();
  const user = useUsersSelector('user');

  useEffect(() => {
    if (withFetch) {
      dispatch(fetchUserThunk());
    }
  }, [dispatch]);

  if (!user || !user.permissions) {
    return <></>;
  }

  return (
    <Component {...props as T} user={user} permissions={user.permissions} />
  )
}

export default withUserCheck;
