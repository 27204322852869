import styled, { css } from "styled-components";
import ContentEditable from "react-contenteditable";
// styles
import theme from "core/theme/theme";
import { simpleText } from "styles/common";

const wrapperStyle = css`
  padding: 8px 8px 24px;
`;

export const Wrapper = styled.div`
  position: relative;
  ${wrapperStyle};
  border: 1px solid ${theme.color.nobelGray};
`;

export const Content = styled(ContentEditable)`
  width: 345px;
  min-height: 116px;
  ${simpleText};
  white-space: pre-line;

  &:focus {
    outline: none;
  }

  &::before {
    content: attr(placeholder);
    position: absolute;
    top: 0;
    left: 0;
    ${wrapperStyle};
    ${simpleText};
    color: ${theme.color.nobelGray};
    cursor: text;
  }

  /* [placeholder]:empty:focus::before {
      content: "";
  } */

  &>span[data-name='prefix'] {
    color: ${theme.color.blue};
  }
`;
