import styled from "styled-components";
// components
import { DownloadControls } from "components/common";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  gap: 24px 0;
  margin-top: 20px;
`;

interface StyledDownloadControlsProps {
  $left: number,
}

export const StyledDownloadControls = styled(DownloadControls) <StyledDownloadControlsProps>`
  position: fixed;
  bottom: 0;
  left: calc(50% - ${({ $left }) => $left}px);
`;