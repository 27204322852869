import { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation, } from 'react-router-dom';
// config
import { routesPrivate, routesPublic } from 'core/constants/routes';
// hooks
import { useErrorSelector } from 'core/store/selectorHooks';
// utils
import { getCookie } from 'core/utils/cookies/cookie';
import history from 'core/utils/history';
// templates
import HeaderTemplate from 'templates/HeaderTemplate';
// pages
import AuthPage from 'pages/Auth';
import PackagesPage from 'pages/Packages';
import StatisticsPage from 'pages/Statistics';
import ErrorPage from 'pages/Error';

const RoutesComponent = () => {
  const errorData = useErrorSelector('data');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = getCookie('token');

  useEffect(() => {
    history.listen(() => {
      navigate(history.location.pathname);
    });
  }, [navigate]);

  useEffect(() => {
    if (!token && !Object.values(routesPublic).some((item) => item === pathname)) {
      navigate(routesPublic.AUTH);
    }
  }, [token, pathname, navigate]);

  useEffect(() => {
    if (errorData) {
      navigate(routesPublic.NOTFOUND);
    }
  }, [errorData, navigate]);

  return (
    <Routes>
      <Route path='*' element={<Navigate to={routesPublic.NOTFOUND} />} />
      <Route path="/" element={<Navigate to={token ? routesPrivate.DOWNLOADS : routesPublic.AUTH} />} />

      <Route path={routesPublic.AUTH} Component={AuthPage} />
      <Route element={<HeaderTemplate />}>
        <Route path={routesPrivate.DOWNLOADS} Component={PackagesPage} />
        <Route path={routesPrivate.STATISTICS} Component={StatisticsPage} />
      </Route>
      <Route path={routesPublic.NOTFOUND} Component={ErrorPage} />
    </Routes>
  );
};

export default RoutesComponent;
