import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { simpleText, solidBorderProperty } from "styles/common";

export const Wrapper = styled.ul``;

interface LineProps {
  $header?: boolean,
}

export const Line = styled.li<LineProps>`
  display: grid;
  grid-template-columns: 0.7fr 1fr 0.5fr 0.5fr 0.3fr 0.3fr;
  grid-gap: 0 10px;
  padding: 16px 8px;
  ${simpleText};
  color: ${({ $header = false }) => $header ? theme.color.silverGray : theme.color.black};

  &:not(:last-child) {
    border-bottom: ${solidBorderProperty};
  }
`;

export const DescriptionText = styled.p`
  word-break: break-word;
`;
