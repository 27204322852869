// types
import { AnyFunctionType } from "core/types/simple.types";
// style
import * as S from "./style";

interface IButton {
  text: string,
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"],
  disabled?: boolean,
  transparent?: boolean,
  className?: string,
  onClick?: AnyFunctionType,
}

const Button = ({
  text,
  type = 'button',
  disabled = false,
  transparent = false,
  className,
  onClick = () => { },
}: IButton) => {
  const buttonProps = {
    disabled: disabled,
    $transparent: transparent,
    className,
  }

  return (
    type === 'submit' ?
      <S.Button type='submit' {...buttonProps}>
        {text}
      </S.Button> :
      <S.Button type={type} onClick={onClick} {...buttonProps}>
        {text}
      </S.Button>
  )
}

export default Button;