import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { simpleText } from "styles/common";

interface WrapperProps {
  $display: boolean,
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  gap: 0 4px;
  padding: 4px 16px;
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.altoGray};
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
  opacity: ${({ $display }) => $display ? 1 : 0};
  z-index: ${({ $display }) => $display ? theme.zIndex.nav : theme.zIndex.none};
`;

export const Title = styled.p`
  margin-right: 8px;
  ${simpleText};
`;