// types
import { AnyFunctionType } from 'core/types/simple.types';
// styles
import * as S from './style';

interface ICloseButton {
  onClick: AnyFunctionType,
}

const CloseButton = ({
  onClick,
}: ICloseButton) => {
  return (
    <S.Button type="button" onClick={onClick} />
  )
}

export default CloseButton;