interface IText {
  text: string,
}

const HrefText = ({ text }: IText) => {
  const textHref = text.match(/\S+@\S+/);

  if (textHref) {
    const textWithoutHref = text.slice(0, textHref.index);
    const textWithoutHrefEnd = text.slice(Number(textHref.index) + textHref[0].length);

    return (
      <p>
        {textWithoutHref}
        <a href={`mailto:${textHref}`}>{textHref}</a>
        {textWithoutHrefEnd}
      </p>
    )
  }

  return (
    <p>{text}</p>
  )
}

export default HrefText;