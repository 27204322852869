// types
import { AxiosResponseDataType } from "core/utils/requestParser";
import { IFetchUsersRes, ITaskResult, IUser } from "./model.types";
import { IPagination, IdPropsType } from "../types/RequestProps.types";
// utils
import { defaultInstance, users } from "core/utils/axiosInstance";
import requestParser from "core/utils/requestParser";

export const fetchUserReq = requestParser(() => users.get('me/')) as () => AxiosResponseDataType<IUser>;

interface FetchUserByIdReqProps extends IdPropsType { }

export const fetchUserByIdReq = requestParser(({ id }: FetchUserByIdReqProps) => users.get(`${id}/`)) as (data: FetchUserByIdReqProps) => AxiosResponseDataType<IUser>;

interface FetchUsersReqProps extends Partial<IPagination> {
  fullName: string,
}

export const fetchUsersReq = requestParser(({ fullName }: FetchUsersReqProps) =>
  users.get(`?full_name=${fullName}`)) as
  (data: FetchUsersReqProps) => AxiosResponseDataType<IFetchUsersRes>;

interface GetTaskResultReqProps {
  taskId: string,
}
// TODO: this is not downloads functional, maybe replace it to separate slice
export const getTaskResultReq =
  requestParser(({ taskId }: GetTaskResultReqProps) => defaultInstance.get(`task-result/?task_id=${taskId}`)) as
  (data: GetTaskResultReqProps) => AxiosResponseDataType<ITaskResult>;
