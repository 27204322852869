import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0 36px;
`;

export const Title = styled.h4``;

export const SettingsBlock = styled.div`
  &>* {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;