// types
import { IOption } from "./simple.types";

interface ICommonFilter {
  title: string,
}

export interface ICheckboxFilter extends ICommonFilter {
  checkboxes: {
    label: string,
    value: string,
  }[],
}

export interface IInputFilterParams {
  multipleValue?: boolean,
  options?: IOption[],
  optionsIsLoading?: boolean,
  fetchOptions?: (value: string) => void,
}

export interface IInputFilter extends ICommonFilter, IInputFilterParams {
  label: string,
  placeholder: string,
}

export interface IDateFilter extends ICommonFilter { }

export interface ILocationFilter extends IInputFilter {
  type: FilterTypes.Location,
}

export interface IAuthorFilter extends IInputFilter {
  type: FilterTypes.Author,
}

export interface IStatusFilter extends ICheckboxFilter {
  type: FilterTypes.Status,
}

export interface IContentFilter extends ICheckboxFilter {
  type: FilterTypes.Content,
}

export interface IPeriodFilter extends IDateFilter {
  type: FilterTypes.Period,
  withTime?: boolean,
}

export type FilterUnionType = IPeriodFilter | IContentFilter | IStatusFilter | IAuthorFilter | ILocationFilter;

export enum FilterTypes {
  Author = 'fullName',
  Location = 'location',
  Content = 'contentType',
  Status = 'editingStatus',
  Period = 'period',
  StartDate = 'startDate',
  EndDate = 'finishDate',
}

export enum FilterTypesSnakiefied {
  Author = 'full_name',
  Location = 'location',
  Content = 'content_type',
  Status = 'editing_status',
  Period = 'period',
  StartDate = 'start_date',
  EndDate = 'finish_date',
}

export type AuthorFilterType = {
  [FilterTypes.Author]: string[],
}

export type LocationFilterType = {
  [FilterTypes.Location]: string[],
}

export type StatusFilterType = {
  [FilterTypes.Status]: string[],
}

export type ContentFilterType = {
  [FilterTypes.Content]: string[],
}

export type StartFilterType = {
  [FilterTypes.StartDate]: string,
}

export type EndFilterType = {
  [FilterTypes.EndDate]: string,
}

export type FiltersType = AuthorFilterType & LocationFilterType & StatusFilterType & ContentFilterType & StartFilterType & EndFilterType;

export type PackagesFiltersType = FiltersType;

export type StatisticsFiltersType = StartFilterType & EndFilterType & AuthorFilterType;

export type FiltersUnionType = AuthorFilterType | LocationFilterType | StatusFilterType | ContentFilterType | StartFilterType | EndFilterType;

export interface ITagFilter {
  label: string,
  value: string,
  name: FilterTypes.Location | FilterTypes.Author | FilterTypes.Status | FilterTypes.Content | FilterTypes.Period;
}

export type FilterModeType = 'separated' | 'simple';
