// types
import { FilterUnionType, FiltersType, ICheckboxFilter, FilterTypes, ILocationFilter, IAuthorFilter } from "core/types/filter.types";
import { SetFilterValueType } from "../FiltersLine";
// utils
import { getDisplayCancelButton } from "../FiltersLine/utils/isFiltersActive";
// components
import CheckboxFilter from "./CheckboxFilter";
import DateFilter from "./DateFilter";
import InputFilter from "./InputFilter";
// styles
import * as S from "./style";

interface IFilter {
  filter: FilterUnionType,
  className?: string,
  filtersValues: Partial<FiltersType>,
  checkboxItems: [ICheckboxFilter['checkboxes'], ICheckboxFilter['checkboxes']],
  setActiveFilter: (value: null) => void,
  setFilterValue: SetFilterValueType,
}

const Filter = ({
  filter,
  className,
  filtersValues,
  checkboxItems,
  setActiveFilter,
  setFilterValue,
}: IFilter) => {
  const onStatusFilterChange = (value: string) => {
    const statusesFilter = filtersValues[FilterTypes.Status];

    if (statusesFilter?.some(item => item === value)) {
      setFilterValue({ editingStatus: statusesFilter.filter(item => item !== value) });
    } else {
      setFilterValue({ editingStatus: [...statusesFilter || [], value] });
    }
  }

  const onContentFilterChange = (value: string) => {
    const contentFilter = filtersValues[FilterTypes.Content];

    if (contentFilter?.some(item => item === value)) {
      setFilterValue({ contentType: contentFilter.filter(item => item !== value) });
    } else {
      setFilterValue({ contentType: [...contentFilter || [], value] });
    }
  }

  const onLocationFilterChange = (filter: ILocationFilter, value: string) => {
    const filterValue = {
      location: filter.multipleValue ? [
        ...(filtersValues.location || []),
        value,
      ] : [value]
    };

    setFilterValue(filterValue, true);
  }

  const onAuthorFilterChange = (filter: IAuthorFilter, value: string) => {
    const filterValue = {
      fullName: filter.multipleValue ? [
        ...(filtersValues.fullName || []),
        value,
      ] : [value]
    };

    setFilterValue(filterValue, true);
  }

  const onDateFilterChange = (startDate: string, finishDate: string) => {
    setFilterValue({ startDate, finishDate });
  }

  const onCancleButtonClick = () => {
    switch (filter.type) {
      case FilterTypes.Location: setFilterValue({ location: [] }); break;
      case FilterTypes.Author: setFilterValue({ fullName: [] }); break;
      case FilterTypes.Content: setFilterValue({ contentType: [] }); break;
      case FilterTypes.Status: setFilterValue({ editingStatus: [] }); break;
      case FilterTypes.Period: setFilterValue({ startDate: '', finishDate: '' }); break;
      default: break;
    }
    setActiveFilter(null);
  }

  return (
    <S.Wrapper className={className} onClick={(e) => e.stopPropagation()} $short={filter.type === FilterTypes.Period && !filter.withTime}>
      <S.Header>
        <S.Title>{filter.title}</S.Title>
        {getDisplayCancelButton(filtersValues, filter.type) &&
          <S.CancelButton type="button" onClick={onCancleButtonClick}>Сбросить</S.CancelButton>
        }
      </S.Header>
      <S.Inner>
        {filter.type === FilterTypes.Location &&
          <InputFilter
            {...filter}
            initValue={filtersValues[FilterTypes.Author]?.[0]}
            setFilterValue={(value: string) => onLocationFilterChange(filter, value)}
          />
        }
        {filter.type === FilterTypes.Author &&
          <InputFilter
            {...filter}
            initValue={filtersValues[FilterTypes.Author]?.[0]}
            setFilterValue={(value: string) => onAuthorFilterChange(filter, value)}
          />
        }
        {filter.type === FilterTypes.Status &&
          <CheckboxFilter
            statusesFilter={filtersValues[FilterTypes.Status]}
            setFilterValue={onStatusFilterChange}
            checkboxItems={checkboxItems}
          />
        }
        {filter.type === FilterTypes.Content &&
          <CheckboxFilter
            statusesFilter={filtersValues[FilterTypes.Content]}
            setFilterValue={onContentFilterChange}
            checkboxItems={checkboxItems}
          />
        }
        {filter.type === FilterTypes.Period &&
          <DateFilter
            {...filter}
            startDate={filtersValues.startDate}
            endDate={filtersValues.finishDate}
            setFilterValue={onDateFilterChange}
          />
        }
      </S.Inner>
    </S.Wrapper>
  )
}

export default Filter;