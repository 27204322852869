import { createSlice } from '@reduxjs/toolkit'
// types
import { EventEntryType } from './model.types';

export interface IHistoryState {
  isLoading: boolean,
  data: EventEntryType[],
}

const initialState: IHistoryState = {
  isLoading: false,
  data: [],
}

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {},
})

const { reducer: historyReducer } = historySlice;

export default historyReducer;
