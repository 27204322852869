// types
import { IconTabType } from 'core/types/tabs.types';
import { AnyFunctionType, ClickEventType } from 'core/types/simple.types';
// styles
import * as S from './style';

interface IOptionsMenu {
  options: IconTabType[],
  className?: string,
}

const OptionsMenu = ({
  options,
  className,
}: IOptionsMenu) => {
  const optionsButtonClickHandler = (e: ClickEventType, clickAction: AnyFunctionType) => {
    e.stopPropagation();
    clickAction(e);
  }

  return (
    <S.Wrapper className={className}>
      {options.map(({ title, notification, notificationDuration, icon, onClick }, key) =>
        <S.Option key={key}>
          <S.OptionButton $icon={icon} type="button" onClick={(e) => optionsButtonClickHandler(e, onClick)}>
            {title}
          </S.OptionButton>
          <S.OptionNotification $display={Boolean(notification)} $duration={notificationDuration}>{notification}</S.OptionNotification>
        </S.Option>
      )}
    </S.Wrapper>
  )
}

export default OptionsMenu;