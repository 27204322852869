import styled from "styled-components";
// components
import FileView from "components/common/FileView";
// styles
import { simpleText, solidBorderProperty, titleText } from "styles/common";
import theme from "core/theme/theme";
import { EllipsisTitle } from "components/common";

export const Wrapper = styled.div`
  display: flex;
`;

export const StyledFileView = styled(FileView)`
  width: 70%;
`;

export const Info = styled.div`
  width: 30%;
  padding: 16px 24px;
`;

export const InfoTitle = styled.div`
  padding-bottom: 16px;
  border-bottom: ${solidBorderProperty};

  &>p {
    ${simpleText};
    color: ${theme.color.nobelGray};
  }
`;

export const StyledEllipsisTitle = styled(EllipsisTitle)`
  ${titleText};
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  padding: 16px 0;
  border-bottom: ${solidBorderProperty};
`;