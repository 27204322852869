// types
import { filtersAPIOutputDateFormat, shortUserTimeFormat, userDateFormat } from "core/constants/dateFormats";
import { IHandbook, MediaTypes, StatusesTypes } from "core/models/handbook/model.types";
import { FilterTypes, FiltersType, ITagFilter } from "core/types/filter.types";
// utils
import { formatDate } from "core/utils/date";

export const getFilterTags = (filtersValues: Partial<FiltersType>, handbook: IHandbook): ITagFilter[] => {
  let tags: ITagFilter[] = [];

  if (filtersValues[FilterTypes.Location]) {
    filtersValues[FilterTypes.Location].forEach(item => {
      tags.push({ label: 'Местоположение', value: item, name: FilterTypes.Location });
    })
  }

  if (filtersValues[FilterTypes.Author]) {
    filtersValues[FilterTypes.Author].forEach(item => {
      tags.push({ label: 'Корреспондент', value: item, name: FilterTypes.Author });
    })
  }

  if (filtersValues[FilterTypes.Status]?.length) {
    filtersValues[FilterTypes.Status].forEach(item => {
      tags.push({ label: 'Статус', value: handbook.editingStatusType[item as StatusesTypes] || '', name: FilterTypes.Status });
    })
  }

  if (filtersValues[FilterTypes.Content]?.length) {
    filtersValues[FilterTypes.Content].forEach(item => {
      tags.push({ label: 'Контент', value: handbook.mediaType[item as MediaTypes], name: FilterTypes.Content });
    })
  }

  if (filtersValues[FilterTypes.StartDate]) {
    const startDateValue = formatDate(filtersValues[FilterTypes.StartDate], filtersAPIOutputDateFormat, userDateFormat);
    const startTimeValue = formatDate(filtersValues[FilterTypes.StartDate], filtersAPIOutputDateFormat, shortUserTimeFormat);
    const endDateValue = filtersValues[FilterTypes.EndDate] ? formatDate(filtersValues[FilterTypes.EndDate], filtersAPIOutputDateFormat, userDateFormat) : '';
    const endTimeValue = filtersValues[FilterTypes.EndDate] ? formatDate(filtersValues[FilterTypes.EndDate], filtersAPIOutputDateFormat, shortUserTimeFormat) : '';

    tags.push({
      label: 'Период',
      value: `${startDateValue}${startTimeValue ? `, ${startTimeValue}` : ', 00:00'} -> ${endDateValue}${endTimeValue ? `, ${endTimeValue}` : ', 23:59'}`,
      name: FilterTypes.Period,
    });
  }

  return tags;
}