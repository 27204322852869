import styled from "styled-components";
// styles
import { solidBorderProperty } from "styles/common";

export const Wrapper = styled.ul``;

export const Group = styled.li`
  &:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: ${solidBorderProperty};
  }
`;