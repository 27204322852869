const theme = {
  color: {
    // BLACK
    black: '#1A1A1A',
    pureBlack: '#000',
    // WHITE
    white: '#fff',
    // GRAY
    nobelGray: '#b2b2b2',
    altoGray: '#d9d9d9',
    silverGray: '#757575',
    hazeGray: '#f6f6f6',
    nurseGray: '#eaeaea',
    // BLUE
    blue: '#122090',
    libertyBlue: '#5963B1',
    lightSmokeBlue: 'rgba(18, 32, 144, 0.05)',
    smokeBlue: 'rgba(18, 32, 144, 0.10)',
    darkSmokeBlue: 'rgba(18, 32, 144, 0.10)',
    // RED
    red: '#C92222',
    // YELLOW
    yellow: '#F1CA05',
    whiteYellow: '#fef9ec',
    // GREEN
    green: '#097B3B',
  },
  fontSize: {
    XS: '10px',
    S: '12px',
    SM: '13px',
    M: '14px',
    XM: '17px',
    L: '19px',
    XL: '24px',
    XXL: '36px',
    XXXL: '48px',
  },
  zIndex: {
    none: '-1',
    auto: 'auto',
    elem: '1',
    menu: '9',
    nav: '99',
    modal: '999',
    superPuper: '9999',
  },
  icons: {
    video: '\\e900',
    chevronDown: '\\e901',
    chevronLeft: '\\e902',
    archive: '\\e903',
    bell: '\\e904',
    calendar: '\\e905',
    close: '\\e906',
    download: '\\e907',
    file: '\\e908',
    help: '\\e909',
    history: '\\e90a',
    link: '\\e90b',
    UNKNOWN: '\\e90d',
    logout: '\\e90e',
    message: '\\e90f',
    photo: '\\e910',
    play: '\\e911',
    redo: '\\e912',
    refresh: '\\e913',
    search: '\\e914',
    stop: '\\e915',
    tag: '\\e916',
    time: '\\e917',
    volume: '\\e918',
    muted: '\\e919',
  }
} as const;

type IconsType = typeof theme.icons;

export type ThemeIconsType = keyof {[Icon in keyof IconsType as `${IconsType[Icon]}`]: 0};

type ColorsType = typeof theme.color;
export type ThemeColorsType = keyof {[Icon in keyof ColorsType as `${ColorsType[Icon]}`]: 0};

type ZIndexType = typeof theme.zIndex;
export type ThemeZIndexType = keyof {[Icon in keyof ZIndexType as `${ZIndexType[Icon]}`]: 0};

export default theme;