// types
import type { AxiosResponseDataType } from "core/utils/requestParser";
import { IFetchNotificationsRes, IHistoryReq, NotificationSettingType } from "./model.types";
import { IPagination } from "../types/RequestProps.types";
import { EventTypes } from "../handbook/model.types";
// utils
import { events, users } from "core/utils/axiosInstance"
import requestParser from "core/utils/requestParser";
import { arrayValueToQuery } from "core/utils/urlQuery";

export interface FetchDownloadHistoryReqProps extends IPagination {
  id: number
};

export const fetchPackageHistoryReq = requestParser(({ id, pageSize, pageCount }: FetchDownloadHistoryReqProps) => {
  const eventTypes: EventTypes[] = ['download', 'new_message', 'change_status'];
  return events.get(`web/package/${id}?${arrayValueToQuery(eventTypes, 'event_type')}&page_size=${pageSize || 10}&page=${pageCount || 1}`);
}) as (props: FetchDownloadHistoryReqProps) => AxiosResponseDataType<IHistoryReq>;

interface FetchNotificationsReqProps extends IPagination { }

export const fetchNotificationsReq = requestParser(({ pageCount, pageSize, }: FetchNotificationsReqProps) => {
  const eventTypes: EventTypes[] = ['new_message', 'upload'];
  return events.get(`web/?${arrayValueToQuery(eventTypes, 'event_type')}&page_size=${pageSize || 10}&page=${pageCount || 1}`);
}) as (data: FetchNotificationsReqProps) => AxiosResponseDataType<IFetchNotificationsRes>;

export interface ReadNotificationReqProps {
  eventId: number,
};

export const readNotificationReq =
  requestParser(({ eventId }: ReadNotificationReqProps) => events.post(`viewed/${eventId ? `?event_id=${eventId}` : ''}`)) as
  (data: ReadNotificationReqProps) => AxiosResponseDataType<string>;

export const readAllNotificationsReq =
  requestParser(() => events.post(`web/viewed/`)) as
  () => AxiosResponseDataType<string>;

const notificationURL = 'notification';

export interface UpdateNotificationsSettingReqProps {
  settingType: NotificationSettingType,
  value: boolean,
};

export const updateNotificationsSettingReq =
  requestParser(({ settingType, value }: UpdateNotificationsSettingReqProps) => {
    let queryParamName: `enable_${'mail' | 'push'}` | '' = '';
    if (settingType === 'email') queryParamName = 'enable_mail';
    else if (settingType === 'push') queryParamName = 'enable_push';

    return users.patch(`${notificationURL}/enable-${settingType}/${queryParamName ? `?${queryParamName}=${value}` : ''}`);
  }) as (data: UpdateNotificationsSettingReqProps) => AxiosResponseDataType<string>;

export interface RegisterDownloadEventReqProps {
  uploadId: number,
  titles: string[],
};

export const registerDownloadEventReq =
  requestParser((data: RegisterDownloadEventReqProps) => events.post(`web/download/`, data)) as
  (data: RegisterDownloadEventReqProps) => AxiosResponseDataType<string>;
