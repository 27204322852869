import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// types
import { IPackage } from 'core/models/packages/model.types';
import { FiltersType, FilterTypesSnakiefied } from 'core/types/filter.types';
import { IPagination } from 'core/models/types/RequestProps.types';
// config
import { emptyDownloadsFilters, getDownloadsFilters, getSearchEndDateValue, getSearchPackagesFilters, getSearchStartDateValue, getUTCSearch } from 'core/config/filters.config';
import { APIIntputDateFormat, filtersAPIInputDateFormat, shortUserTimeFormat } from 'core/constants/dateFormats';
// thunks
import { fetchOnePackageThunk, fetchPackagesThunk } from 'core/models/packages/thunks';
// utils
import { getSearchQueryValue, objectToQuery, replaceQueryParam, updateQueryParam } from 'core/utils/urlQuery';
import { formatDate, getDatesDifferInHours, UTCFormatDate } from 'core/utils/date';
import { toast } from 'react-toastify';
// hooks
import { useAppDispatch } from 'core/store';
import { useHandbookSelector, usePackagesSelector } from 'core/store/selectorHooks';
import { useScrollAutoFetch } from 'core/hooks/useScrollAutoFetch';
// hocs
import { withFetch, withUserCheck, type WithUserCheckType } from 'core/hocs';
// components
import FiltersLine from 'components/common/FiltersLine';
import PackagesList from 'components/lists/PackagesList';
// styles
import * as S from './style';

export type FilterChangeFuncType = (value: any, key: FilterTypesSnakiefied) => void;

interface IPackagesPagePage extends WithUserCheckType {
  data: IPackage[],
  children: React.ReactNode,
}

const PackagesPage = ({
  permissions,
  data,
  children: loaderChild,
}: IPackagesPagePage) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { handbook } = useHandbookSelector(undefined);
  const { shouldFetchPackageId } = usePackagesSelector(undefined);
  const downloadsFilters = handbook ? getDownloadsFilters(handbook) : null;
  const searchId = getSearchQueryValue(location.search, true);
  const searchParam = getSearchQueryValue(location.search);
  const filtersValues = getSearchPackagesFilters(decodeURIComponent(location.search));
  const [previousSearchParam, setPreviousSearchParam] = useState(searchParam);

  const fetchPackages = useCallback(({ pageSize, pageCount }: IPagination) => {
    if (searchId) return;
    if (previousSearchParam !== searchParam) {
      setPreviousSearchParam(searchParam);
      return;
    }

    const startDateValue = getSearchStartDateValue(location.search);
    const endDateValue = getSearchEndDateValue(location.search);

    if ((startDateValue && !endDateValue) || (!startDateValue && endDateValue)) return;

    // adding 1 day to finish_date
    let newEndDateValue = endDateValue;
    const endTimeValue = formatDate(endDateValue, filtersAPIInputDateFormat, shortUserTimeFormat);
    let newSearch = null;
    if (!endTimeValue) {
      const endDate = new Date(endDateValue);
      endDate.setDate(new Date(endDateValue).getDate() + 1);
      newEndDateValue = UTCFormatDate(endDate, APIIntputDateFormat);
      newSearch = replaceQueryParam(location.search, endDateValue, newEndDateValue, FilterTypesSnakiefied.EndDate);
    }

    // validating if time error
    const datesDifferInHours = getDatesDifferInHours(startDateValue, newEndDateValue, false);
    if (datesDifferInHours > 0) {
      toast('Время начала больше чем время конца');
    }

    const UTCSearch = getUTCSearch(newSearch || location.search);
    dispatch(fetchPackagesThunk({
      data: { query: UTCSearch, pageSize, pageCount, }
    }));
  }, [dispatch, location.search, previousSearchParam, searchId, searchParam]);

  const [listRef, setPageCount] = useScrollAutoFetch('package', { fetchAction: fetchPackages, listItemsCount: data?.length || 0 });

  useEffect(() => {
    setPageCount(1);
  }, [searchParam, setPageCount]);

  useEffect(() => {
    if (searchId) {
      dispatch(fetchOnePackageThunk({ data: { id: Number(searchId) }, actionData: { singlePackage: true, } }));
    }
  }, [dispatch, searchId]);

  useEffect(() => {
    if (shouldFetchPackageId) {
      dispatch(fetchOnePackageThunk({ data: { id: shouldFetchPackageId }, actionData: { singlePackage: false, } }));
    }
  }, [dispatch, shouldFetchPackageId]);

  const onFiltersChange = (filtersValues: Partial<FiltersType>) => {
    navigate({ search: objectToQuery(location.search, filtersValues) });
    setPageCount(1);
    window.scrollTo(0, 0);
  }

  const onFilterChange: FilterChangeFuncType = (value, key) => {
    navigate({ search: updateQueryParam(location.search, [value], key) });
    setPageCount(1);
    window.scrollTo(0, 0);
  }

  return (
    <>
      {downloadsFilters &&
        <FiltersLine
          filtersConfig={downloadsFilters}
          filtersValues={filtersValues}
          title='Загрузки'
          emptyFilters={emptyDownloadsFilters}
          onFiltersChange={onFiltersChange}
        />
      }
      <S.Wrapper ref={listRef}>
        {permissions.downloads.read &&
          <PackagesList
            downloads={data}
            onFilterChange={onFilterChange}
          />
        }
      </S.Wrapper>
      {loaderChild}
    </>
  )
}

export default withFetch(withUserCheck(PackagesPage), 'downloads', { withFetch: false, });
