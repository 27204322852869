import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { simpleText } from "styles/common";

export const Wrapper = styled.div`
  position: relative;
`;

export const ReadAll = styled.button`
  ${simpleText};
  color: ${theme.color.blue};
  position: absolute;
  top: 0;
  right: 0;

  &:disabled {
    cursor: not-allowed;
    color: ${theme.color.silverGray}
  }
`;

export const Group = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Date = styled.p`
  ${simpleText};
  color: ${theme.color.silverGray};
  margin-bottom: 8px;
`;

export const NoItems = styled.p`
  ${simpleText};
  color: ${theme.color.silverGray};
`;