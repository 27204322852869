import styled from "styled-components";
// types
import { RotatePositionType } from "core/types/advanced.types";
// styles
import theme from "core/theme/theme";
import { getIconElemStyle } from "styles/common";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  background-color: ${theme.color.black};
`;

export const Controls = styled.div`
  position: absolute;
  top: 12px;
  right: 40px;
  display: flex;
  align-items: center;
  gap: 0 8px;
`;

interface PhotoProps {
  $rotate: RotatePositionType,
  $maxWidth: number,
  $maxHeight: number,
}

export const Photo = styled.img<PhotoProps>`
  transform: rotateZ(${({ $rotate }) => $rotate}deg);
  position: relative;
  z-index: ${theme.zIndex.elem};
  max-width: ${({ $rotate, $maxWidth, $maxHeight }) => ($rotate === 0 || $rotate === 180) ? $maxWidth : $maxHeight}px;
  max-height: ${({ $rotate, $maxWidth, $maxHeight }) => ($rotate === 0 || $rotate === 180) ? $maxHeight : $maxWidth}px;
`;

export const NavButtons = styled.div`
  position: absolute;
  top: calc(50% - 18px);
  left: 12px;
  right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>button {
    width: 36px;
    height: 36px;
    border: 1px solid ${theme.color.nobelGray};
    background-color: ${theme.color.white};
    position: relative;
    z-index: ${theme.zIndex.menu};

    ${getIconElemStyle(theme.icons.chevronLeft, 36)}

    &:disabled {
      background-color: ${theme.color.hazeGray};
    }

    &:last-child {
      transform: rotateZ(180deg);
    }
  }
`;