import { useState } from 'react';
import { Field, Formik, Form, } from 'formik';
import * as Yup from 'yup';
// config
import { chatStatuses } from 'core/config/statuses.config';
import { chatFormMaxLength, chatFormMinLength } from 'core/constants/values';
// types
import { IFormikHelpers, IFormikProps } from 'core/types/formik.types';
import { IMessage } from 'core/models/messages/model.types';
import { IMessagesUser } from 'core/models/users/model.types';
import { IPackage } from 'core/models/packages/model.types';
import { StatusesTypes } from 'core/models/handbook/model.types';
import { IStatusWithMessage } from 'core/types/status.types';
import { ContentEditableRefType } from 'core/types/simple.types';
// utils
import { renderTextArea } from '../utils';
import { getFullName } from 'core/utils/getFullName';
import { clearContentEditable } from 'core/utils/clearContentEditable';
// components
import { Button, Checkbox } from 'components/UI';
import Status from 'components/common/Status';
import ErrorsBlock from 'components/common/ErrorsBlock';
// styles
import * as S from './style';

export interface IValues {
  message: IMessage['content'],
  statusValue: IMessage['newEditingStatus'],
}

interface IInnerForm extends IFormikProps<IValues> {
  answeredUser: IChatForm['answeredUser'],
  checkboxChecked: boolean,
  statusMessage: string,
  statuses: IStatusWithMessage[],
  chatFieldRef: IChatForm['chatFieldRef'],
  setStatusMessage: (value: string) => void,
  setCheckboxChecked: (value: boolean) => void,
  setAnsweredUser: IChatForm['setAnsweredUser'],
  setAnswerMessageId: IChatForm['setAnswerMessageId'],
}

const InnerForm = ({
  setFieldValue,
  values,
  errors,
  statuses,
  answeredUser,
  checkboxChecked,
  statusMessage,
  chatFieldRef,
  setStatusMessage,
  setCheckboxChecked,
  setAnsweredUser,
  setAnswerMessageId,
}: IInnerForm) => {
  const pureValue = clearContentEditable({ value: values.message.replaceAll(/<p[^>]+>/g, '').replaceAll(/<span[^>]+>/g, '').replaceAll('\n', ''), });

  const checkboxChangeHandler = () => {
    if (checkboxChecked) {
      if (statusMessage) setStatusMessage('');
      if (values.statusValue) setFieldValue('statusValue', null);
    }

    setCheckboxChecked(!checkboxChecked);
  }

  const chooseStatus = (status: typeof statuses[number]) => {
    if (status.value !== values.statusValue) {
      setFieldValue('statusValue', status.value);
      setStatusMessage(status.message || '');
    }
  }

  const prefixDeleteHandler = () => {
    setAnsweredUser(null)
    setAnswerMessageId(null)
  }

  return (
    <S.Form as={Form}>
      <S.MessageFieldWrapper>
        <Field
          name="message"
          children={renderTextArea({
            placeholder: 'Введите сообщение',
            value: values.message,
            templateData: [
              { name: 'template', value: statusMessage, editable: true, slug: values.statusValue || '', insertAfter: true, },
              { name: 'prefix', value: answeredUser ? getFullName(answeredUser, '@ ') : '', editable: false, deleteTemplate: prefixDeleteHandler, invisible: true, },
            ],
            fieldRef: chatFieldRef,
            onChange: (value: string) => setFieldValue('message', value),
          })}
        />
        <S.StyledCounter
          counter={pureValue.length}
          total={chatFormMaxLength}
          separator='/'
        />
      </S.MessageFieldWrapper>
      <ErrorsBlock errors={Object.values(errors)} right />
      <S.Controls>
        <Checkbox
          label='Обновить статус'
          checked={checkboxChecked}
          onChange={checkboxChangeHandler}
        />
        <Button
          type="submit"
          text='Отправить'
        // disabled={!isValid}
        />
      </S.Controls>
      {checkboxChecked &&
        <S.StatusesWrapper>
          {statuses.map((item, key) =>
            <Status
              key={key}
              status={item}
              checked={item.value === values.statusValue}
              onClick={() => chooseStatus(item)}
            />
          )}
        </S.StatusesWrapper>
      }
    </S.Form>
  )
}

interface IChatForm {
  initStatusAction?: StatusesTypes | null,
  answeredUser: IMessagesUser | null,
  packageEntity: IPackage | null,
  chatFieldRef: ContentEditableRefType,
  onSubmit: (values: IValues) => void,
  setAnsweredUser: (value: IMessagesUser | null) => void,
  setAnswerMessageId: (value: number | null) => void,
}

const ChatForm = ({
  initStatusAction = null,
  packageEntity,
  answeredUser,
  chatFieldRef,
  onSubmit,
  setAnsweredUser,
  setAnswerMessageId,
}: IChatForm) => {
  const selectedStatuses = chatStatuses.filter(item => item.value !== packageEntity?.editingStatus);
  const [checkboxChecked, setCheckboxChecked] = useState(Boolean(initStatusAction));
  const [statusMessage, setStatusMessage] = useState<string>(initStatusAction ?
    (selectedStatuses.find(item => item.value === initStatusAction)?.message || '') : ''
  );

  const initialValues: IValues = {
    message: '',
    statusValue: initStatusAction,
  };

  const resetFormSideData = () => {
    setCheckboxChecked(false);
    setAnsweredUser(null);
    setStatusMessage('');
  }

  const onSubmitHandler = (values: IValues, { resetForm, }: IFormikHelpers<IValues>) => {
    resetFormSideData();
    resetForm({ values: { statusValue: null, message: '' } });

    onSubmit({
      ...values,
      message: clearContentEditable({
        value: values.message.replaceAll(/<p[^>]+>/g, '\n').replaceAll(/<\/p>/g, '\n').replaceAll(/<span[^>]+>/g, ''),
        placeLineFeeds: true,
      }).trim(),
    })
  }

  const validationSchema: Yup.ObjectSchema<{ message: Yup.Maybe<string>, statusValue: StatusesTypes | null }> = Yup.object({
    message: Yup
      .string()
      .optional()
      .test(
        'limit',
        'Превышен лимит длины сообщения',
        (value) => {
          const pureValue = clearContentEditable({ value: (value || '').replaceAll(/<p[^>]+>/g, '').replaceAll(/<span[^>]+>/g, '').replaceAll('\n', ''), });
          if (pureValue.length > chatFormMaxLength) return false;
          return true;
        },
      ).test(
        'minLength',
        `Сообщение должно иметь не менее ${chatFormMinLength} символов`,
        (value, context) => {
          const pureValue = clearContentEditable({ value: (value || '').replaceAll(/<p[^>]+>/g, '').replaceAll(/<span[^>]+>/g, '').replaceAll('\n', ''), });
          const contextParent: IValues = context.parent;

          if (contextParent.statusValue === 'at_work' || contextParent.statusValue === 'new') {
            if (pureValue.length < chatFormMinLength && pureValue.length !== 0) return false;
            return true
          }
          if (pureValue.length < chatFormMinLength) return false;

          return true;
        },
      ),
    statusValue: Yup
      .string<StatusesTypes>()
      .nullable()
      .defined()
      .test(
        'isRequired',
        'Выберите статус',
        (value) => {
          if (!value && checkboxChecked) return false;
          return true;
        },
      ),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
      validateOnChange={false}
      validateOnBlur={false}
      children={(props) =>
        <InnerForm
          {...props}
          answeredUser={answeredUser}
          checkboxChecked={checkboxChecked}
          statusMessage={statusMessage}
          statuses={selectedStatuses}
          chatFieldRef={chatFieldRef}
          setStatusMessage={setStatusMessage}
          setCheckboxChecked={setCheckboxChecked}
          setAnsweredUser={setAnsweredUser}
          setAnswerMessageId={setAnswerMessageId}
        />}
    />
  )
}

export default ChatForm;