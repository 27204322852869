import styled from "styled-components";
// styles
import { simpleText } from "styles/common";

export const Wrapper = styled.div``;

export const Label = styled.p`
  margin-bottom: 8px;
  ${simpleText};

  &:not(:first-of-type) {
    margin-top: 16px;
  } 
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`;
