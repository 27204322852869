export const packageItemAvarageHeight = 200;

export const headerHeight = 65;

export const filtersLineHeight = 45;

export const historyItemAvarageHeight = 53;

export const notificationItemAvarageHeight = 84;

export const hoursToHasDateDiffer = 4;

export const dataDiffMessage = 'Метаданные:';

export const emptyDataDiffMessage = 'Метаданные отсутствуют';

export const messagesModalWidth = 480;

export const modalWrapperPadding = 24;

export const modalInnerBottomPadding = 12;

export const chatFormMaxLength = 1000;

export const chatFormMinLength = 10;

export const workAreaWidth = 1440;

export const loadingMessage = 'Загрузка...';

export const notificationsModalInnerTopPadding = 24;

export const notificationsModalInnerBottomPadding = 12;

export const uploadNotificationMessage = 'Получены новые материалы';

export const maxTaskFetchCounter = 5;
