import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
// types
import { ModalsType } from 'core/models/modal/model.types';
import { ClickEventType } from 'core/types/simple.types';
import { IPackage } from 'core/models/packages/model.types';
// config
import { notificationTabs } from 'core/constants/tabs';
import { userDateTimeFormat, userTimeFormat } from 'core/constants/dateFormats';
// hooks
import { useModalSelector } from 'core/store/selectorHooks';
// utils
import { chooseHeight, chooseLeft, chooseWidth } from './utils/getSizes';
import { formatDate, toLocaleString } from 'core/utils/date';
// actions
import { clearGalleryData, closeModal, setMessagesModalHoverId } from 'core/models/modal/slice';
// components
import { GalleryModal, MessagesModal, NotificationsModal, HistoryModal } from 'components/modals';
import CloseButton from '../CloseButton';
// styles
import * as S from './style';

const executeCloseModalAction = (type: ModalsType | null, dispatch: Dispatch<AnyAction>) => {
  switch (type) {
    case 'gallery': dispatch(clearGalleryData()); break;
    case 'messages': dispatch(setMessagesModalHoverId({ id: null, })); break;
    default: break;
  }
}

const Modal = () => {
  const wrapperRef = useRef<null | HTMLParagraphElement>(null);
  const innerRef = useRef<null | HTMLParagraphElement>(null);
  const dispatch = useDispatch();
  const { type, data, } = useModalSelector(undefined);
  const [tab, setTab] = useState(notificationTabs[0].value);

  const onCloseModal = () => {
    dispatch(closeModal());
    executeCloseModalAction(type, dispatch);
    setTab(notificationTabs[0].value);
  }

  const onWrapperClick = (e: ClickEventType) => {
    if (e.target === wrapperRef.current) {
      onCloseModal();
    }
  }

  useEffect(() => {
    if (type) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [type]);

  if (type) {
    return (
      <>
        <S.Wrapper onClick={onWrapperClick} ref={wrapperRef} $aside={type === 'notifications'}></S.Wrapper>
        <S.Inner ref={innerRef} $width={chooseWidth(type)} $height={chooseHeight(type)} $left={chooseLeft(type)}>
          <S.Header>
            <HeaderTitle type={type} tab={tab} setTab={setTab} download={data.package as IPackage} />
            <CloseButton onClick={onCloseModal} />
          </S.Header>
          <ModalContent type={type} tab={tab} modalRef={innerRef} />
        </S.Inner>
      </>
    )
  }
  return <></>
}

export default Modal;

interface IModalContent {
  type: ModalsType,
  tab: number,
  modalRef: React.MutableRefObject<HTMLParagraphElement | null>,
}

const ModalContent = ({ type, tab, modalRef, }: IModalContent) => {
  switch (type) {
    case 'gallery': return <GalleryModal />
    case 'messages': return <MessagesModal modalRef={modalRef} />
    case 'notifications': return <NotificationsModal tab={tab} />
    case 'history': return <HistoryModal modalRef={modalRef} />
    default: return <></>
  }
}

interface IHeaderTitle {
  type: ModalsType,
  tab: number,
  setTab: (tab: number) => void,
  download: IPackage,
}

const HeaderTitle = ({ type, tab, setTab, download, }: Required<IHeaderTitle>) => {
  switch (type) {
    case 'gallery': return <S.Title>Изображение</S.Title>
    case 'messages': return <S.Title>Ответ корреспонденту</S.Title>
    case 'notifications': return <S.StyledTabs tabs={notificationTabs} tab={tab} setTab={setTab} />
    case 'history': return (
      <S.Title>
        История действий <span>(id{download.uploadId}) / Данные актуальные на: {formatDate(toLocaleString(new Date()), userDateTimeFormat, userTimeFormat)}</span>
      </S.Title>
    )
    default: return <></>
  }
}