import styled from "styled-components";
// components
import { Button } from "components/UI";
// styles
import theme from "core/theme/theme";


export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const Picture = styled.div`
  width: 70%;
  background-color: ${theme.color.blue};
`;

export const Content = styled.aside`
  width: 30%;
  padding: 80px 45px 20px;
  display: grid;
  grid-template-rows: repeat(5, min-content) auto;
`;

export const Title = styled.h2`
  font-size: ${theme.fontSize.XXL};
  font-weight: 700;
  line-height: 42px;
  margin-bottom: 100px;
`;

export const Description = styled.p`
  font-size: ${theme.fontSize.XM};
  line-height: 20px;
  margin-bottom: 20px;
`;

export const Designation = styled.p`
  color: ${theme.color.nobelGray};
  font-size: ${theme.fontSize.SM};
  line-height: 20px;
  align-self: end;
`;

export const OAuthButton = styled(Button)`
  width: fit-content;
  margin-top: 20px;
`;
