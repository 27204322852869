import styled from "styled-components";
// components
import { Status } from 'components/common';
// styles
import theme from "core/theme/theme";

export const Wrapper = styled.div`
  z-index: ${theme.zIndex.menu};
  width: 202px;
  padding: 8px 0;
  background-color: ${theme.color.hazeGray};
`;

export const StyledStatus = styled(Status)`
  width: 100%;
  padding: 8px 16px;
`;