import styled, { css } from "styled-components";
// styles
import theme from "core/theme/theme";
import { menuBoxShadow, simpleText, solidBorderProperty, titleText } from "styles/common";

const sidePadding = css`
  padding-left: 16px;
  padding-right: 16px;
`;

interface WrapperProps {
  $short: boolean,
}

export const Wrapper = styled.div<WrapperProps>`
  width: ${({ $short }) => $short ? '192px' : '288px'};
  padding: 12px 0 16px;
  background-color: ${theme.color.hazeGray};
  ${menuBoxShadow};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  ${sidePadding};
  border-bottom: ${solidBorderProperty};
`;

export const Title = styled.p`
  ${titleText};
`;

export const CancelButton = styled.button`
  padding: 4px 8px;
  ${simpleText};
`;

export const Inner = styled.div`
  padding-top: 16px;
  ${sidePadding};
`;
