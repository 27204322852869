// types
import { tabType } from "core/constants/tabs";
import { NumberParamVoidFuncType } from "core/types/simple.types";
// styles
import * as S from "./style";

interface ITabs {
  tabs: tabType[],
  tab: number,
  smallPaddings?: boolean,
  className?: string,
  setTab: NumberParamVoidFuncType;
};

const Tabs = ({
  tabs,
  tab,
  smallPaddings = false,
  className = '',
  setTab,
}: ITabs) => {
  const setTabHandler = (newTab: number) => {
    setTab(newTab);
  }

  return (
    <S.Tabs className={className}>
      {tabs.map((item, i) =>
        item.href ?
          <S.HrefTab
            key={i}
            to={item.href}
            onClick={() => setTabHandler(item.value)}
            $isActive={item.value === tab}
            $smallPaddings={smallPaddings}
          >
            {item.title}
          </S.HrefTab> :
          <S.ButtonTab
            key={i}
            type="button"
            onClick={() => setTabHandler(item.value)}
            $isActive={item.value === tab}
            $smallPaddings={smallPaddings}>
            {item.title}
          </S.ButtonTab>
      )}
    </S.Tabs>
  )
}

export default Tabs;