// types
import { IOptionsInfo } from 'components/common/OptionsInfo';
import { AnyFunctionType } from 'core/types/simple.types';
import { IStatus } from 'core/types/status.types';
// hooks
import { useElemSize } from 'core/hooks/useElemSize';
// components
import { Status, Tooltip } from 'components/common';
// styles
import * as S from './style';

interface ICell {
  title: string,
  value: string | IStatus | null,
  tooltip?: string,
  colored?: boolean,
  hoverOptions?: IOptionsInfo['options'],
  required?: boolean,
  ellipsis?: boolean,
  selectedPart?: string,
  onClick?: AnyFunctionType,
}

const Cell = ({
  title,
  value,
  tooltip,
  colored = false,
  required = false,
  ellipsis = false,
  hoverOptions,
  selectedPart,
  onClick,
}: ICell) => {
  const [valueRef, valueWidth] = useElemSize();
  const [optionsRef, optionsWidth] = useElemSize();

  if ((required && value === null) || (!required && !value)) return <></>

  const colorizeStringValue = (value: string) => {
    return value.split('/&/').filter(item => item).map((item, i) => item === selectedPart
      ? <S.Colored key={i}>{item}</S.Colored>
      : <span key={i}>{item}</span>
    )
  };

  const getStringValue = (value: string) => {
    return value.split('\n').map((item, i) => <p key={i} title={ellipsis ? item : ''}>{selectedPart ? colorizeStringValue(item) : item}</p>)
  }

  const selectStringPart = (value: string, selectedPart: string) => {
    const selectedPartStartIndex = value.indexOf(selectedPart);
    if (selectedPartStartIndex === -1) return value;

    const selectedPartEndIndex = selectedPartStartIndex + selectedPart.length;
    return `${value.slice(0, selectedPartStartIndex)}/&/${value.slice(selectedPartStartIndex, selectedPartEndIndex)}/&/${value.slice(selectedPartEndIndex)}`;
  }

  return (
    <S.Wrapper>
      <p>{title}</p>
      <S.Inner $colored={colored} $withHover={Boolean(hoverOptions)}>
        {typeof value === 'string' &&
          <S.Value
            ref={valueRef}
            onClick={onClick}
            $ellipsis={ellipsis}
          >
            {getStringValue(selectedPart ? selectStringPart(value, selectedPart) : value)}
          </S.Value>
        }
        {typeof value !== 'string' && value && <Status status={value} onClick={onClick} checked small />}
        {tooltip && <Tooltip text={tooltip} />}
        {hoverOptions &&
          <S.StyledOptionsInfo
            options={hoverOptions}
            optionsRef={optionsRef}
            $left={-optionsWidth / 2 + valueWidth / 2}
          />
        }
      </S.Inner>
    </S.Wrapper>
  )
}

export default Cell;
