import styled from "styled-components";
// styles
import { getIconElemStyle, iconInputIconStyle, iconInputStyle, iconInputWrapperStyle, } from "styles/common";
import theme from "core/theme/theme";

export const Wrapper = styled.div`
  position: relative;
  gap: 0 12px;
  min-width: 157px;
  max-width: 157px;
  ${iconInputWrapperStyle};
  padding: 0;
`;

export const Input = styled.input`
  ${iconInputStyle};
  padding: 8px;
  z-index: ${theme.zIndex.elem};
`;

export const Icon = styled.div`
  ${iconInputIconStyle};
  ${getIconElemStyle(theme.icons.calendar, 20)}
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const DayPickerWrapper = styled.div`
  position: absolute;
  top: 110%;
  left: 0;
`;
