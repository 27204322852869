import { createSlice } from '@reduxjs/toolkit'
// types
import { IStatistic } from './model.types';
import { IShortUser } from '../users/model.types';
// thunks
import { fetchStatisticsThunk } from './thunks';
import { fetchUsersThunk } from '../users/thunks';

export interface IStatisticsState {
  data: IStatistic | null,
  users: IShortUser[],
  filters?: null,
  usersIsLoading: boolean,
  isLoading: boolean,
}

const initialState: IStatisticsState = {
  data: null,
  users: [],
  usersIsLoading: false,
  isLoading: false,
}

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    clearStatistics: (state) => {
      state.data = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatisticsThunk.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchStatisticsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
      if (!state.users.length) {
        state.users = [payload.correspondent];
      }
    });

    builder.addCase(fetchUsersThunk.pending, (state) => {
      state.usersIsLoading = true;
    });

    builder.addCase(fetchUsersThunk.fulfilled, (state, { payload }) => {
      state.usersIsLoading = false;
      state.users = payload.data;
    });

    builder.addCase(fetchUsersThunk.rejected, (state) => {
      state.usersIsLoading = false;
    });
  },
})

const { actions, reducer: statisticsReducer } = statisticsSlice;

export const { clearStatistics } = actions;

export default statisticsReducer;
