import { useCallback, useState } from "react";
// types
import { AnyFunctionType } from "core/types/simple.types";

export const useTimeout = (action: AnyFunctionType, duration: number):
  [NodeJS.Timeout | null, (timer: NodeJS.Timeout) => void, AnyFunctionType] => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const actionWithTimeout = useCallback((...args: any[]) => {
    const actionTimer = setTimeout(() => {
      action(args);
      setTimer(null)
    }, duration);

    if (timer) clearTimeout(timer);
    setTimer(actionTimer);
  }, [action, duration]);

  const clearTimer = (timer: NodeJS.Timeout) => {
    clearTimeout(timer);
    setTimer(null);
  }

  return [timer, clearTimer, actionWithTimeout]
}
