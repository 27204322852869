import styled from "styled-components";
// styles
import { simpleText } from "styles/common";

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.p`
  margin-bottom: 8px;
  ${simpleText};
`;
