import { useState } from 'react';
// types
import { DownloadMediaType, IOptionCell } from '../PackageItem';
import { ClickEventType } from 'core/types/simple.types';
import { IconTabType } from 'core/types/tabs.types';
import { WithDisplayPropertyType } from 'core/types/advanced.types';
// actions
import { UserPermissionsType } from 'core/models/users/model.types';
import { openHistoryModal, openMessagesModal } from "core/models/modal/slice";
// constants
import { searchQuery } from 'core/constants/queryParams';
// hooks
import { useAppDispatch } from 'core/store';
// hocs
import { withUserCheck } from 'core/hocs';
// components
import { OptionsButton } from 'components/UI';
// styles
import * as S from './style';
import theme from 'core/theme/theme';

interface IDownloadOptions extends IOptionCell {
  permissions: UserPermissionsType,
  onDownload: DownloadMediaType,
};

const OptionsCell = ({
  permissions,
  packageEntity,
  optionsOpenned,
  onDownload,
  setOptionsOpenned,
}: IDownloadOptions) => {
  const [linkNotification, setLinkNotification] = useState('');
  const [linkTimer, setLinkTimer] = useState<NodeJS.Timeout | null>(null);
  const linkNotificationDuration = 2000;
  const dispatch = useAppDispatch();
  const location = window.location;

  const openMessages = () => {
    setOptionsOpenned(false);
    dispatch(openMessagesModal({ package: packageEntity, }));
  }

  const openHistory = () => {
    setOptionsOpenned(false);
    dispatch(openHistoryModal({ package: packageEntity }));
  }

  const onOptionsButtonClick = (e: ClickEventType) => {
    e.stopPropagation();
    setOptionsOpenned(!optionsOpenned);
  }

  const downloadClickHandler = (archive: boolean) => {
    setOptionsOpenned(false);
    onDownload(archive);
  }

  const linkClickHandler = () => {
    if (linkTimer) {
      clearTimeout(linkTimer);
      setLinkTimer(null);
    }

    navigator.clipboard.writeText(`${location.origin}${location.pathname}?${searchQuery}=id:${packageEntity.uploadId}`);
    setLinkNotification('Ссылка скопирована');

    const timer = setTimeout(() => {
      setLinkNotification('');
    }, linkNotificationDuration);

    setLinkTimer(timer);
  }

  const options: WithDisplayPropertyType<IconTabType, false>[] = [
    {
      title: 'Ответить',
      icon: theme.icons.message,
      onClick: openMessages,
      display: permissions.downloads.comment,
    },
    {
      title: 'Скачать архивом',
      icon: theme.icons.archive,
      onClick: () => downloadClickHandler(true),
      display: permissions.downloads.upload,
    },
    {
      title: `Скачать файлами (${packageEntity.mediaCount})`,
      icon: theme.icons.download,
      onClick: () => downloadClickHandler(false),
      display: permissions.downloads.upload,
    },
    {
      title: 'Ссылка',
      notification: linkNotification,
      notificationDuration: linkNotificationDuration,
      icon: theme.icons.link,
      onClick: linkClickHandler,
    },
    {
      title: 'История',
      icon: theme.icons.history,
      onClick: openHistory,
      display: permissions.uploadHistory.read,
    },
    // {
    //   title: 'Маркер «Проверено»',
    //   icon: theme.icons.link,
    //   onClick: () => console.log('Маркер'),
    //   // display: permissions.downloads.setMarker,
    //   display: true,
    // },
  ].filter(item => item.display !== false).map(({ display, ...args }) => args);

  return (
    <S.OptionsCell onClick={e => e.stopPropagation()}>
      <OptionsButton onClick={onOptionsButtonClick} isActive={optionsOpenned} />
      {optionsOpenned && <S.StyledOptions options={options} />}
    </S.OptionsCell>
  )
}

export default withUserCheck(OptionsCell);