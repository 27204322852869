import { FieldProps } from "formik";
// components
import { Input, IInput } from "components/UI";
import ErrorsBlock from "components/common/ErrorsBlock";
// styles
import * as S from './style';

interface RenderInputProps extends IInput {
  error?: string,
}

const renderInput = ({ error, ...props }: RenderInputProps) => <T,>({ field, }: FieldProps<T>) => {
  return (
    <S.InputWrapper>
      <Input
        {...field}
        {...props}
        value={props.value}
      />
      {error &&
        <ErrorsBlock errors={[error]} type='fieldError' />
      }
    </S.InputWrapper>
  )
}

export default renderInput;