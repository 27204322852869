import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// types
import { IHandbook } from 'core/models/handbook/model.types';
import type { IValues as IValuesasILoginFormValues } from 'components/forms/LoginForm';
// thunks
import { SSOLoginReqThunk, loginThunk } from 'core/models/auth/thunks';
import { fetchHandbookThunk } from 'core/models/handbook/thunks';
// hooks
import { useAppDispatch } from 'core/store';
import { useAuthSelector, useHandbookSelector } from 'core/store/selectorHooks';
// utils
import { getSearchParam } from 'core/utils/urlQuery';
import { createLinkAndExecute } from 'core/utils/events/eventHandlers';
// components
import LoginForm from 'components/forms/LoginForm';
// styles
import * as S from "./style";

const AuthPage = () => {
  const { loginErrors } = useAuthSelector(undefined);
  const handbook = useHandbookSelector('handbook');
  const oauthSettings = handbook?.oauthSettings;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const submitHandler = ({ login, password, rememberMe }: IValuesasILoginFormValues) => {
    dispatch(loginThunk({ actionData: { navigate, rememberMe }, data: { login, password, } }));
  }

  const onOauthButtonClick = () => {
    if (!oauthSettings) return;
    RedirectToSSO(oauthSettings);
  }

  useEffect(() => {
    if (!handbook || !oauthSettings) {
      dispatch(fetchHandbookThunk());
    }
  }, [dispatch, handbook, oauthSettings]);

  const RedirectToSSO = (settings: IHandbook['oauthSettings']) => {
    createLinkAndExecute(
      `${settings.main_url}?client_id=${settings.client_id}&response_type=code&redirect_uri=${settings.redirect_url}&scope=openid&response_mode=query`
    );
  }

  useEffect(() => {
    const [codeSearchParam] = getSearchParam(search, 'code');
    if (codeSearchParam) {
      dispatch(SSOLoginReqThunk({
        data: { code: codeSearchParam.replace('code=', ''), },
        actionData: { navigate },
      }));
    }
  }, [dispatch, navigate, search]);

  return (
    <S.Wrapper>
      <S.Picture />
      <S.Content>
        <S.Title>Служба единого входа ТАСС</S.Title>
        <S.Description>Выполнить вход, используя учетную запись организации</S.Description>
        <LoginForm onSubmit={submitHandler} errors={Object.values(loginErrors)} />
        <S.OAuthButton onClick={onOauthButtonClick} text='Войти с помощью OAuth' />
        <S.Designation>©️ Корпорация Майкрософт (Microsoft Corporation), 2016</S.Designation>
      </S.Content>
    </S.Wrapper>
  )
}

export default AuthPage;