// import sanitizeHtml from "sanitize-html";
// types
import type ContentEditable from 'react-contenteditable';
// hooks
import { type ITemplateData, useContentEditableTemplate } from 'core/hooks/useContentEditableTemplate';
// styles
import * as S from './style';

export interface ITextArea {
  value: string,
  placeholder: string,
  templateData?: ITemplateData[],
  fieldRef?: React.MutableRefObject<ContentEditable | null>,
  onChange: (value: string) => void,
}

const TextArea = ({
  value,
  templateData = [],
  placeholder,
  fieldRef,
  onChange,
}: ITextArea) => {
  const [fieldValue, onChangeHandler] = useContentEditableTemplate({
    value,
    templateData,
    onChange
  });

  // const sanitizeConf = {
  //   allowedTags: ["b", "i", "em", "strong", "a", "p", "h1"],
  //   allowedAttributes: { a: ["href"] }
  // };

  // const sanitize = () => {
  //   console.log(sanitizeHtml(value, sanitizeConf))
  //   // onChange(sanitizeHtml(value));
  // };

  const onInputHandler = (e: React.FormEvent<HTMLDivElement>) => {
    onChangeHandler(e.currentTarget.innerHTML);
  }

  return (
    <S.Wrapper>
      <S.Content
        html={fieldValue}
        onChange={onInputHandler}
        placeholder={fieldValue ? '' : placeholder}
        ref={fieldRef}
      // onBlur={sanitize}
      />
    </S.Wrapper>
  )
}

export default TextArea;