import { useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import { ru } from "date-fns/locale";
// types
import type { Matcher, SelectSingleEventHandler } from "react-day-picker";
import { InputFocusEvent } from "core/types/simple.types";
// config
import { APIIntputDateFormat, userDateTimeFormat } from "core/constants/dateFormats";
// utils
import { formatDate, toLocaleString } from "core/utils/date";
// styles
import * as S from "./style";

interface IInputDate {
  disabledPeriod?: Matcher | Matcher[],
  value: string,
  type: 'start' | 'finish',
  changeHandler: (value: string) => void,
}

const InputDate = ({
  disabledPeriod,
  value,
  type,
  changeHandler
}: IInputDate) => {
  const [dayPickerOpenned, setDayPickerOpenned] = useState(false);
  const [month, setMonth] = useState<Date>(new Date());
  const dayPickerRef = useRef<HTMLParagraphElement | null>(null);

  const selectHandler: SelectSingleEventHandler = (date) => {
    if (date) {
      const formattedDate = formatDate(toLocaleString(date), userDateTimeFormat, APIIntputDateFormat);
      changeHandler(formattedDate);
      setDayPickerOpenned(false);
    }
  }

  const inputClickHandler = () => {
    setDayPickerOpenned(true);
  }

  const onInputBlur = (e: InputFocusEvent) => {
    if ((e.relatedTarget !== dayPickerRef.current && !e.relatedTarget?.className.includes('rdp')) || !dayPickerRef.current) {
      setDayPickerOpenned(false);
    } else {
      e.target.focus();
    }
  }

  return (
    <S.Wrapper>
      <S.Input
        placeholder='дд.мм.гггг'
        value={value}
        onClick={inputClickHandler}
        onBlur={onInputBlur}
        readOnly
      />
      <S.Icon />
      {dayPickerOpenned &&
        <S.DayPickerWrapper ref={dayPickerRef} tabIndex={0}>
          <DayPicker
            mode='single'
            selected={new Date(value)}
            onSelect={selectHandler}
            locale={ru}
            month={month}
            onMonthChange={setMonth}
            disabled={disabledPeriod}
          />
        </S.DayPickerWrapper>
      }
    </S.Wrapper>
  )
}

export default InputDate;