// types
import { IOption } from "core/types/simple.types";
// constants
import { loadingMessage } from "core/constants/values";

export const noDataOptions: IOption[] = [
  { id: 0, value: '', name: 'Нет данных', disabled: true, }
];

export const loadingOptions: IOption[] = [
  { id: 0, value: '', name: loadingMessage, disabled: true, }
]

export const weekDays = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  0: 'Воскресенье',
};

export const months = {
  0: ['Январь', 'Января'],
  1: ['Февраль', 'Февраля'],
  2: ['Март', 'Марта'],
  3: ['Апрель', 'Апреля'],
  4: ['Май', 'Мая'],
  5: ['Июнь', 'Июня'],
  6: ['Июль', 'Июля'],
  7: ['Август', 'Августа'],
  8: ['Сентябрь', 'Сентября'],
  9: ['Октябрь', 'Октября'],
  10: ['Ноябрь', 'Ноября'],
  11: ['Декабрь', 'Декабря'],
}
