import { useEffect, useState } from 'react';
// types
import { FilterUnionType, FiltersUnionType, FiltersType, ICheckboxFilter, ITagFilter, FilterTypes } from 'core/types/filter.types';
import { AnyFunctionType, ClickEventType } from 'core/types/simple.types';
import { WithUserCheckType } from 'core/hocs/withUserCheck';
// utils
import { getFilterTags } from './utils/dataUtils';
import { getIsFiltersActive } from './utils/isFiltersActive';
// hooks
import { useHandbookSelector } from 'core/store/selectorHooks';
// hocs
import { withUserCheck } from 'core/hocs';
// styles
import * as S from './style';

export type SetFilterValueType = (filter: FiltersUnionType, submit?: boolean, closeFilter?: boolean) => void;

interface IFiltersLine extends WithUserCheckType {
  title: string,
  filtersConfig: FilterUnionType[],
  filtersValues: Partial<FiltersType>,
  emptyFilters: Partial<FiltersType>,
  hasControls?: boolean,
  confirmButtonText?: string,
  confirmButtonHandler?: AnyFunctionType,
  onFiltersChange: (filtersValues: Partial<FiltersType>) => void,
}

const FiltersLine = ({
  permissions,
  title,
  filtersConfig,
  filtersValues,
  emptyFilters,
  hasControls = true,
  confirmButtonText,
  confirmButtonHandler,
  onFiltersChange,
}: IFiltersLine) => {
  const [activeFilter, setActiveFilter] = useState<{
    key: number,
    checkboxItems?: [ICheckboxFilter['checkboxes'], ICheckboxFilter['checkboxes']],
  } | null>(null);
  const [tagsOpenned, setTagsOpenned] = useState(true);
  const { isAuthorActive, isLocationActive, isContentActive, isPeriodActive, isStatusActive } = getIsFiltersActive(filtersValues);
  const handbook = useHandbookSelector('handbook');

  const hasActiveFilter = hasControls
    ? isAuthorActive || isLocationActive || isContentActive || isPeriodActive || isStatusActive
    : false;

  const filterButtonHandler = (key: number, filter: FilterUnionType, e: ClickEventType) => {
    e.stopPropagation();

    if (activeFilter?.key === key) {
      setActiveFilter(null);
      return;
    }

    if (filter.type === FilterTypes.Content || filter.type === FilterTypes.Status) {
      setActiveFilter({
        key,
        checkboxItems: filter.checkboxes.reduce<[ICheckboxFilter['checkboxes'], ICheckboxFilter['checkboxes']]>(
          (acc, item) => filtersValues[filter.type]?.some(statusValue => statusValue === item.value)
            ? [[...acc[0], item], [...acc[1]]] : [[...acc[0]], [...acc[1], item]],
          [[], []]
        )
      });
      return;
    }

    setActiveFilter({ key });
  }

  const setFilterValue: SetFilterValueType = (filter, closeFilter = false) => {
    const updatedFiltersValues = {
      ...filtersValues,
      ...filter
    };

    onFiltersChange(updatedFiltersValues);

    if (closeFilter) {
      setActiveFilter(null);
    }
  }

  const onCancelButtonClick = () => {
    onFiltersChange(emptyFilters);
  }

  const onDeleteTagFilter = (tagFilter: ITagFilter) => {
    switch (tagFilter.name) {
      case FilterTypes.Location:
        setFilterValue({ location: filtersValues[tagFilter.name]?.filter(item => item !== tagFilter.value) || [] });
        break;
      case FilterTypes.Author:
        setFilterValue({ fullName: filtersValues[tagFilter.name]?.filter(item => item !== tagFilter.value) || [] });
        break;
      case FilterTypes.Status:
        setFilterValue({ editingStatus: filtersValues[tagFilter.name]?.filter(item => item !== tagFilter.value) || [] });
        break;
      case FilterTypes.Content:
        setFilterValue({ contentType: filtersValues[tagFilter.name]?.filter(item => item !== tagFilter.value) || [], });
        break;
      case FilterTypes.Period:
        setFilterValue({ startDate: '', finishDate: '', });
        break;
    }
  }

  const getFilterCounter = (filter: FilterUnionType): number => {
    switch (filter.type) {
      case FilterTypes.Location: return filtersValues[FilterTypes.Location]?.length || 0;
      case FilterTypes.Author: return filtersValues[FilterTypes.Author]?.length || 0;
      case FilterTypes.Status: return filtersValues[FilterTypes.Status]?.length || 0;
      case FilterTypes.Content: return filtersValues[FilterTypes.Content]?.length || 0;
      case FilterTypes.Period: return isPeriodActive ? 1 : 0;
      default: return 0;
    }
  }

  const documentClickHandler = () => {
    setActiveFilter(null);
  }

  useEffect(() => {
    document.addEventListener('click', documentClickHandler);

    return () => {
      document.removeEventListener('click', documentClickHandler);
    }
  }, []);

  return (
    <S.Wrapper>
      <S.FiltersWrapper>
        <S.Title>
          {title}
        </S.Title>
        {permissions.downloads.read &&
          <S.Content>
            {hasActiveFilter &&
              <>
                <S.TagsButton type="button" onClick={() => setTagsOpenned(!tagsOpenned)} $openned={tagsOpenned}>Показать теги</S.TagsButton>
                <S.CancelButton type="button" onClick={onCancelButtonClick}>Сбросить</S.CancelButton>
              </>
            }
            {filtersConfig.map((filter, key, arr) =>
              <S.FilterWrapper key={key}>
                <S.FilterButton type="button" onClick={(e) => filterButtonHandler(key, filter, e)} $counter={getFilterCounter(filter)} $openned={key === activeFilter?.key}>
                  {filter.title}
                </S.FilterButton>
                {key === activeFilter?.key &&
                  <S.StyledFilter
                    filter={filter}
                    $placeOnRight={key + 3 > arr.length}
                    filtersValues={filtersValues}
                    checkboxItems={activeFilter.checkboxItems || [[], []]}
                    setActiveFilter={setActiveFilter}
                    setFilterValue={setFilterValue}
                  />
                }
              </S.FilterWrapper>
            )}
            {confirmButtonText && confirmButtonHandler &&
              <S.StyledButton text={confirmButtonText} onClick={confirmButtonHandler} />
            }
          </S.Content>
        }
      </S.FiltersWrapper>
      {tagsOpenned && hasActiveFilter && handbook &&
        <S.StyledTagsList tags={getFilterTags(filtersValues, handbook)} onCloseTag={onDeleteTagFilter} />
      }
    </S.Wrapper>
  )
}

export default withUserCheck(FiltersLine);