// hooks
import { useAppDispatch } from "core/store";
// thunks
import { logoutThunk } from "core/models/auth/thunks";
// types
import { IUser } from "core/models/users/model.types";
// utils
import { getCookie } from "core/utils/cookies/cookie";
// styles
import * as S from './style';
import { useNavigate } from "react-router-dom";

interface IUserBlock {
  user: IUser
}

const UserBlock = ({
  user,
}: IUserBlock) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = getCookie('token');
  const refreshToken = getCookie('refreshToken');

  const logoutHandler = () => {
    dispatch(logoutThunk({ actionData: { navigate }, data: { token, refreshToken, } }));
  }

  return (
    <S.Wrapper>
      <S.UserInfo>
        <S.UserName>{user.fullName}</S.UserName>
        <S.UserRole>{user.role}</S.UserRole>
      </S.UserInfo>
      <S.LogoutButton type="button" onClick={logoutHandler}></S.LogoutButton>
    </S.Wrapper>
  )
}

export default UserBlock;