import { useEffect } from "react";
import { AsyncThunk } from "@reduxjs/toolkit";
// thunks
import { fetchNotificationsThunk, fetchPackageHistoryThunk } from "core/models/events/thunks";
import { fetchPackageCommentsThunk } from "core/models/messages/thunks";
import { downloadPackageThunk, fetchPackageMediaThunk } from "core/models/packages/thunks";
// hooks
import { useModalSelector } from "core/store/selectorHooks";
import { useAppDispatch } from "core/store";
// types
import { IPackage } from 'core/models/packages/model.types';
import { ModalsType } from "core/models/modal/model.types";
import { IMessage } from "core/models/messages/model.types";
import { IModalState } from "core/models/modal/slice";

type ModalAsyncThunk = AsyncThunk<any, any, any>;

const getThunk = (type: ModalsType, data: IModalState['data'], additionalData: Omit<IModalState, 'data'>): ModalAsyncThunk[] | null => {
  switch (type) {
    case 'messages': {
      let thunks = [];
      if ((data && !data.messages) || additionalData.messagesShouldFetch) thunks.push(fetchPackageCommentsThunk);
      return thunks as ModalAsyncThunk[];
    }
    case 'history': {
      let thunks = [];
      if (data && !data.history) thunks.push(fetchPackageHistoryThunk);
      return thunks as ModalAsyncThunk[];
    }
    case 'notifications': {
      let thunks = [];
      if (data && !data.notifications?.length) thunks.push(fetchNotificationsThunk);
      return thunks as ModalAsyncThunk[];
    }
    case 'gallery': {
      if (data && !data.package?.medias?.length) return [fetchPackageMediaThunk] as [ModalAsyncThunk];
      if (data && !data.package?.mediasURLs?.length) return [downloadPackageThunk] as [ModalAsyncThunk];
      return null
    }
  }
}

const getDataKey = (type: ModalsType): keyof Omit<IModalState['data'], 'id'> => {
  switch (type) {
    case 'gallery': return 'package';
    case 'messages': return 'messages';
    case 'history': return 'history';
    case 'notifications': return 'notifications';
  }
}

const isEmptyData = (type: ModalsType, data: Omit<IModalState['data'], 'id'>): boolean => {
  switch (type) {
    case 'gallery':
      if (!data.package?.medias?.length || !data.package?.mediasURLs?.length) return true;
      return false;
    case 'history':
    case 'messages':
    case 'notifications': return false;
  }
}

type WithModalFetchType = {
  data: IModalState['data']['history'] | IMessage[] | IPackage | IModalState['data']['notifications'],
  id?: number,
};

interface IParams {
  async?: boolean,
  withFetch?: boolean,
}

const withModalFetch = <T extends WithModalFetchType>(Component: React.ComponentType<T>, type: ModalsType, { async = false, withFetch = true }: IParams = {}) => (props: Omit<T, 'data' | 'id'>) => {
  const { data, ...additionalData } = useModalSelector(undefined);
  const { id } = data;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (withFetch) {
      const fetchThunks = async (thunks: ModalAsyncThunk[]) => {
        for (const thunk of thunks) {
          if (id) {
            // throw updateGalleryData only for fetchPackageMediaThunk and downloadPackageThunk
            if (async) await dispatch(thunk({ data: { id }, actionData: { updateGalleryData: true, } }));
            else dispatch(thunk({ data: { id } }));
          } else {
            if (async) await dispatch(thunk({ data: {} }));
            else dispatch(thunk({ data: {} }));
          }
        }
      }

      const thunks = getThunk(type, data, additionalData);

      if (thunks) {
        fetchThunks(thunks);
      }
    }
  }, [id, data, dispatch, additionalData, withFetch, async]);

  if (isEmptyData(type, data)) return <></>

  return (
    <Component {...props as T} data={data[getDataKey(type)]} id={id} />
  )
}

export default withModalFetch;