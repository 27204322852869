import styled from "styled-components";
// styles
import { simpleText, smallText } from "styles/common";
import theme from "core/theme/theme";

interface StatusProps {
  $color: string,
  $checked: boolean,
  $small: boolean,
  $isButton: boolean,
}

export const Status = styled.button<StatusProps>`
  ${({ $small }) => $small ? smallText : simpleText};
  display: flex;
  align-items: center;
  gap: ${({ $small }) => $small ? '0 4px' : '0 10px'};
  width: fit-content;
  padding: 2px 0;
  background-color: ${({ $checked }) => $checked ? theme.color.nurseGray : 'transparent'};
  cursor: ${({ $isButton }) => $isButton ? 'pointer' : 'default'};

  &::before {
    content: '';
    min-width: 10px;
    min-height: 10px;
    background-color: ${({ $color }) => $color};
    border-radius: 100%;
  }
`;