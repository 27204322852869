// utils
import createAsyncThunkExtended, { createAsyncThunkExtendedNoActionData } from "core/store/extendedThunk";
// api
import { changeMediaOrientationReq, changePackageStatusReq, downloadPackageReq, fetchFileReq, fetchOnePackage, fetchPackagesReq } from "./packages.api";

export const fetchPackagesThunk = createAsyncThunkExtendedNoActionData(
  'packages/fetch',
  fetchPackagesReq,
);

export const fetchOnePackageThunk = createAsyncThunkExtended(
  'packages/package/fetch',
  fetchOnePackage,
  (response, { singlePackage }: { singlePackage: boolean },) => {
    return { data: response, singlePackage };
  }
);

export const fetchPackageMediaThunk = createAsyncThunkExtended(
  'packages/media/fetch',
  fetchOnePackage,
  (response, { updateGalleryData = false }: { updateGalleryData?: boolean }) => {
    return { data: response, updateGalleryData };
  }
);

export const changePackageStatusThunk = createAsyncThunkExtended(
  'packages/status/change',
  changePackageStatusReq,
  (response, _, { id, status }) => {
    return { ...response, packageId: id, status, };
  }
);

export const fetchFileThunk = createAsyncThunkExtendedNoActionData(
  'packages/file/fetch',
  fetchFileReq,
);

export const downloadPackageThunk = createAsyncThunkExtended(
  'packages/media/load',
  downloadPackageReq,
  (response, { updateGalleryData = false }: { updateGalleryData?: boolean }, { id }) => {
    return { data: {...response, packageId: id}, updateGalleryData, };
  }
);

export const changeMediaOrientationThunk = createAsyncThunkExtended(
  'package/media/change-orientation',
  changeMediaOrientationReq,
  (response, { uploadId }: { uploadId: number }, { rotationMedia }) => {
    return { ...response, rotationMedia, uploadId };
  }
);
