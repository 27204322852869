import { useNavigate } from 'react-router-dom';
// types
import { IErrorData } from 'core/models/error/model.types';
// actions
import { setErrorData } from "core/models/error/slice";
// hooks
import { useAppDispatch } from 'core/store';
// hocs
import { withFetch } from 'core/hocs';
// components
import HrefText from 'components/common/HrefText';
// styles
import * as S from './style';
import { notFoundErrorData } from 'core/config/errorsData';

interface IErrorPage {
  data: IErrorData,
  children: React.ReactNode,
}

const ErrorPage = ({
  data,
}: IErrorPage) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { message, iconType, buttonLink, buttonText, reload, } = data || notFoundErrorData;

  const onButtonClick = () => {
    navigate({ pathname: buttonLink });
    dispatch(setErrorData({ type: null }));

    if (reload) {
      window.location.reload();
    }
  }

  return (
    <S.Wrapper>
      <S.Inner>
        <S.Icon $iconType={iconType} />
        <S.Message>
          <h4>{message.header}</h4>
          {message.text && message.text.map((item, key) =>
            <HrefText key={key} text={item} />
          )}
        </S.Message>
        {buttonText && buttonLink &&
          <S.StyledButton text={buttonText} onClick={onButtonClick} />
        }
      </S.Inner>
    </S.Wrapper>
  )
}

export default withFetch(ErrorPage, 'error', { withFetch: false, withEmptyDataCheck: false, });