import styled from "styled-components";
// components
import Counter from "components/common/Counter";
// styles
import { smallText } from "styles/common";
import theme from "core/theme/theme";

export const Form = styled.form`
  width: 410px;
`;

export const MessageFieldWrapper = styled.div`
  position: relative;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const StatusesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`;

export const StyledCounter = styled(Counter)`
  ${smallText};
  color: ${theme.color.silverGray};
  position: absolute;
  bottom: 8px;
  right: 8px;
`
