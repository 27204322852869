import styled, { css } from "styled-components";
// icons
import checkedIcon from 'assets/icons/control.svg';
// styles
import theme from "core/theme/theme";
import { simpleText, solidBorderProperty } from "styles/common";

interface WrapperProps {
  $withBottomBorder: boolean,
}

const withBottomBorderStyle = css`
  padding-bottom: 16px;
  border-bottom: ${solidBorderProperty};

  &+* {
    padding-top: 8px;
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  gap: 0 8px;
  ${({ $withBottomBorder }) => $withBottomBorder ? withBottomBorderStyle : ''};
`;

export const Checkbox = styled.input`
  cursor: pointer;
  appearance: none;
  display: flex;
  align-items: center;
  gap: 0 8px;
  min-width: 20px;
  min-height: 20px;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${theme.color.white};
    border: 1px solid ${theme.color.nobelGray};
    border-radius: 2px;
  }

  &:checked {
    &::after {
      background: url(${checkedIcon}) no-repeat center/cover;
    }
  }
`;

export const Label = styled.label`
  cursor: pointer;
  ${simpleText};
`