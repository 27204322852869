import { FieldProps } from "formik";
import { Checkbox, ICheckbox } from "components/UI";

type TRenderCheckbox = Omit<ICheckbox, 'onChange' | 'checked'>;

const renderCheckbox = (props: TRenderCheckbox) => <T,>({ form, field, }: FieldProps<T>) => {
  const onChange = () => {
    form.setFieldValue(field.name, !form.values[field.name]);
  }
  return <Checkbox
    {...field}
    {...props}
    checked={form.values[field.name]}
    onChange={onChange}
  />
}

export default renderCheckbox;