// types
import { routesPrivate, routesPublic } from "core/constants/routes";
import { IErrorData } from "core/models/error/model.types";

export const noPermErrorData: IErrorData = {
  message: {
    header: 'У вас недостаточно прав для просмотра этого ресурса.',
    text: [
      'Вам необходимо запросить нужные права в соответствии с вашими должностными полномочиями в sd@tass.ru. Как заявка будет выполнена, вам необходимо будет заново зайти',
    ],
  },
  iconType: 'logo',
  buttonText: 'Перейти на главную',
  buttonLink: routesPrivate.DOWNLOADS,
}

export const otherErrorData: IErrorData = {
  message: {
    header: 'Не удалось войти в систему.',
    text: [
      'Попробуйте обновить страницу позднее.',
    ],
  },
  iconType: 'logo',
  buttonText: 'Обновить страницу',
  buttonLink: routesPublic.AUTH,
  reload: true,
}

export const maintenanceErrorData: IErrorData = {
  message: {
    header: 'ТАСС.Ассистент временно на техническом обслуживании.',
    text: [
      'Пожалуйста, подождите еще немного. Как правило, \nэто занимает не более часа.',
      'Если мы будем задерживаться — напишите, пожалуйста, \nписьмо на почту sd@tass.ru.'
    ],
  },
  iconType: 'settings',
  buttonText: 'Обновить страницу',
  buttonLink: routesPublic.AUTH,
  reload: true,
}

export const serverErrorData: IErrorData = {
  message: {
    header: 'Ошибка 502',
    text: [
      'Произошла ошибка. Попробуйте зайти на страницу позже. Если проблема сохранится - напишите, пожалуйста, письмо на почту sd@tass.ru',
    ],
  },
  iconType: 'settings',
}

export const notFoundErrorData: IErrorData = {
  message: {
    header: 'Страница не найдена. Проверьте адрес ссылки \nили перейдите на главную страницу.',
  },
  iconType: 'settings',
  buttonText: 'Перейти на главную',
  buttonLink: routesPrivate.DOWNLOADS,
};
