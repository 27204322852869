// types
import { TokenResponse } from "./model.types";
import type { AxiosResponseDataType } from "core/utils/requestParser";
// Utils
import { auth, updateAxiosInstancesToken } from "core/utils/axiosInstance"
import requestParser from "core/utils/requestParser";

export interface LoginReqProps {
  login: string,
  password: string,
}

export const loginReq = requestParser((data: LoginReqProps) =>
  auth.post('token/', data).then((response) => {
    updateAxiosInstancesToken(response.data.access_token);
    return response;
  })) as (props: LoginReqProps) => AxiosResponseDataType<TokenResponse>;


export interface SSOLoginReqProps {
  code: string,
}

export const SSOLoginReq = requestParser(({ code }: SSOLoginReqProps) =>
  auth.post(`oauth/redirect/?code=${code}`).then((response) => {
    updateAxiosInstancesToken(response.data.access_token);
    return response;
  })) as (props: SSOLoginReqProps) => AxiosResponseDataType<TokenResponse>;

export interface LogoutReqProps {
  token: string,
  refreshToken: string,
}

export const logoutReq = requestParser(({ token, refreshToken, }: LogoutReqProps) => auth.post('token/remove/', { refresh_token: refreshToken, }, {
  headers: {
    Authorization: `Bearer ${token}`
  }
})) as (props: LogoutReqProps) => AxiosResponseDataType<string>;
