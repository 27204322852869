import { createGlobalStyle } from "styled-components";
// styles
import theme from "core/theme/theme";
import { menuBoxShadow } from "./common";

const DayPickerStyles = createGlobalStyle`
  .rdp {
    padding: 20px;
    position: absolute;
    top: 115%;
    left: 0;
    z-index: ${theme.zIndex.menu};
    border-radius: 12px 12px 12px 12px;
    background-color: ${theme.color.white};
    ${menuBoxShadow};
  }

  .rdp-caption {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .rdp-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: calc(50% - 9px);
    left: 0;
  }

  .rdp-cell {
    text-align: center;
  }

  .rdp-day {
    width: 40px;
    padding: 8px 0;
    border-radius: 8px;
  }

  .rdp-day_selected {
    background-color: ${theme.color.altoGray};
  }

  .rdp-day_disabled {
    /* background-color: ${theme.color.altoGray}; */
    color: ${theme.color.nobelGray};
  }
`;

export default DayPickerStyles;