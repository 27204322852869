// types
import { ModalsType } from "core/models/modal/model.types";
// constants
import { messagesModalWidth, modalWrapperPadding } from "core/constants/values";

export const chooseWidth = (type: ModalsType) => {
  switch (type) {
    case 'gallery':
    case 'history': return `calc(100% - ${modalWrapperPadding * 2}px)`;
    case 'messages': return `${messagesModalWidth}px`;
    case 'notifications': return 'fit-content';
    default: return 'fit-content';
  }
}

export const chooseHeight = (type: ModalsType) => {
  switch (type) {
    case 'gallery': return `calc(100% - ${modalWrapperPadding * 2}px)`;
    case 'history':
    case 'messages': return `auto`;
    case 'notifications': return '100%';
    default: return 'auto';
  }
}

export const chooseLeft = (type: ModalsType) => {
  switch (type) {
    case 'gallery':
    case 'history': return `${modalWrapperPadding}px`;
    case 'messages': return `calc(50% - ${messagesModalWidth / 2}px)`;
    case 'notifications': return 'auto';
    default: return 'auto';
  }
}
