// types
import { ITagFilter } from 'core/types/filter.types';
// styles
import * as S from './style';

interface IFilterTag {
  tag: ITagFilter,
  onCloseTag: (filter: ITagFilter) => void,
}

const FilterTag = ({
  tag,
  onCloseTag,
}: IFilterTag) => {
  return (
    <S.Wrapper>
      {tag.label}: {tag.value}
      <button type="button" onClick={() => onCloseTag(tag)}/>
    </S.Wrapper>
  )
}

export default FilterTag;