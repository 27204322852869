import { useEffect, useState } from 'react';
// utils
import { getVideoTimeLine } from 'core/utils/time';
// components
import VideoLine from '../VideoLine';
// styles
import * as S from './style';

interface IVideoControls {
  videoTarget: HTMLVideoElement,
  videoLoaded: boolean,
  src: string | undefined,
}

const VideoControls = ({
  videoTarget,
  videoLoaded,
  src,
}: IVideoControls) => {
  const [isPlaying, setIsPlaying] = useState(videoTarget.currentTime > 0 && !videoTarget.ended && !videoTarget.paused);
  const [isMuted, setIsMuted] = useState(videoTarget.muted);
  const [currentTime, setCurrentTime] = useState(0);
  const [bufferedTime, setBufferedTime] = useState(0);

  useEffect(() => {
    videoTarget.addEventListener('timeupdate', () => {
      if (videoTarget.ended) {
        setIsPlaying(false);
      }

      setCurrentTime(videoTarget.currentTime);

      if (videoTarget.buffered.length > 0) {
        setBufferedTime(videoTarget.buffered.end(videoTarget.buffered.length - 1));
      }
    });
  }, [videoTarget]);

  useEffect(() => {
    setIsPlaying(false)
  }, [src]);

  const playButonHandler = () => {
    if (isPlaying) {
      videoTarget.pause();
      setIsPlaying(false);
    } else {
      videoTarget.play();
      setIsPlaying(true);
    }
  }

  const muteButtonHandler = () => {
    if (isMuted) {
      videoTarget.muted = false;
      setIsMuted(false);
    } else {
      videoTarget.muted = true;
      setIsMuted(true);
    }
  }

  return (
    <>
      <S.ControlsWrapper>
        <VideoLine
          progressPercent={currentTime * 100 / videoTarget.duration}
          bufferedPercent={bufferedTime * 100 / videoTarget.duration}
          videoTarget={videoTarget}
        />
        <S.Controls>
          <S.PlayButton type="button" disabled={!videoLoaded} onClick={playButonHandler} $isPlaying={isPlaying} />
          <S.MuteButton type="button" disabled={!videoLoaded} onClick={muteButtonHandler} $isMuted={isMuted} />
          <S.Time>{getVideoTimeLine(currentTime, videoTarget.duration)}</S.Time>
        </S.Controls>
      </S.ControlsWrapper>
      {videoTarget && !isPlaying &&
        <S.StartWrapper>
          <S.StartButton type="button" disabled={!videoLoaded} onClick={playButonHandler} />
        </S.StartWrapper>
      }
    </>
  )
}

export default VideoControls;