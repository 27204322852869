// api
import { addMessageReq, fetchPackageCommentsReq } from "./messages.api";
import createAsyncThunkExtended, { createAsyncThunkExtendedNoActionData } from "core/store/extendedThunk";

export const addMessageThunk = createAsyncThunkExtended(
  'downloads/comments/add',
  addMessageReq,
  (response, _, { id }) => {
    return { data: response, packageId: id };
  }
);

export const fetchPackageCommentsThunk = createAsyncThunkExtendedNoActionData(
  'downloads/comments/fetch',
  fetchPackageCommentsReq,
);