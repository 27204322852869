import { createSlice } from '@reduxjs/toolkit'
// thunks
import {
  changePackageStatusThunk,
  downloadPackageThunk,
  fetchOnePackageThunk,
  fetchPackageMediaThunk,
  fetchPackagesThunk,
} from './thunks';
import { addMessageThunk } from '../messages/thunks';
// types
import { IPackage } from './model.types';
import { IMessage } from '../messages/model.types';
// utils
import { replacePackageElemById } from './utils';
export interface IPackagesState {
  isLoading: boolean,
  data: IPackage[],
  shouldFetchPackageId: number | null,
  totalCount: number,
  comments: IMessage[],
}

const initialState: IPackagesState = {
  isLoading: false,
  data: [],
  shouldFetchPackageId: null,
  totalCount: 0,
  comments: [],
}

const downloadsSlice = createSlice({
  name: 'downloads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPackagesThunk.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchPackagesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.totalCount = payload.totalCount;

      if (payload.page > 1) {
        state.data = [...state.data, ...payload.data];
      } else {
        state.data = payload.data;
      }
    });
    builder.addCase(fetchOnePackageThunk.fulfilled, (state, { payload }) => {
      if (payload.singlePackage) {
        state.data = [payload.data];
      } else {
        state.data = replacePackageElemById(state.data, payload.data);
        state.shouldFetchPackageId = null;
      }
    });
    builder.addCase(fetchPackageMediaThunk.fulfilled, (state, { payload }) => {
      state.data = replacePackageElemById(state.data, payload.data);
    });

    builder.addCase(downloadPackageThunk.fulfilled, (state, { payload }) => {
      const changingPackage = state.data.find(item => item.uploadId === payload.data.packageId);

      if (changingPackage) {
        state.data = replacePackageElemById(state.data, { ...changingPackage, mediasURLs: payload.data.medias, });
      }
    });

    builder.addCase(addMessageThunk.fulfilled, (state, { payload }) => {
      state.shouldFetchPackageId = payload.packageId;
    });

    builder.addCase(changePackageStatusThunk.fulfilled, (state, { payload }) => {
      state.shouldFetchPackageId = payload.packageId;
    });
  },
})

const { reducer: downloadsReducer } = downloadsSlice;

export default downloadsReducer;
