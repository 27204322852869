// types
import { IOption, NullableRefType } from 'core/types/simple.types';
// styles
import * as S from './style';

interface ISelect {
  selectedValue: string,
  options: IOption[],
  selectRef: NullableRefType<HTMLUListElement>,
  className?: string,
  optionClickHandler: (value: string) => void
}

const Select = ({
  selectedValue,
  options,
  selectRef,
  className,
  optionClickHandler,
}: ISelect) => {
  return (
    <S.Wrapper className={className} ref={selectRef} tabIndex={0}>
      {options.map(({ id, value, name, disabled }) =>
        <S.Option
          key={id}
          onClick={disabled ? undefined : () => optionClickHandler(value)}
          $selected={selectedValue === value}
          $disabled={Boolean(disabled)}
        >
          {name}
        </S.Option>
      )}
    </S.Wrapper>
  )
}

export default Select;
