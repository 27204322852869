import styled from "styled-components";
import theme from "core/theme/theme";
import { simpleText } from "styles/common";

interface ButtonProps {
  $transparent: boolean,
}

export const Button = styled.button<ButtonProps>`
  padding: 8px 16px;
  background-color: ${({ $transparent }) => $transparent ? theme.color.white : theme.color.blue};
  border: 1px solid ${({ $transparent }) => $transparent ? theme.color.silverGray : theme.color.blue};
  ${simpleText};
  color: ${({ $transparent }) => $transparent ? theme.color.black : theme.color.white};

  &:disabled {
    background-color: ${theme.color.white};
    color: ${theme.color.nobelGray};
    border: 1px solid ${theme.color.nobelGray};
    cursor: not-allowed;
  }
`