// utils
import { cutSubStrArr } from "./base/baseUtils";

interface IClearContentEditableParams {
  value: string,
  subStrArr?: string[],
  replaceNbsp?: boolean,
  placeLineFeeds?: boolean,
  cutValue?: boolean,
}

export const clearContentEditable = ({ value, subStrArr = [], cutValue = true, replaceNbsp = true, placeLineFeeds = false }: IClearContentEditableParams): string => {
  let newValue = value;
  if (cutValue) {
    const staticSubStrArr = ['<br>', '<p>', '</p>', '</span>',];
    if (placeLineFeeds) {
      let tempValue = cutSubStrArr(value, [...subStrArr, ...staticSubStrArr,]).replaceAll('<div>', '\n');
      const m = tempValue.match(/<\/div>[^<^\n]/g);
      m?.forEach(i => {
        const r = i.replace('</div>', '');
        tempValue = tempValue.replace(new RegExp(`</div>${r}`, 'g'), `\n${r}`);
      })
      newValue = tempValue.replaceAll('<br>', '\n').replaceAll(/<\/div>/g, '');
    } else {
      newValue = cutSubStrArr(value, [...subStrArr, ...staticSubStrArr, '<div>', '</div>',]);
    }
  }

  if (replaceNbsp) {
    newValue = newValue.replaceAll('&nbsp;', ' ');
  } else {
    while (Number(newValue.match(/&nbsp;/g)?.length) >= 2) {
      newValue = newValue.replace('&nbsp;', ' ');
    }
  }

  return newValue;
}