import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AsyncThunk } from "@reduxjs/toolkit";
// thunks
import { fetchPackagesThunk } from "core/models/packages/thunks";
import { fetchStatisticsThunk } from "core/models/statistics/thunks";
// types
import { IPackage } from 'core/models/packages/model.types';
import { useAppDispatch } from "core/store";
import { IState } from '../../store/state.type';
import { IStatistic } from "core/models/statistics/model.types";
import { IErrorData } from "core/models/error/model.types";
import { IReqError } from "core/utils/requestParser";
// components
import Loader from "components/common/Loader";
// styles
import * as S from './style';

type ComponentType = 'downloads' | 'statistics' | 'error';

const getThunk = (type: ComponentType): AsyncThunk<any, any, { rejectValue: IReqError; }> | null => {
  switch (type) {
    case 'downloads': return fetchPackagesThunk;
    case 'statistics': return fetchStatisticsThunk;
    default: return null;
  }
}

type WithFetchType = {
  data: IPackage[] | IStatistic | IErrorData | null,
  children: React.ReactNode,
};

interface IParams {
  withFetch?: boolean, 
  withQuery?: boolean,
  withEmptyDataCheck?: boolean,
}

const withFetch = <T extends WithFetchType>(Component: React.ComponentType<T>, type: ComponentType, params: IParams = {},) => (props: Omit<T, 'data' | 'children' | 'isLoading' >) => {
  const { isLoading, data, } = useSelector((state: IState) => state[type]);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const withQuery = params.withQuery ?? true;
  const withFetch = params.withFetch ?? true;
  const withEmptyDataCheck = params.withEmptyDataCheck ?? true;

  useEffect(() => {
    if (withFetch) {
      const thunk = getThunk(type);
      if (thunk) {
        dispatch(thunk({ data: withQuery ? { query: location.search } : {}, }));
      }
    }
  }, [dispatch]);

  if (withEmptyDataCheck && !data) {
    return <></>
  }

  return (
    <Component {...props as T} data={data} isLoading={isLoading}>
      <S.LoaderWrapper>
        {isLoading &&
          <Loader />
        }
      </S.LoaderWrapper>
    </Component>
  )
}

export default withFetch;