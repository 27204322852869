import axios from "axios";
// types
import type { AxiosResponseDataType } from "core/utils/requestParser";
import { IDownloadReq, IPackage, IPackageStatusReq, IPackagesReq, IRotationMedia, } from "./model.types";
import { IPagination, IdPropsType } from "../types/RequestProps.types";
import { StatusesTypes } from "../handbook/model.types";
// utils
import { mediaWeb } from "core/utils/axiosInstance"
import requestParser from "core/utils/requestParser";

const URL = 'packages';

interface fetchPackagesProps extends IPagination {
  query: string,
}

export const fetchPackagesReq = requestParser((props: fetchPackagesProps) =>
  mediaWeb.get(`${URL}/${props.query || ''}${props.query ? '&' : '?'}page_size=${props.pageSize || 10}&page=${props.pageCount || 1}`)
) as (props: fetchPackagesProps) => AxiosResponseDataType<IPackagesReq>;

interface FetchOnePackageProps extends IdPropsType { };

export const fetchOnePackage =
  requestParser(({ id }: FetchOnePackageProps) => mediaWeb.get(`${URL}/${id}/`)) as
  (props: FetchOnePackageProps) => AxiosResponseDataType<IPackage>;

export interface ChangePackageStatusReqProps extends IdPropsType {
  status: StatusesTypes,
  comment?: string,
  answerMessageId?: number | null,
}

export const changePackageStatusReq =
  requestParser(({ id, ...data }: ChangePackageStatusReqProps) => mediaWeb.patch(`${URL}/${id}/`, data)) as
  (props: ChangePackageStatusReqProps) => AxiosResponseDataType<IPackageStatusReq>;

interface DownloadPackageReqProps extends IdPropsType {
  mediaIds?: number[],
}

export const downloadPackageReq =
  requestParser(({ mediaIds, id }: DownloadPackageReqProps) => {
    const queryIds = mediaIds?.reduce((acc, id) => `${acc ? `${acc},` : ''}${id}`, '') || null;
    return mediaWeb.get(`${URL}/${id}/download/${queryIds ? `?ids=${queryIds}` : ''}`);
  }) as
  (data: DownloadPackageReqProps) => AxiosResponseDataType<IDownloadReq>;

interface ChangeMediaOrientationReqProps {
  uploadId: number,
  rotationMedia: IRotationMedia[],
}

export const changeMediaOrientationReq =
  requestParser((data: ChangeMediaOrientationReqProps) => mediaWeb.post('package/media/change-orientation/', data)) as
  (data: ChangeMediaOrientationReqProps) => AxiosResponseDataType<{ taskId: string }>;

interface FetchFileReqProps {
  url: string,
}

export const fetchFileReq =
  requestParser(({ url }: FetchFileReqProps) => axios.get(url, { responseType: 'arraybuffer' })) as
  (data: FetchFileReqProps) => AxiosResponseDataType<ArrayBuffer>;
