import styled from "styled-components";
// styles
import theme from "core/theme/theme";

interface ButtonProps {
  $isActive: boolean,
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 3px;
  width: 20px;
  height: 20px;
  background-color: ${({ $isActive }) => $isActive ? theme.color.hazeGray : 'transparent'};

  &>span {
    width: 3px;
    height: 3px;
    border: 1px solid ${theme.color.black};
    border-radius: 100%;
  }
`;