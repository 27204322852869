// types
import { CookieNameType } from "core/types/cookies.types";

// cookies domain
// import { NODE_ENV } from 'constants';


const LOCAL_HOST = 'localhost';
const LOCAL_ALIASES = [LOCAL_HOST, '127.0.0.1'];

const getCookieDomain = (host: string) => {
  // if (NODE_ENV === 'development' && host === '192.168.0.200') {
  if (host === '192.168.0.200') {
    return host;
  }

  if (LOCAL_ALIASES.includes(host)) {
    return LOCAL_HOST;
  }

  return `.${host}`;
};


// getCookieByName
const getCookieByName = (name: CookieNameType, cookieString: string): string | undefined => {
  const value = `; ${cookieString}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length >= 2) {
    return parts.pop()?.split(';').shift();
  }
  return undefined;
};

// cookie

const PATH = '/';

const getDomain = () => getCookieDomain(window.location.hostname) || '';

export const getCookie = (name: CookieNameType) => {
  const cookie = getCookieByName(name, document.cookie);
  if (cookie) {
    const parsedCookie = JSON.parse(cookie);
    return parsedCookie.value;
  }
  return undefined;
};

export const getCookieExpTime = (name: CookieNameType) => {
  const cookie = getCookieByName(name, document.cookie);
  if (cookie) {
    const parsedCookie = JSON.parse(cookie);
    return parsedCookie.expires.toUTCString();
  }
  return undefined;
};

export const createCookie = (name: CookieNameType, value: string, expires?: number): void => {
  document.cookie = `${name}=${JSON.stringify({ value, expires, })};expires=${expires || ''};domain=${getDomain()};path=${PATH};`;
};

export const removeCookie = (name: CookieNameType) => {
  if (!getCookie(name)) return;
  document.cookie = `${name}=;path=${PATH};domain=${getDomain()};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

export const hasCookie = (name: CookieNameType) => !!getCookie(name);

const cookie = {
  getCookie,
  createCookie,
  removeCookie,
  hasCookie,
};

export default cookie;
