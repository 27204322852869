// utils
import { isNotEmptyArray, isObject, snakeify } from "./object";
// constants
import { searchQuery } from "core/constants/queryParams";

export const getSearchParam = (search: string, paramKey: string): [string, string] => {
  const paramMatch = search.match(new RegExp(`${paramKey}=[^&]+`, 'g'));

  return [paramMatch?.[0] || '', paramKey];
}

export const arrayValueToQuery = (paramValueArr: any[], paramKey: string): string => {
  const res = paramValueArr.reduce<string>((acc, paramValue, i) =>
    `${acc}${paramKey}=${paramValue}${i + 1 !== paramValueArr.length ? '&' : ''}`,
    ''
  );
  return res;
}

export const clearQueryParam = (initQuery: string, param: string) => {
  return initQuery.replace(`&${param}`, '').replace(`?${param}&`, '?').replace(`?${param}`, '');
}

const setQueryParam = (initQuery: string, paramValue: any, paramKey: string,): string => {
  if (paramValue && !Array.isArray(paramValue)) {
    return `${initQuery}&${paramKey}=${paramValue}`;
  }

  if (isNotEmptyArray(paramValue)) {
    return `${initQuery}&${arrayValueToQuery(paramValue, paramKey)}`;
  }

  return initQuery;
}

export const updateQueryParam = (initQuery: string, paramValue: any, paramKey: string,): string => {
  let query = decodeURIComponent(initQuery);

  query = clearQueryParam(query, `${paramKey}=${paramValue}`);
  query = setQueryParam(query, paramValue, paramKey);

  return query;
}

export const replaceQueryParam = (initQuery: string, oldParamValue: any, newParamValue: any, paramKey: string,): string => {
  let query = decodeURIComponent(initQuery);

  query = clearQueryParam(query, `${paramKey}=${oldParamValue}`);
  query = setQueryParam(query, newParamValue, paramKey);

  return query;
}

export const objectToQuery = (initQuery: string, obj: Record<string, any>, doSnakeify: boolean = true,): string => {
  let query = initQuery || `?`;
  const workObj = doSnakeify ? snakeify(obj) : obj;
  const keys = Object.keys(workObj);

  keys.forEach(key => {
    const value = isObject(workObj[key]) ? '' : workObj[key];
    const reg = new RegExp(`${key}=[^&]+`, 'g');
    const params = query.match(reg);

    if (params) {
      params.forEach(param => query = clearQueryParam(query, param));
    }
    query = setQueryParam(query, value, key);
  })

  if (query[0] !== '?') query = `?${query}`;

  return query ? query.replace('?&', '?') : '';
}

export const getSearchQueryValue = (query: string, idParam: boolean = false) => {
  const template = idParam ? `${searchQuery}=id:` : `${searchQuery}=`;
  return decodeURIComponent(query).match(new RegExp(`${template}[^&]+`, 'g'))?.[0].replace(template, '') || null;
}
