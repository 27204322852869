// types
import { IStatus } from 'core/types/status.types';
import { AnyFunctionType } from 'core/types/simple.types';
// styles
import * as S from './style';

interface IStatusesMenu {
  statuses: IStatus[],
  className?: string,
  onClick: AnyFunctionType,
}

const StatusesMenu = ({
  statuses,
  className,
  onClick,
}: IStatusesMenu) => {
  return (
    <S.Wrapper onClick={(e) => e.stopPropagation()} className={className}>
      {statuses.map((item, key) =>
        <S.StyledStatus key={key} status={item} onClick={onClick} />
      )}
    </S.Wrapper>
  )
}

export default StatusesMenu;