import styled from "styled-components";
// components
import OptionsInfo from "components/common/OptionsInfo";
// styles
import theme from "core/theme/theme";
import { invisibleStyle, titleText, visibleMenuStyle } from "styles/common";

export const Article = styled.div`
  display: flex;
  ${titleText};
  color: ${theme.color.silverGray};
  font-weight: 400;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &>span {
    font-weight: 700;
    color: ${theme.color.black};
  }
`;

interface StyledOptionsInfoProps {
  $left: number,
}

export const StyledOptionsInfo = styled(OptionsInfo) <StyledOptionsInfoProps>`
  ${invisibleStyle};
  position: absolute;
  top: 30px;
  left: ${({ $left }) => $left}px;
  cursor: default;
`;

export const ArticleValue = styled.div`
  position: relative;
  font-weight: 700;

  &:hover {
    &>${StyledOptionsInfo} {
      ${visibleMenuStyle};
    }
  }
`;
