import { AxiosError, type AxiosResponse } from "axios";
// utils
import { camelize, isObject } from "./object";
import { isFile } from "./base/baseUtils";

export interface IReqError {
  status: number | null,
  message: string,
  dataMessage: string,
}

export const isResponseReqError = (response: unknown): response is IReqError => {
  if (isObject(response) && 'status' in response && 'message' in response) {
    return true
  }
  return false;
}

interface ICamelizeData {
  withCamelize?: boolean,
  recursive?: boolean,
}

const requestParser =
  <R extends Promise<AxiosResponse['data']>, T extends Record<string, any> | undefined = {}>
    (request: (props: T) => Promise<AxiosResponse>, camelizeData?: ICamelizeData) => async (props: T): Promise<Awaited<R> | IReqError> => {
      try {
        const response = await request(props);

        if (isFile(response.data)) {
          return response.data as Awaited<R>;
        }

        const withCamelize = camelizeData?.withCamelize ?? true;
        const recursive = camelizeData?.recursive ?? true;

        if (!withCamelize) {
          return response.data;
        }

        return camelize(response.data, recursive);
      } catch (e) {
        if (e instanceof AxiosError) {
          return {
            status: e.status || e.response?.status || null,
            message: e.message || '',
            dataMessage: e.response?.data?.detail || '',
          }
        }

        if (e instanceof Error) {
          return {
            status: null,
            message: e.message || '',
            dataMessage: e.message || '',
          }
        }

        return {
          status: 999,
          message: 'Техническая ошибка',
          dataMessage: 'Техническая ошибка',
        };
      }
    }

export default requestParser;

export type AxiosResponseDataType<T> = Promise<AxiosResponse<T | IReqError>['data']>;