// assets
import avatar2 from 'assets/avatar2.png';
// styles
import * as S from './style';

interface IChatAvatar {
  avatar: string,
}

const ChatAvatar = ({ avatar }: IChatAvatar) => {
  return (
    <S.Avatar src={avatar2} />
  )
}

export default ChatAvatar;