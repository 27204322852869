import { useState } from "react";
import { Field, Form, Formik, } from "formik";
import * as Yup from 'yup';
// types
import { IFormikProps } from "core/types/formik.types";
import { AnyFunctionType } from "core/types/simple.types";
// utils
import { renderInput, renderCheckbox } from "../utils";
// components
import { Button } from "components/UI";
import ErrorsBlock from "components/common/ErrorsBlock";
// styles
import * as S from './style';

export interface IValues {
  login: string,
  password: string,
  rememberMe: boolean,
}

const InnerForm = ({ errors }: IFormikProps<IValues>) => {
  return (
    <S.Form as={Form}>
      <Field
        name="login"
        children={renderInput({
          placeholder: 'Логин',
          error: errors.login,
        })}
      />
      <Field
        name="password"
        children={renderInput({
          type: 'password',
          placeholder: 'Пароль',
          error: errors.password,
        })}
      />
      <Field
        name="rememberMe"
        children={renderCheckbox({
          label: 'Оставаться в системе',
        })}
      />
      <Button text="Вход" type='submit' />
    </S.Form>
  )
}

interface ILoginForm {
  errors: string[],
  onSubmit: AnyFunctionType,
}

const LoginForm = ({
  errors,
  onSubmit,
}: ILoginForm) => {
  const [validate, setValidate] = useState(false);

  const initialValues: IValues = {
    login: '',
    password: '',
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    login: Yup.string().required('Поле обязательно'),
    password: Yup.string().required('Поле обязательно'),
  });

  const submitHandler = (values: IValues) => {
    setValidate(true);
    onSubmit(values);
  }

  return (
    <>
      <ErrorsBlock errors={errors} />
      <Formik
        initialValues={initialValues}
        onSubmit={submitHandler}
        component={InnerForm}
        validationSchema={validationSchema}
        validateOnChange={validate}
        validateOnBlur={validate}
      />
    </>
  )
}

export default LoginForm;