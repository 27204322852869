// types
import { ICheckboxFilter, StatusFilterType, FilterTypes } from "core/types/filter.types";
// components
import { Checkbox } from "components/UI";
// styles
import * as S from "./style";

export interface ICheckboxFilterProps {
  statusesFilter: StatusFilterType[FilterTypes.Status] | undefined,
  checkboxItems: [ICheckboxFilter['checkboxes'], ICheckboxFilter['checkboxes']],
  setFilterValue: (value: string) => void,
}

const CheckboxFilter = ({
  statusesFilter,
  checkboxItems,
  setFilterValue,
}: ICheckboxFilterProps) => {
  const [checkedItems, unCheckedItems] = checkboxItems;

  return (
    <S.Wrapper>
      {checkedItems.map((item, key, arr) =>
        <Checkbox
          key={key}
          label={item.label}
          checked={Boolean(statusesFilter?.some(statusValue => statusValue === item.value))}
          onChange={() => setFilterValue(item.value)}
          withBottomBorder={key === arr.length - 1}
        />
      )}
      {unCheckedItems.map((item, key) =>
        <Checkbox
          key={key}
          label={item.label}
          checked={Boolean(statusesFilter?.some(statusValue => statusValue === item.value))}
          onChange={() => setFilterValue(item.value)}
        />
      )}
    </S.Wrapper>
  )
}

export default CheckboxFilter;