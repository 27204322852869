import { useEffect, useState } from "react";
import InputMask, { InputState, Selection } from 'react-input-mask';
// types
import { InputEventType } from "core/types/simple.types";
// styles
import * as S from "./style";

interface IInputTime {
  initValue: string,
  isDisabled: boolean,
  placeholder?: string,
  setFilterValue: (value: string) => void,
}

const InputTime = ({
  initValue,
  isDisabled,
  placeholder = 'чч:мм',
  setFilterValue,
}: IInputTime) => {
  const [value, setValue] = useState(initValue);

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const beforeMaskedValueChange = (newState: InputState, oldState: InputState, userInput: string): InputState => {
    let newValue = null;
    let newSelection: Selection | null = null;

    // валидация Х_:__
    if (newState.selection?.start === 0 && Number(userInput) >= 3) {
      newValue = newState.value.replace(/_[_0-9]:/g, `0${userInput}:`);
      newSelection = { start: 3, end: 3 };
    }

    // валидация _Х:__
    if (oldState.selection?.start === 1 && newState.value[0] === '2' && Number(userInput) >= 4) {
      newValue = oldState.value.replace('_:', '3:');
    }

    // валидация __:Х_
    if (oldState.selection?.start === 3 && Number(userInput) >= 6) {
      newValue = oldState.value.replace(':_', ':5');
      newSelection = { start: 4, end: 4 };
    }

    return {
      value: newValue || newState.value,
      selection: newSelection || newState.selection
    };
  }

  const onChangeHandler = (e: InputEventType) => {
    const value = e.currentTarget.value;
    setValue(value);

    if (value === '__:__') {
      setFilterValue('');
    } else if (!value.includes('_')) {
      setFilterValue(value);
    }
  }

  return (
    <S.Wrapper>
      <InputMask
        value={value}
        placeholder={placeholder}
        disabled={isDisabled}
        onChange={onChangeHandler}
        mask="29:59"
        //@ts-ignore
        formatChars={{
          '9': '[0-9]',
          '2': '[0-2]',
          '5': '[0-5]',
        }}
        beforeMaskedValueChange={beforeMaskedValueChange}
      />
      <S.Icon />
    </S.Wrapper>
  )
}

export default InputTime;