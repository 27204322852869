import { useState } from "react";
// types
import { InputEventType, InputKeyboardEvent } from "core/types/simple.types";
// utils
import { isEnterKey } from "core/utils/events/eventHandlers";
// components
import { Input } from "components/UI";

interface ISimpleInput {
  placeholder: string,
  initValue?: string,
  multipleValue?: boolean,
  setFilterValue: (value: string) => void,
}

const SimpleInput = ({
  placeholder,
  initValue,
  multipleValue,
  setFilterValue
}: ISimpleInput) => {
  const [value, setValue] = useState(multipleValue ? '' : (initValue || ''));

  const onInputChange = (e: InputEventType) => {
    setValue(e.currentTarget.value);
  }

  const keyDownHandler = (e: InputKeyboardEvent) => {
    if (isEnterKey(e)) {
      setFilterValue(e.currentTarget.value);
    }
  }

  return (
    <>
      <Input
        value={value}
        placeholder={placeholder}
        onChange={onInputChange}
        onKeyDown={keyDownHandler}
        autoFocus
      />
    </>
  )
}

export default SimpleInput;
