import { PayloadAction, createSlice } from '@reduxjs/toolkit'
// types
import { GalleryFileType, ModalsType } from './model.types';
import { IPackage } from '../packages/model.types';
import { IMessage } from '../messages/model.types';
import { EventEntryType } from '../events/model.types';
import { StatusesTypes } from '../handbook/model.types';
import { RotatePositionType } from 'core/types/advanced.types';
// constants
import { ISODateFormat } from 'core/constants/dateFormats';
// utils
import { UTCFormatDate } from 'core/utils/date';
// thunks
import { addMessageThunk, fetchPackageCommentsThunk } from '../messages/thunks';
import { fetchNotificationsThunk, fetchPackageHistoryThunk, readAllNotificationsThunk } from '../events/thunks';
import { changePackageStatusThunk, downloadPackageThunk, fetchPackageMediaThunk } from '../packages/thunks';

export interface IModalState {
  type: ModalsType | null,
  data: {
    id: number | null,
    messages: IMessage[] | null,
    history: EventEntryType[] | null,
    package: IPackage | null,
    notifications: EventEntryType[] | null,
  },
  messagesStatusAction: StatusesTypes | null,
  galleryFileKey: number | null,
  galleryFile: GalleryFileType | null,
  galleryRotations: RotatePositionType[],
  messagesShouldFetch: boolean,
  notificationsIsLoading: boolean,
  messagesModalHoverId: number | null,
}

type GalleryModalDataType = { package: IPackage, fileKey?: number, };
type MessagesModalDataType = { package: IPackage, statusAction?: StatusesTypes, };
type HistoryModalDataType = { package: IPackage };
type SetGalleryFileDataType = { galleryFile: GalleryFileType };
type SetGalleryRotationsDataType = { rotations: IModalState['galleryRotations'] };
type SetMessagesModalHoverIdDataType = { id: IModalState['messagesModalHoverId'], };

const initialState: IModalState = {
  type: null,
  data: {
    id: null,
    messages: null,
    history: null,
    package: null,
    notifications: null,
  },
  galleryFileKey: null,
  galleryFile: null,
  galleryRotations: [],
  messagesStatusAction: null,
  messagesShouldFetch: false,
  notificationsIsLoading: false,
  messagesModalHoverId: null,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openGalleryModal: {
      reducer: (state, { payload }: PayloadAction<GalleryModalDataType>) => {
        state.type = 'gallery';
        state.data.id = payload.package.uploadId;
        state.galleryFileKey = payload.fileKey || 0;
        state.data.package = payload.package;
      },
      prepare: (data: GalleryModalDataType) => {
        return { payload: data }
      },
    },
    setGalleryFile: {
      reducer: (state, { payload }: PayloadAction<SetGalleryFileDataType>) => {
        state.galleryFile = payload.galleryFile;
      },
      prepare: (data: SetGalleryFileDataType) => {
        return { payload: data }
      },
    },
    clearGalleryData: (state) => {
      state.data.package = null;
      state.galleryFileKey = null;
      state.galleryFile = null;
      state.galleryRotations = [];
    },
    setGalleryRotations: {
      reducer: (state, { payload }: PayloadAction<SetGalleryRotationsDataType>) => {
        state.galleryRotations = payload.rotations;
      },
      prepare: (data: SetGalleryRotationsDataType) => {
        return { payload: data }
      },
    },
    openMessagesModal: {
      reducer: (state, { payload }: PayloadAction<MessagesModalDataType>) => {
        state.type = 'messages';
        state.data.id = payload.package.uploadId;
        state.data.package = payload.package;
        state.messagesStatusAction = payload.statusAction || null;
      },
      prepare: (data: MessagesModalDataType) => {
        return { payload: data }
      },
    },
    openNotificationsModal: (state) => {
      state.type = 'notifications';
    },
    openHistoryModal: {
      reducer: (state, { payload }: PayloadAction<HistoryModalDataType>) => {
        state.type = 'history';
        state.data.package = payload.package;
        state.data.id = payload.package.uploadId;
      },
      prepare: (data: HistoryModalDataType) => {
        return { payload: { package: data.package } }
      },
    },
    closeModal: (state) => {
      state.type = initialState.type;
      state.data = initialState.data;
    },
    setMessagesModalHoverId: {
      reducer: (state, { payload }: PayloadAction<SetMessagesModalHoverIdDataType>) => {
        state.messagesModalHoverId = payload.id;
      },
      prepare: (data: SetMessagesModalHoverIdDataType) => {
        return { payload: data }
      },
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchPackageHistoryThunk.fulfilled, (state, { payload }) => {
      if (payload.page > 1 && state.data.history) {
        state.data.history = [...state.data.history, ...payload.data];
      } else {
        state.data.history = payload.data;
      }
    });
    builder.addCase(fetchPackageCommentsThunk.fulfilled, (state, { payload }) => {
      state.data.messages = payload;
      state.messagesShouldFetch = false;
    });
    builder.addCase(fetchNotificationsThunk.pending, (state) => {
      state.notificationsIsLoading = true;
    });
    builder.addCase(fetchNotificationsThunk.fulfilled, (state, { payload }) => {
      state.notificationsIsLoading = false;
      const UTCNotifications = payload.data.map(item => ({
        ...item,
        timestamp: UTCFormatDate(item.timestamp, ISODateFormat),
      }));

      if (payload.page > 1 && state.data.notifications) {
        state.data.notifications = [...state.data.notifications, ...UTCNotifications];
      } else {
        state.data.notifications = UTCNotifications;
      }
    });
    builder.addCase(fetchNotificationsThunk.rejected, (state) => {
      state.notificationsIsLoading = false;
    });
    builder.addCase(readAllNotificationsThunk.fulfilled, (state) => {
      state.data.notifications = state.data.notifications?.map(item => ({ ...item, isEventNew: false, })) || [];
    });
    builder.addCase(fetchPackageMediaThunk.fulfilled, (state, { payload }) => {
      if (payload.updateGalleryData) {
        state.data.package = { ...state.data.package, ...payload.data };
      }
    });
    builder.addCase(downloadPackageThunk.fulfilled, (state, { payload }) => {
      if (payload.updateGalleryData && state.data.package) {
        state.data.package = { ...state.data.package, mediasURLs: payload.data.medias, };
      }
    });
    builder.addCase(addMessageThunk.fulfilled, (state) => {
      state.messagesShouldFetch = true;
    });

    builder.addCase(changePackageStatusThunk.fulfilled, (state, { payload }) => {
      state.messagesShouldFetch = true;

      if (state.data.package) {
        state.data.package = {
          ...state.data.package,
          editingStatus: payload.status,
        }
      }
    });
  }
})

const { actions, reducer: modalReducer } = modalSlice;

export const {
  openGalleryModal,
  openMessagesModal,
  openNotificationsModal,
  openHistoryModal,
  closeModal,
  setGalleryFile,
  setGalleryRotations,
  clearGalleryData,
  setMessagesModalHoverId,
} = actions;

export default modalReducer;
