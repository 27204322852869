// types
import { IPackage, IPackageMedia } from 'core/models/packages/model.types';
import { RotatePositionType } from 'core/types/advanced.types';
// utils
import { UTCFormatDate, getIsDatesDiffer } from 'core/utils/date';
// components
import { Cell } from 'components/common';
// constants
import { dataDiffMessage } from 'core/constants/values';
// styles
import * as S from './style';

export interface IRotationData {
  rotatePosition: RotatePositionType,
  refreshImg: () => void,
  setRotatePosition: (value: RotatePositionType) => void,
}

interface IFileContent extends IRotationData {
  packageEntity: IPackage,
  file: IPackageMedia,
  className?: string,
  onPrevClick: () => void,
  onNextClick: () => void,
}

const FileContent = ({
  packageEntity,
  file,
  rotatePosition,
  className,
  refreshImg,
  onPrevClick,
  onNextClick,
  setRotatePosition,
}: IFileContent) => {
  const isDatesDiffer = getIsDatesDiffer(packageEntity.shootingDate, file.shootingDate);
  const isLocationDiffer = packageEntity.location !== file.shootingLocation;

  return (
    <S.Wrapper className={className}>
      <S.StyledFileView
        file={file}
        rotatePosition={rotatePosition}
        refreshImg={refreshImg}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        setRotatePosition={setRotatePosition}
        withNav={Number(packageEntity.medias?.length) > 1}
      />
      <S.Info>
        <S.InfoTitle>
          <S.StyledEllipsisTitle value={file.title} type='h4' />
          <p>{file.id}</p>
        </S.InfoTitle>
        <S.InfoContent>
          <Cell title='Автор/Источник' value={packageEntity.author || packageEntity.correspondent.fullName} />
          <Cell title='Контакты автора' value={packageEntity.authorContact || packageEntity.correspondent.phone} />
          <Cell
            title='Снято'
            value={UTCFormatDate(packageEntity.shootingDate)}
            colored={isDatesDiffer}
            tooltip={isDatesDiffer ? dataDiffMessage : ''}
          />
          <Cell
            title='Место'
            value={packageEntity.location}
            colored={isLocationDiffer}
            tooltip={isLocationDiffer ? dataDiffMessage : ''}
          />
          <Cell title='Устройство' value={file.device} />
          <Cell title='Описание файла' value={file.description || ''} />
          <Cell title='Описание пакета' value={packageEntity.description} />
          <Cell title='Комментарий корреспондента' value={packageEntity.comment} />
          <Cell title='Корреспондент' value={packageEntity.correspondent.fullName} />
        </S.InfoContent>
      </S.Info>
    </S.Wrapper>
  )
}

export default FileContent;