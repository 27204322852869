import { useCallback, useEffect, useRef, useState } from "react";
// types
import { IPagination } from "core/models/types/RequestProps.types";
import { NullableRefType } from "core/types/simple.types";
/// utils
import { registerScrollEvent } from "core/utils/events/documentEvents";
import { getDocumentWorkAreaHeight, getHistoryListAmount, getHistoryModalWorkAreaHeight, getNotificationsListAmount, getNotificationsModalWorkAreaHeight, getPackagesListAmount } from "core/utils/base/calcValues";
// constants
import { modalInnerBottomPadding, modalWrapperPadding } from "core/constants/values";

const getWorkAreaHeight = (type: ScrollTypes) => {
  switch (type) {
    case 'package': return getDocumentWorkAreaHeight();
    case 'history': return getHistoryModalWorkAreaHeight();
    case 'notifications': return getNotificationsModalWorkAreaHeight();
  }
}

const getListAmount = (type: ScrollTypes) => {
  switch (type) {
    case 'package': return getPackagesListAmount();
    case 'history': return getHistoryListAmount();
    case 'notifications': return getNotificationsListAmount();
  }
}

const getScrollSup = (type: ScrollTypes) => {
  switch (type) {
    case 'package': return 0;
    case 'history': return modalWrapperPadding * 2 + modalInnerBottomPadding;
    case 'notifications': return modalInnerBottomPadding;
  }
}

const getTargetScroll = (scrollTarget: UseScrollAutoFetchProps['scrollTarget']) => {
  if (!scrollTarget) return 0;
  if ('scrollTop' in scrollTarget) return scrollTarget.scrollTop;
  return scrollTarget.documentElement.scrollTop;
}

interface UseScrollAutoFetchProps {
  fetchAction: (params: IPagination) => void,
  scrollTarget?: HTMLParagraphElement | Document | null,
  listItemsCount: number,
}

type ScrollTypes = 'package' | 'history' | 'notifications';

export const useScrollAutoFetch = (type: ScrollTypes, { fetchAction, scrollTarget = document, listItemsCount, }: UseScrollAutoFetchProps): [NullableRefType, (value: number) => void] => {
  const [pageCount, setPageCount] = useState(1);
  const listRef = useRef<null | HTMLParagraphElement>(null);
  const [listAmount] = useState(getListAmount(type));

  const onDocumentScroll = useCallback(() => {
    const heightDiff = (listRef.current?.offsetHeight || 0) - getWorkAreaHeight(type);
    const documentScroll = getTargetScroll(scrollTarget) + getScrollSup(type);

    if ((heightDiff - documentScroll <= 0) && listItemsCount === pageCount * listAmount) {
      setPageCount(pageCount + 1);
    }
  }, [type, scrollTarget, listItemsCount, pageCount, listAmount]);

  useEffect(() => registerScrollEvent('scroll', onDocumentScroll, scrollTarget), [scrollTarget, onDocumentScroll]);

  useEffect(() => {
    fetchAction({ pageCount, pageSize: listAmount, })
  }, [fetchAction, listAmount, pageCount]);

  return [listRef, setPageCount];
}
