import styled, { css } from "styled-components";
// types
import { UploadTypes } from "core/models/packages/model.types";
// styles
import theme from "core/theme/theme";
import { getIconElemStyle, smallBoldText } from "styles/common";

interface CheckboxWrapperProps {
  $checked: boolean,
}

export const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  display: ${({ $checked }) => $checked ? 'block' : 'none'};
  padding: 2px 4px;
  background-color: hsla(0,0%,0%,0.3);
`;

interface WrapperProps {
  $clickable: boolean,
  $previewUrl: string,
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  overflow: hidden;
  cursor: ${({ $clickable }) => $clickable ? 'pointer' : 'default'};
  border-radius: 3px;
  background: url(${({ $previewUrl }) => $previewUrl}) no-repeat center/cover;

  &:hover {
    & ${CheckboxWrapper} {
      display: block;
    }
  }

  &>img {
    display: block;
    width: 100%;
    min-height: 100%;
    height: auto;
  }
`;

interface FilesSetProps {
  $type: UploadTypes;
}

const chooseFilesSetIcon = (type: FilesSetProps['$type']) => {
  switch (type) {
    case 'photo':
    case 'photo_package':
      return theme.icons.photo;
    case 'video':
    case 'video_package':
      return theme.icons.video;
    case 'package':
      return theme.icons.file;
    default: return '';
  }
}

const previewElemStyle = css`
  position: absolute;
  bottom: 2px;
  display: flex;
  align-items: center;
  gap: 0 4px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: hsla(0,0%,0%,0.3);
  border-radius: 1px;

  &>p {
    ${smallBoldText};
    color: ${theme.color.white};
  }
`;

export const FilesSet = styled.div`
  ${previewElemStyle};
  padding-top: 4px;
  padding-bottom: 4px;
  left: 4px;
`;

export const FilesSetIcon = styled.div<FilesSetProps>`
  ${({ $type }) => getIconElemStyle(chooseFilesSetIcon($type), 20, 'white')};
`;


export const FilesSize = styled.div`
  ${previewElemStyle};
  padding-top: 4px;
  padding-bottom: 4px;
  right: 4px;
`;

export const MarksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
  position: absolute;
  top: 2px;
  left: 4px;
`;

export const Differences = styled.div`
  width: 30px;
  height: 22px;
  padding: 3px 7px;
  background-color: ${theme.color.yellow};
  border-radius: 1px;

  ${getIconElemStyle(theme.icons.help, 16)}
`;

export const Verified = styled.p`
  padding: 3px 7px;
  background-color: ${theme.color.green};
  border-radius: 1px;
  ${smallBoldText};
  color: ${theme.color.white};
`;

export const Download = styled.button`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 28px;
  height: 24px;
  padding: 2px 4px;

  ${getIconElemStyle(theme.icons.download, 20, 'white')}
  background-color: hsla(0,0%,0%,0.3);
`;
