import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
`;
