// types
import type { AxiosResponseDataType } from "core/utils/requestParser";
import { IStatistic } from "./model.types";
// utils
import { users } from "core/utils/axiosInstance"
import requestParser from "core/utils/requestParser";

export interface FetchStatisticsReqProps {
  query: string,
  userId: number,
}

export const fetchStatisticsReq = requestParser(({ userId, query }: FetchStatisticsReqProps) =>
  users.get(`/${userId}/statistics/${query || ''}`)) as
  (props: FetchStatisticsReqProps) => AxiosResponseDataType<IStatistic>;