import { useEffect, useState } from "react";
import { fetchEventSource } from '@microsoft/fetch-event-source';
// utils
import { getCookie } from "core/utils/cookies/cookie";

interface UseSSEProps {
  path: string,
  applyEmptyString?: boolean,
  userPermission: boolean,
  setMessageState?: (message: string | null) => void,
}

export const useSSE = ({ path, applyEmptyString = false, userPermission, setMessageState, }: UseSSEProps) => {
  const [message, setMessage] = useState<string | null>(null);
  const token = getCookie('token');

  useEffect(() => {
    if (userPermission) {
      const fetchData = async () => {
        await fetchEventSource(`${process.env.REACT_APP_API_URL}/${path}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          onmessage(ev) {
            if (applyEmptyString || ev.data !== '') {
              setMessage(ev.data);
            }
          },
          onerror() {
            console.log('stream error');
          },
        });
      };

      fetchData();
    }
  }, [applyEmptyString, path, token]);

  useEffect(() => {
    if (setMessageState) {
      setMessageState(message);
    }
  }, [message, setMessageState]);

  return message;
};
