export enum filterQueryParams {
  Author = 'fullName',
  Location = 'location',
  Content = 'contentTypeId',
  Status = 'editingStatusId',
  StartDate = 'startDate',
  FinishDate = 'finishDate',
  UserId = 'userId',
}

export const searchQuery = 'param';
