import { useSelector } from "react-redux"
// types
import { IModalState } from "core/models/modal/slice";
import { IAuthState } from "core/models/auth/slice";
import { IState } from "./state.type";
import { IStatisticsState } from "core/models/statistics/slice";
import { IErrorState } from "core/models/error/slice";
import { IPackagesState } from "core/models/packages/slice";
import { IHandbookState } from "core/models/handbook/slice";
import { IUsersState } from "core/models/users/slice";

const getSelectorFunc = <K extends keyof IState, T extends IState[K], P extends keyof T>(model: K, prop?: P) => {
  let selectorFunc: (state: IState) => T | T[P];

  if (prop) {
    selectorFunc = (state: IState) => (state[model] as T)[prop];
  } else {
    selectorFunc = (state: IState) => state[model] as T;
  }

  return selectorFunc;
}

type IModelCondType<T, K> = T extends keyof K ? K[T] : K;

export const useModalSelector = <T extends keyof IModalState | undefined>(prop: T): IModelCondType<T, IModalState> => {
  const selectorFunc = getSelectorFunc('modal', prop);
  const state = useSelector(selectorFunc);
  return state as IModelCondType<T, IModalState>;
}

export const usePackagesSelector = <T extends keyof IPackagesState | undefined>(prop: T): IModelCondType<T, IPackagesState> => {
  const selectorFunc = getSelectorFunc('downloads', prop);
  const state = useSelector(selectorFunc);
  return state as IModelCondType<T, IPackagesState>;
}

export const useAuthSelector = <T extends keyof IAuthState | undefined>(prop: T): IModelCondType<T, IAuthState> => {
  const selectorFunc = getSelectorFunc('auth', prop);
  const state = useSelector(selectorFunc);
  return state as IModelCondType<T, IAuthState>;
}

export const useStatisticsSelector = <T extends keyof IStatisticsState | undefined>(prop?: T): IModelCondType<T, IStatisticsState> => {
  const selectorFunc = getSelectorFunc('statistics', prop);
  const state = useSelector(selectorFunc);
  return state as IModelCondType<T, IStatisticsState>;
}

export const useErrorSelector = <T extends keyof IErrorState | undefined>(prop?: T): IModelCondType<T, IErrorState> => {
  const selectorFunc = getSelectorFunc('error', prop);
  const state = useSelector(selectorFunc);
  return state as IModelCondType<T, IErrorState>;
}

export const useHandbookSelector = <T extends keyof IHandbookState | undefined>(prop: T): IModelCondType<T, IHandbookState> => {
  const selectorFunc = getSelectorFunc('handbook', prop);
  const state = useSelector(selectorFunc);
  return state as IModelCondType<T, IHandbookState>;
}

export const useUsersSelector = <T extends keyof IUsersState | undefined>(prop: T): IModelCondType<T, IUsersState> => {
  const selectorFunc = getSelectorFunc('users', prop);
  const state = useSelector(selectorFunc);
  return state as IModelCondType<T, IUsersState>;
}
