import {
  filtersLineHeight,
  headerHeight,
  packageItemAvarageHeight,
  historyItemAvarageHeight,
  modalWrapperPadding,
  modalInnerBottomPadding,
  notificationItemAvarageHeight,
  notificationsModalInnerTopPadding,
  notificationsModalInnerBottomPadding,
} from "core/constants/values";

export const getDocumentWorkAreaHeight = () => {
  return window.innerHeight - headerHeight - filtersLineHeight;
}

export const getHistoryModalWorkAreaHeight = () => {
  const listHeaderHeight = 45;
  const listFooterHeight = 51;
  const modalHeaderHeight = 53;
  return window.innerHeight - modalWrapperPadding * 2 - modalInnerBottomPadding - modalHeaderHeight - listHeaderHeight - historyItemAvarageHeight - listFooterHeight;
}

export const getNotificationsModalWorkAreaHeight = () => {
  const modalHeaderHeight = 61;
  return window.innerHeight - modalInnerBottomPadding - modalHeaderHeight - notificationsModalInnerTopPadding - notificationsModalInnerBottomPadding;
}

export const getPackagesListAmount = () => {
  const listAreaHeight = getDocumentWorkAreaHeight();
  return Math.ceil(listAreaHeight / packageItemAvarageHeight);
}

export const getHistoryListAmount = () => {
  const listAreaHeight = getHistoryModalWorkAreaHeight();
  return Math.ceil(listAreaHeight / historyItemAvarageHeight);
}

export const getNotificationsListAmount = () => {
  const listAreaHeight = getNotificationsModalWorkAreaHeight();
  return Math.ceil(listAreaHeight / notificationItemAvarageHeight);
}

const downgradeFileSize = (size: number, grade: number) => {
  return size / Math.pow(1024, grade);
}

export const bytesToMbytes = (bytes: number) => {
  return downgradeFileSize(bytes, 2).toFixed(2);
}

export const bytesToGbytes = (bytes: number) => {
  return downgradeFileSize(bytes, 3).toFixed(2);
}

export const getElemSizesWithoutPaddings = (element: HTMLElement | null): [number, number] => {
  if (!element) return [0, 0];

  const computedStyle = getComputedStyle(element);

  const elementWidth = element.clientWidth;   // width with padding
  const elementHeight = element.clientHeight;  // height with padding

  return [
    elementWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight),
    elementHeight - parseFloat(computedStyle.paddingTop) - parseFloat(computedStyle.paddingBottom),
  ]
}
