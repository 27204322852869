// types
import type { IOptionsInfo } from "components/common/OptionsInfo";
import { INotificationsSetting } from "core/models/events/model.types";
import { IShortUser, IUser } from "core/models/users/model.types";

export const getOptionsInfo = (user: Omit<IShortUser, 'id'>): IOptionsInfo['options'] => {
  return [
    { title: 'Должность', value: user.position },
    { title: 'Подразделение', value: user.department },
    { title: 'Email', value: user.email },
    { title: 'Телефон', value: user.phone }
  ]
}

export const getNotificationsSettings = (user: IUser): INotificationsSetting[] => {
  return [
    { id: 1, value: user.enableMailNotification, text: 'Уведомления по почте', type: 'email' },
    { id: 2, value: user.enablePushNotification, text: 'Пуш-уведомления в браузере', type: 'push' }
  ];
}
