import { useCallback } from "react";
// types
import { EventEntryType } from "core/models/events/model.types";
import { IPagination } from "core/models/types/RequestProps.types";
import { NullableRefType } from "core/types/simple.types";
// hooks
import { useAppDispatch } from "core/store";
import { useModalSelector } from "core/store/selectorHooks";
import { useScrollAutoFetch } from "core/hooks/useScrollAutoFetch";
// thunks
import { fetchPackageHistoryThunk } from "core/models/events/thunks";
// actions
import { closeModal } from "core/models/modal/slice";
// hocs
import { withModalFetch } from "core/hocs";
// components
import { Tabs } from "components/common";
import { Button } from "components/UI";
// styles
import * as S from './style';

interface IHistoryModal {
  data: EventEntryType[] | null,
  modalRef: NullableRefType,
}

const HistoryModal = ({
  data,
  modalRef,
}: IHistoryModal) => {
  const { id } = useModalSelector('data');
  const dispatch = useAppDispatch();

  const fetchHistory = useCallback(({ pageSize, pageCount }: IPagination) => {
    if (id) {
      dispatch(fetchPackageHistoryThunk({ data: { id, pageSize, pageCount, } }));
    }
  }, [dispatch, id]);

  const [listRef] = useScrollAutoFetch('history', { fetchAction: fetchHistory, scrollTarget: modalRef.current, listItemsCount: data?.length || 0 });

  const onClose = () => {
    dispatch(closeModal());
  }

  return (
    <S.Wrapper ref={modalRef}>
      <S.TabsWrapper>
        <Tabs tabs={[{ title: 'Все редакции', value: 1 }]} tab={1} setTab={() => { }} smallPaddings />
      </S.TabsWrapper>
      <div ref={listRef}>
        <S.StyledHistoryList history={data} />
      </div>
      <S.Footer>
        <Button text='Закрыть' onClick={onClose} transparent />
      </S.Footer>
    </S.Wrapper>
  )
}

export default withModalFetch(HistoryModal, 'history', { withFetch: false, });
