import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { TooltipPointerStyle, smallBoldText, smallText } from "styles/common";

export const Wrapper = styled.div`
  width: 261px;
  padding: 4px 12px;
  background-color: ${theme.color.black};
  z-index: ${theme.zIndex.menu};

  &::after {
    ${TooltipPointerStyle};
    top: -5px;
  }
`;

export const Item = styled.div`
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.color.silverGray};
  }

  &>p {
    &:first-child {
      margin-bottom: 2px;
      ${smallText};
      color: ${theme.color.nobelGray};
    }

    &:last-child {
      ${smallBoldText};
      color: ${theme.color.white};
    }
  }
`;