import styled, { css } from "styled-components";
// styles
import theme from "core/theme/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 15px;
  position: relative;
  cursor: pointer;
`;

const lineStyle = css`
  position: absolute;
  top: 5px;
  bottom: 7px;
  left: 0;
  background-color: ${theme.color.white};
`;

export const MainLine = styled.div`
  ${lineStyle};
  width: 100%;
  opacity: 0.2;
`;

export const BufferLine = styled.div`
  ${lineStyle};
  opacity: 0.3;
`;

export const ProgressLine = styled.div`
  ${lineStyle};
`;