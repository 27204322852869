// types
import { ITagFilter } from 'core/types/filter.types';
// components
import FilterTag from 'components/common/FilterTag';
// styles
import * as S from './style';

interface ITagsList {
  tags: ITagFilter[],
  className?: string,
  onCloseTag: (filter: ITagFilter) => void,
}

const TagsList = ({
  tags,
  className,
  onCloseTag,
}: ITagsList) => {
  return (
    <S.Wrapper className={className}>
      {tags.map((item, key) =>
        <FilterTag key={key} tag={item} onCloseTag={onCloseTag} />
      )}
    </S.Wrapper>
  )
}

export default TagsList;