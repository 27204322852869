import styled, { css } from "styled-components";
// types
import { ErrorsBlockTypes } from ".";
// styles
import theme from "core/theme/theme";

const formErrorsMargins = css`
  margin-bottom: 10px;
`;

const inputErrorMargins = css`
  margin-top: 4px;
  margin-left: 8px;
`;

const getMargins = (type: ErrorsBlockTypes) => {
  switch (type) {
    case 'formErrors': return formErrorsMargins;
    case 'fieldError': return inputErrorMargins;
  }
}
interface ErrorsProps {
  $type: ErrorsBlockTypes,
  $right: boolean,
}

export const Errors = styled.div<ErrorsProps>`
  ${({ $type }) => getMargins($type)};
  text-align: ${({ $right }) => $right ? 'right' : 'left'};

  &>p {
    color: ${theme.color.red};
  }
`;
