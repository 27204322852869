import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
// style
import theme from "core/theme/theme";

interface TabProps {
  readonly $isActive: boolean;
  readonly $smallPaddings: boolean;
}

export const Tabs = styled.nav`
  display: flex;
  align-items: center;
  gap: 0 24px;
`;

const activeTabCss = css`
  color: ${theme.color.blue};
  font-weight: 700;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${theme.color.blue};
  }
`;

const tabCss = css<TabProps>`
  color: ${theme.color.silverGray};
  font-size: 14px;
  line-height: 20px;
  padding-bottom: ${({ $smallPaddings }) => $smallPaddings ? '12px' : '20px'};
  ${({ $isActive }) => $isActive ? activeTabCss : ''};
`

export const HrefTab = styled(Link) <TabProps>`
  ${tabCss};
`;

export const ButtonTab = styled.button<TabProps>`
  ${tabCss};
`;