import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// types
import { IPackage } from 'core/models/packages/model.types';
// actions
import { fetchPackageMediaThunk } from 'core/models/packages/thunks';
import { openGalleryModal } from 'core/models/modal/slice';
// utils
import { getSearchQueryValue } from 'core/utils/urlQuery';
// hooks
import { useAppDispatch } from 'core/store';
import { useElemSize } from 'core/hooks/useElemSize';
// components
import FileCard from 'components/cards/FileCard';
// styles
import * as S from './style';

interface IPackageCard {
  packageEntity: IPackage,
  packageId: number,
  onDownload: (archive: boolean) => void,
}

const PackageCard = ({
  packageEntity,
  packageId,
  onDownload,
}: IPackageCard) => {
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const [controlsRef, controlsWidth] = useElemSize();
  const packageMedia = packageEntity.medias;
  const location = useLocation();
  const searchId = getSearchQueryValue(location.search, true);

  useEffect(() => {
    // TODO: неправильная проверка, нужна будет проверка на null
    if (!packageMedia?.length && !searchId) {
      dispatch(fetchPackageMediaThunk({ data: { id: packageId }, actionData: {} }));
    }
  }, [packageId, packageMedia, dispatch, searchId]);

  const onFileChange = (id: number) => {
    const currentFile = checkedItems.findIndex(item => item === id);

    if (currentFile === -1) {
      setCheckedItems([
        ...checkedItems,
        id,
      ]);
    } else {
      setCheckedItems([
        ...checkedItems.slice(0, currentFile),
        ...checkedItems.slice(currentFile + 1),
      ]);
    }
  }

  const onControlsCancel = () => {
    setCheckedItems([]);
  }

  const openGallery = (fileKey: number) => {
    dispatch(openGalleryModal({ package: packageEntity, fileKey, }));
  }

  return (
    <>
      {packageMedia &&
        <S.Wrapper>
          {packageMedia.map((item, key) =>
            <FileCard
              packageEntity={packageEntity}
              key={key}
              file={item}
              lastRowItemsCount={(packageMedia.length % 4) || 4}
              checkedItems={checkedItems}
              openGallery={() => openGallery(key)}
              checkFile={onFileChange}
              onDownload={onDownload}
            />
          )}
        </S.Wrapper>
      }
      <S.StyledDownloadControls
        checkedItems={checkedItems}
        cancel={onControlsCancel}
        controlsRef={controlsRef}
        $left={controlsWidth / 2}
        onDownload={onDownload}
      />
    </>
  )
}

export default PackageCard;