import styled, { css } from "styled-components";
// components
import OptionsInfo from 'components/common/OptionsInfo'
// styles
import theme from "core/theme/theme";
import { ellipsisStyles, invisibleStyle, smallText, visibleMenuStyle } from "styles/common";

export const Wrapper = styled.div`
  &>p {
    ${smallText};
    margin-bottom: 2px;
    color: ${theme.color.nobelGray};
  }
`;

interface StyledOptionsInfoProps {
  $left: number,
}

export const StyledOptionsInfo = styled(OptionsInfo) <StyledOptionsInfoProps>`
  ${invisibleStyle};
  position: absolute;
  top: 25px;
  left: ${({ $left }) => `${$left}px`};
  cursor: default;
`;

interface ValueProps {
  $ellipsis: boolean,
}

export const Value = styled.div<ValueProps>`
  max-width: 100%;
  display: inline-block;
  position: relative;

  &>p {
    ${({ $ellipsis }) => $ellipsis ? ellipsisStyles : ''};
  }
`;

export const Colored = styled.span`
  background-color: ${theme.color.libertyBlue};
`;

interface InnerProps {
  $colored: boolean,
  $withHover: boolean,
}

const valueHoverStyle = css`
  cursor: pointer;

  &:hover {
    color: ${theme.color.blue};

    &>${StyledOptionsInfo} {
      ${visibleMenuStyle};
    }
  }

  // for better hover experience
  &>${Value} {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 150%;
      cursor: pointer;
    }
  }
`;

export const Inner = styled.div<InnerProps>`
  display: flex;
  align-items: center;
  gap: 0 4px;
  ${smallText};
  width: auto;
  margin-bottom: 2px;
  position: relative;

  ${({ $withHover }) => $withHover ? valueHoverStyle : ''};
  
  &>${Value} {
    background-color: ${({ $colored }) => $colored ? theme.color.whiteYellow : 'transparent'};
  }
`;
