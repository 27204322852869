import styled from "styled-components";
// components
import { Select } from "components/common";
// styles
import { getIconElemStyle, iconInputIconStyle, iconInputWrapperStyle } from "styles/common";
import theme from "core/theme/theme";

export const Wrapper = styled.div`
  position: relative;
  gap: 0 8px;
  min-width: 90px;
  max-width: 90px;
  ${iconInputWrapperStyle};

  &>input {
    width: 100%;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const Icon = styled.div`
  ${iconInputIconStyle};
  ${getIconElemStyle(theme.icons.time, 20)}
`;

export const StyledSelect = styled(Select)`
  position: absolute;
  top: 100%;
  left: 0;
  width: 130px;
  max-height: 200px;
`;
