import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// types
import { IPackage } from 'core/models/packages/model.types';
import { PreviewCellType } from 'core/types/previewCell.types';
import { IUser } from 'core/models/users/model.types';
import { ClickEventType } from 'core/types/simple.types';
import { FilterTypesSnakiefied } from 'core/types/filter.types';
import { FilterChangeFuncType } from 'pages/Packages';
// actions
import { openGalleryModal } from 'core/models/modal/slice';
import { downloadPackageThunk } from 'core/models/packages/thunks';
// hooks
import { useAppDispatch } from 'core/store';
import { usePackagesSelector } from 'core/store/selectorHooks';
// utils
import { getFullName } from 'core/utils/getFullName';
import { UTCFormatDate } from 'core/utils/date';
import { downloadFilesHandler } from 'core/models/packages/utils';
import { getOptionsInfo } from 'core/config/userData';
import { getSearchQueryValue } from 'core/utils/urlQuery';
// components
import { Cell } from 'components/common';
import PackageCard from '../PackageCard';
import OptionsCell from '../OptionsCell';
import StatusCell from '../StatusCell';
// styles
import * as S from './style';

export interface IOptionCell {
  user: IUser,
  packageEntity: IPackage,
  optionsOpenned: boolean,
  setOptionsOpenned: (value: boolean) => void;
}

const choosePreviewCellType = (option: IPackage): PreviewCellType => {
  // if (option.isInformationTrue && option.verified) return 'diffAndVerif';
  if (!option.isShootingLocationActual || !option.isShootingDateActual) return 'differences';
  // if (option.verified) return 'verified';
  return 'simple';
}

export type DownloadMediaType = (archive: boolean, mediaIds?: number[]) => void;

interface IPackageItem {
  packageEntity: IPackage,
  cardOpenned: boolean,
  onFilterChange: FilterChangeFuncType,
  setOpennedCardId: (value: number | null) => void,
}

export const PackageItem = ({
  packageEntity,
  cardOpenned,
  onFilterChange,
  setOpennedCardId,
}: IPackageItem) => {
  const [optionsOpenned, setOptionsOpenned] = useState(false);
  const [statusOptionsOpenned, setStatusOptionsOpenned] = useState(false);
  const [downloadMediaIds, setDownloadMediaIds] = useState<number[]>([]);
  const { isLoading } = usePackagesSelector(undefined);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParam = isLoading ? '' : getSearchQueryValue(location.search);

  const [downloadIndicator, setDownloadIndicator] = useState<'archive' | 'files' | null>(null);

  const documentClickHandler = () => {
    setOptionsOpenned(false);
    setStatusOptionsOpenned(false);
  }

  const authorCellClickHandler = (e: ClickEventType) => {
    e.stopPropagation();
    onFilterChange(packageEntity.correspondent.fullName, FilterTypesSnakiefied.Author);
  }

  useEffect(() => {
    document.addEventListener('click', documentClickHandler);

    return () => document.removeEventListener('click', documentClickHandler);
  }, []);

  const openCardHandler = () => {
    if (cardOpenned) {
      setOpennedCardId(null);
    } else {
      setOpennedCardId(packageEntity.uploadId);
    }
  }

  const previewClickHandler = async () => {
    if (cardOpenned) {
      setOpennedCardId(null);
    }
    else {
      dispatch(openGalleryModal({ package: packageEntity, }));
    }
  }

  const downloadMedia: DownloadMediaType = async (archive: boolean, mediaIds: number[] = []) => {
    if (!packageEntity.mediasURLs) {
      await dispatch(downloadPackageThunk({ data: { id: packageEntity.uploadId, }, actionData: {} }));
    }

    setDownloadMediaIds(mediaIds);
    setDownloadIndicator(archive ? 'archive' : 'files');
  }

  useEffect(() => {
    const mediasURLs = packageEntity.mediasURLs;

    if ((downloadIndicator === 'archive' || downloadIndicator === 'files') && mediasURLs) {
      const selectedMediasURLs = downloadMediaIds.length ? mediasURLs.filter(item => downloadMediaIds?.some(id => id === item.id)) : mediasURLs;
      downloadFilesHandler({
        packageId: packageEntity.uploadId,
        medias: selectedMediasURLs,
        archive: downloadIndicator === 'archive' ? true : false,
        dispatch,
      });
      setDownloadIndicator(null);
    }
  }, [dispatch, downloadIndicator, downloadMediaIds, packageEntity.mediasURLs, packageEntity.uploadId]);

  return (
    <S.Wrapper $border={cardOpenned}>
      <S.Inner onClick={openCardHandler} $border={cardOpenned}>
        <S.StyledPreviewCell
          uploadId={packageEntity.uploadId}
          size={packageEntity.size}
          mediaCount={packageEntity.mediaCount}
          uploadType={packageEntity.uploadType}
          previewUrl={packageEntity.previewUrl || ''}
          type={choosePreviewCellType(packageEntity)}
          onClick={previewClickHandler}
        />
        <S.Group>
          <Cell
            title='Описание пакета'
            value={packageEntity.description}
            selectedPart={searchParam || ''}
            required
          />
          <Cell
            title='Комментарий корреспондента'
            value={packageEntity.comment || ''}
            selectedPart={searchParam || ''}
          />
        </S.Group>
        <S.Group>
          <Cell
            title='Получено'
            value={UTCFormatDate(packageEntity.uploadDate)}
          />
          <Cell
            title='Место'
            value={packageEntity.location}
            tooltip={!packageEntity.isShootingLocationActual ? 'Данные отличаются' : ''}
            colored={!packageEntity.isShootingLocationActual}
            required
          />
        </S.Group>
        <S.Group>
          <Cell
            title='Корреспондент/Автор пакета'
            value={getFullName(packageEntity.correspondent)}
            hoverOptions={getOptionsInfo(packageEntity.correspondent)}
            onClick={authorCellClickHandler}
          />
          {packageEntity.author &&
            <Cell
              title='Другой автор'
              value={packageEntity.author}
              tooltip='Корреспондент, отправивший фотографию, не является автором фотографии'
              selectedPart={searchParam || ''}
            />
          }
          {packageEntity.authorContact &&
            <Cell
              title='Контакты автора'
              value={packageEntity.authorContact}
              selectedPart={searchParam || ''}
            />
          }
          {packageEntity.alias &&
            <Cell
              title='Псевдоним'
              value={packageEntity.alias}
              tooltip='Автор просит использовать псевдоним для подписи контента'
              selectedPart={searchParam || ''}
            />
          }
          {packageEntity.lastMessageAuthor && packageEntity.lastMessageDate &&
            <Cell
              title='Последний ответ от'
              value={`${packageEntity.lastMessageAuthor}\n${UTCFormatDate(packageEntity.lastMessageDate)}`}
            />
          }
        </S.Group>
        <S.Group>
          <StatusCell
            packageEntity={packageEntity}
            optionsOpenned={statusOptionsOpenned}
            setOptionsOpenned={setStatusOptionsOpenned}
          />
          {packageEntity.editingStatusAuthor &&
            <Cell
              title='Автор статуса'
              value={packageEntity.editingStatusAuthor}
            />
          }
          <Cell
            title='ID'
            value={`${packageEntity.uploadId}`}
          />
        </S.Group>
        <OptionsCell
          packageEntity={packageEntity}
          optionsOpenned={optionsOpenned}
          onDownload={downloadMedia}
          setOptionsOpenned={setOptionsOpenned}
        />
      </S.Inner>
      {cardOpenned &&
        <PackageCard
          packageEntity={packageEntity}
          packageId={packageEntity.uploadId}
          onDownload={downloadMedia}
        />
      }
    </S.Wrapper>
  )
}

export default PackageItem;