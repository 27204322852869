import { PayloadAction, createSlice } from '@reduxjs/toolkit'
// types
import { ErrorDataType, IErrorData } from './model.types';
// utils
import { removeCookie } from 'core/utils/cookies/cookie';
import history from 'core/utils/history';
// thunks
import { logoutThunk } from '../auth/thunks';
// data
import { routesPublic } from 'core/constants/routes';
import {
  notFoundErrorData,
  serverErrorData,
  maintenanceErrorData,
  otherErrorData,
  noPermErrorData,
} from 'core/config/errorsData';

export interface IErrorState {
  isLoading: boolean,
  data: IErrorData | null,
}

type setDataPayloadType = {
  type: ErrorDataType | null,
};

const initialState: IErrorState = {
  isLoading: false,
  data: null,
}

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setErrorData: {
      reducer: (state, { payload }: PayloadAction<setDataPayloadType>) => {
        switch (payload.type) {
          case 'noPermError': state.data = noPermErrorData; break;
          case 'maintenanceError': state.data = maintenanceErrorData; break;
          case 'notFoundError': state.data = notFoundErrorData; break;
          case 'otherError': state.data = otherErrorData; break;
          case 'serverError': state.data = serverErrorData; break;
          default: state.data = null;
        }
      },
      prepare: (data: setDataPayloadType) => {
        return { payload: { type: data.type } }
      },
    }
  },

  extraReducers: (builder) => {
    builder.addCase(logoutThunk.rejected, (state, { payload }) => {
      removeCookie('token');
      history.push(routesPublic.AUTH);
    });
  },
})

const { actions, reducer: errorReducer } = errorSlice;

export const { setErrorData } = actions;

export default errorReducer;
