import styled, { css } from "styled-components";
// components
import HistoryList from "components/lists/HistoryList";
// styles
import { solidBorderProperty } from "styles/common";

const marginStyle = css`
  margin-left: 20px;
  margin-right: 16px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const TabsWrapper = styled.div`
  padding-top: 12px;
  border-bottom: ${solidBorderProperty};
  ${marginStyle};
`;

export const StyledHistoryList = styled(HistoryList)`
  ${marginStyle};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px 0 20px;
  border-top: ${solidBorderProperty};
`;