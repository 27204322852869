import { useRef, useState } from 'react';
// types
import { IMessage } from 'core/models/messages/model.types';
import { WithUserCheckType } from 'core/hocs/withUserCheck';
import { ChangePackageStatusReqProps } from 'core/models/packages/packages.api';
import { IMessagesUser, } from 'core/models/users/model.types';
import { NullableRefType } from 'core/types/simple.types';
// thunks
import { addMessageThunk } from 'core/models/messages/thunks';
import { changePackageStatusThunk } from 'core/models/packages/thunks';
// hooks
import { useAppDispatch } from 'core/store';
import { useModalSelector } from 'core/store/selectorHooks';
// hocs
import { withModalFetch, withUserCheck } from 'core/hocs';
// components
import ChatForm, { type IValues as IChatFormValues } from 'components/forms/ChatForm';
import MessagesList from 'components/lists/MessagesList';
import ChatAvatar from 'components/common/ChatAvatar';
// styles
import * as S from './style';
import { closeModal } from 'core/models/modal/slice';
import ContentEditable from 'react-contenteditable';

interface IMessagesModal extends WithUserCheckType {
  data: IMessage[],
  id: number,
  modalRef: NullableRefType,
}

export type ReplyToMessageType = ({ answerMessageId, answeredUser }: { answerMessageId: number, answeredUser: IMessagesUser }) => void;

const MessagesModal = ({
  user,
  data: messages,
  id,
  modalRef,
}: IMessagesModal) => {
  const dispatch = useAppDispatch();
  const [answerMessageId, setAnswerMessageId] = useState<number | null>(null);
  const [answeredUser, setAnsweredUser] = useState<IMessagesUser | null>(null);
  const chatFieldRef = useRef<ContentEditable>(null);
  const { messagesStatusAction, data } = useModalSelector(undefined);
  const { package: packageEntity } = data;

  const onChatFormSubmit = ({ statusValue, message }: IChatFormValues) => {
    if (statusValue) {
      const thunkData: ChangePackageStatusReqProps = { id, status: statusValue };
      if (answerMessageId) thunkData.answerMessageId = answerMessageId;
      if (message) thunkData.comment = message;
      else dispatch(closeModal());

      dispatch(changePackageStatusThunk({ data: thunkData, actionData: {} }));
    } else {
      dispatch(addMessageThunk({ data: { id, message, answerMessageId: answerMessageId, }, actionData: {} }));
    }
  }

  const replyToMessage: ReplyToMessageType = ({ answerMessageId, answeredUser, }) => {
    setAnswerMessageId(answerMessageId);
    setAnsweredUser(answeredUser);
    modalRef.current?.scrollTo({ top: 0 });
    if (chatFieldRef.current?.el.current.focus) {
      chatFieldRef.current?.el.current.focus()
    }
  }

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <ChatAvatar avatar={user.avatar} />
        <ChatForm
          initStatusAction={messagesStatusAction}
          answeredUser={answeredUser}
          packageEntity={packageEntity}
          chatFieldRef={chatFieldRef}
          onSubmit={onChatFormSubmit}
          setAnsweredUser={setAnsweredUser}
          setAnswerMessageId={setAnswerMessageId}
        />
      </S.FormWrapper>
      <MessagesList messages={messages} replyToMessage={replyToMessage} modalRef={modalRef} />
    </S.Wrapper>
  )
}

export default withModalFetch(withUserCheck(MessagesModal), 'messages');