// types
import {
  ILocationFilter,
  IAuthorFilter,
  IStatusFilter,
  IContentFilter,
  IPeriodFilter,
  FilterTypes,
  PackagesFiltersType,
  StatisticsFiltersType,
  FilterTypesSnakiefied,
  IInputFilterParams,
} from 'core/types/filter.types';
import { EmptyObject, IOption } from 'core/types/simple.types';
import { StatusesTypes, IHandbook, MediaTypes } from 'core/models/handbook/model.types';
// utils
import { formatDate } from 'core/utils/date';
import { objectToQuery } from 'core/utils/urlQuery';
import { objectEntries } from 'core/utils/object';
// constants
import { ISODateFormat, filtersAPIInputDateFormat, filtersAPIOutputDateFormat, shortUserTimeFormat } from 'core/constants/dateFormats';

const getLocationtFilter = (params: IInputFilterParams | EmptyObject = {}): ILocationFilter => ({
  type: FilterTypes.Location,
  title: 'Местоположение',
  label: 'Место съемки',
  placeholder: 'Введите место съемки',
  ...params,
});

const getCorrespondentFilter = (params: IInputFilterParams | EmptyObject = {}): IAuthorFilter => ({
  type: FilterTypes.Author,
  title: 'Корреспондент',
  label: 'ФИО корреспондента',
  placeholder: 'Введите ФИО',
  ...params,
});

const getStatusFilter = (statusesTypes: IHandbook['editingStatusType']): IStatusFilter => ({
  type: FilterTypes.Status,
  title: 'Статус',
  checkboxes: objectEntries<string, StatusesTypes>(statusesTypes).map((status) => {
    return { label: status[1], value: status[0], }
  }),
});

const getContentFilter = (mediaTypes: IHandbook['mediaType']): IContentFilter => ({
  type: FilterTypes.Content,
  title: 'Тип контента',
  checkboxes: objectEntries<string, MediaTypes>(mediaTypes).map((media) => (
    { label: media[1], value: media[0] }
  )),
})

const getPeriodFilter = (withTime: boolean = true): IPeriodFilter => ({
  type: FilterTypes.Period,
  title: 'Период',
  withTime,
});

export const getDownloadsFilters = (handbook: IHandbook) => [
  getPeriodFilter(),
  getLocationtFilter({ multipleValue: true }),
  getCorrespondentFilter({ multipleValue: true }),
  getStatusFilter(handbook.editingStatusType),
  getContentFilter(handbook.mediaType),
];

interface IGetStatisticsFiltersParams {
  authorOptions: IOption[],
  authorOptionsIsLoading: boolean,
  fetchAuthorOptions: (value: string) => void,
}

export const getStatisticsFilters = ({ authorOptions, authorOptionsIsLoading: optionsIsLoading, fetchAuthorOptions, }: IGetStatisticsFiltersParams) => [
  getPeriodFilter(false),
  getCorrespondentFilter({ options: authorOptions, optionsIsLoading, fetchOptions: fetchAuthorOptions, }),
];

export const getSearchStartDateValue = (search: string): string => {
  return search.match(new RegExp(`${FilterTypesSnakiefied.StartDate}=[^&]+`, 'g'))
    ?.[0].replace(`${FilterTypesSnakiefied.StartDate}=`, '').replace('%20', ' ') || '';
};

export const getSearchEndDateValue = (search: string): string => {
  return search.match(new RegExp(`${FilterTypesSnakiefied.EndDate}=[^&]+`, 'g'))
    ?.[0].replace(`${FilterTypesSnakiefied.EndDate}=`, '').replace('%20', ' ') || '';
};

export const getSearchPackagesFilters = (search: string): PackagesFiltersType => {
  const author = search.match(new RegExp(`${FilterTypesSnakiefied.Author}=[^&]+`, 'g'))
    ?.map(match => match.replace(`${FilterTypesSnakiefied.Author}=`, '')) || [];

  const location = search.match(new RegExp(`${FilterTypesSnakiefied.Location}=[^&]+`, 'g'))
    ?.map(match => match.replace(`${FilterTypesSnakiefied.Location}=`, '')) || [];

  const status = search.match(new RegExp(`${FilterTypesSnakiefied.Status}=[^&]+`, 'g'))
    ?.map(match => match.replace(`${FilterTypesSnakiefied.Status}=`, '')) || [];

  const content = search.match(new RegExp(`${FilterTypesSnakiefied.Content}=[^&]+`, 'g'))
    ?.map(match => match.replace(`${FilterTypesSnakiefied.Content}=`, '')) || [];

  return {
    [FilterTypes.Author]: author,
    [FilterTypes.Location]: location,
    [FilterTypes.Status]: status,
    [FilterTypes.Content]: content,
    [FilterTypes.StartDate]: getSearchStartDateValue(search),
    [FilterTypes.EndDate]: getSearchEndDateValue(search),
  };
}

export const getUTCSearch = (search: string) => {
  if (!search) return '';

  const filtersValues = getSearchPackagesFilters(decodeURIComponent(search));
  const startDateValue = filtersValues[FilterTypes.StartDate] || '';
  const startTimeValue = formatDate(startDateValue, filtersAPIOutputDateFormat, shortUserTimeFormat);
  const endDateValue = filtersValues[FilterTypes.EndDate] || '';
  const endTimeValue = formatDate(endDateValue, filtersAPIOutputDateFormat, shortUserTimeFormat);

  return objectToQuery(search,
    {
      ...filtersValues,
      [FilterTypes.StartDate]: startDateValue ?
        formatDate(new Date(startTimeValue ? startDateValue : `${startDateValue} 00:00`).toISOString(), ISODateFormat, filtersAPIInputDateFormat)
        : '',
      [FilterTypes.EndDate]: endDateValue ?
        formatDate(new Date(endTimeValue ? endDateValue : `${endDateValue} 00:00`).toISOString(), ISODateFormat, filtersAPIInputDateFormat)
        : '',
    }
  );
}

export const emptyDownloadsFilters = {
  [FilterTypes.Author]: [],
  [FilterTypes.Location]: [],
  [FilterTypes.Status]: [],
  [FilterTypes.Content]: [],
  [FilterTypes.StartDate]: '',
  [FilterTypes.EndDate]: '',
};

export const emptyStatisticsFilters = {
  [FilterTypes.Author]: [],
  [FilterTypes.StartDate]: '',
  [FilterTypes.EndDate]: '',
};

export const getSearchStatisticsFilters = (search: string): StatisticsFiltersType => {
  const author = search.match(new RegExp(`${FilterTypesSnakiefied.Author}=[^&]+`, 'g'))
    ?.map(match => match.replace(`${FilterTypesSnakiefied.Author}=`, '')) || [];

  return {
    [FilterTypes.Author]: author,
    [FilterTypes.StartDate]: getSearchStartDateValue(search),
    [FilterTypes.EndDate]: getSearchEndDateValue(search),
  }
};
