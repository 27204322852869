// styles
import * as S from './style';

interface ICounter {
  counter: number,
  total: number,
  separator?: string,
  className?: string,
}

const Counter = ({
  counter,
  total,
  separator = ' из ',
  className,
}: ICounter) => {
  const isLimitExceeded = counter > total;

  return (
    <S.Text className={className} $isLimitExceeded={isLimitExceeded}>
      <span>{counter}</span>{separator}{total}
    </S.Text>
  )
}

export default Counter;