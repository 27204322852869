// types
import { StatusesTypes } from 'core/models/handbook/model.types';
// components
import { Status } from 'components/common';
// config
import { downloadStatuses } from 'core/config/statuses.config';
// styles
import * as S from './style';

interface IStatusEvent {
  oldStatusValue: StatusesTypes | undefined,
  newStatusValue: StatusesTypes | undefined,
}

const StatusEvent = ({
  oldStatusValue,
  newStatusValue,
}: IStatusEvent) => {
  if (!oldStatusValue || !newStatusValue) return <></>
  const oldStatus = downloadStatuses.find(status => status.value === oldStatusValue);
  const newStatus = downloadStatuses.find(status => status.value === newStatusValue);

  return (
    <S.Wrapper>
      {oldStatus && <Status status={oldStatus} />}
      <S.Icon />
      {newStatus && <Status status={newStatus} />}
    </S.Wrapper>
  )
}

export default StatusEvent;
