// types
import { AnyFunctionType, InputEventType, InputKeyboardEvent } from 'core/types/simple.types';
// utils
import { isEnterKey } from 'core/utils/events/eventHandlers';
// styles
import * as S from './style';

interface ISearch {
  value: string,
  placeholder: string,
  className?: string,
  onChange: (value: string) => any,
  onSearchFocus?: AnyFunctionType,
  onSearchBlur?: AnyFunctionType,
  onSearchButtonClick: AnyFunctionType,
  onEnterKeyDown?: AnyFunctionType,
}

const Search = ({
  value,
  placeholder,
  className = '',
  onChange,
  onSearchFocus,
  onSearchBlur,
  onSearchButtonClick,
  onEnterKeyDown,
}: ISearch) => {
  const onChangeHandler = (e: InputEventType) => {
    onChange(e.currentTarget.value);
  }

  const keyDownHandler = (e: InputKeyboardEvent) => {
    if (isEnterKey(e) && onEnterKeyDown) {
      onEnterKeyDown();
    }
  }

  return (
    <S.Wrapper className={className}>
      <S.Input
        placeholder={placeholder}
        value={value}
        onChange={onChangeHandler}
        onFocus={onSearchFocus}
        onBlur={onSearchBlur}
        onKeyDown={keyDownHandler}
      />
      <S.SearchButton type="button" onClick={onSearchButtonClick} />
    </S.Wrapper>
  )
}

export default Search;