import { useEffect, useState, useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// types
import { WithUserCheckType } from 'core/hocs/withUserCheck';
import { RoutesType } from 'core/constants/routes';
// actions
import { setNotificationsCounter } from 'core/models/users/slice';
import { openMessagesModal } from 'core/models/modal/slice';
import { fetchHandbookThunk } from 'core/models/handbook/thunks';
// utils
import { getSearchQueryValue, objectToQuery } from 'core/utils/urlQuery';
// constants
import { getCurrentTab, getMainTabs } from "core/constants/tabs";
import { searchQuery } from 'core/constants/queryParams';
// icons
import logo from "assets/icons/logo.svg";
// hooks
import { useAppDispatch } from 'core/store';
import { useSSE } from 'core/hooks/useSSE';
import { useTaskFetch } from 'core/hooks/useTaskFetch';
import { useModalSelector, usePackagesSelector, useHandbookSelector } from 'core/store/selectorHooks';
// hocs
import { withUserCheck } from 'core/hocs';
// components
import NotificationsBlock from "./components/NotificationsBlock";
import UserBlock from "./components/UserBlock";
// styles
import * as S from "./style";

interface IHeaderTemplate extends WithUserCheckType { };

const HeaderTemplate = ({
  user,
  permissions,
}: IHeaderTemplate) => {
  useTaskFetch();
  const handbook = useHandbookSelector('handbook');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { messagesModalHoverId } = useModalSelector(undefined);
  const { data: packages } = usePackagesSelector(undefined);
  const searchId = getSearchQueryValue(location.search, true);
  const search = getSearchQueryValue(location.search);
  const [tab, setTab] = useState(getCurrentTab(location.pathname as RoutesType));
  const [searchValue, setSearchValue] = useState(search || '');
  const tabs = getMainTabs(permissions);
  useSSE({
    path: 'events/web/event-count/stream/',
    userPermission: permissions.notification.read,
    setMessageState: useCallback((counter: string | null) => dispatch(setNotificationsCounter({ counter: Number(counter) || null })), [dispatch]),
  });

  const setTabHandler = (newTab: number) => {
    if (newTab !== tab) setTab(newTab);
  }

  const searchFocusHandler = () => {
    navigate({ search: objectToQuery(location.search, { [searchQuery]: '' }) });
  }

  const searchHandler = () => {
    navigate({ search: objectToQuery(location.search, { [searchQuery]: searchValue, }) });
  }

  useEffect(() => {
    if (!handbook || !handbook.editingStatusType || !handbook.eventType || !handbook.mediaType) {
      dispatch(fetchHandbookThunk());
    }
  }, [dispatch, handbook]);

  useEffect(() => {
    setSearchValue(search || '');
  }, [search]);

  useEffect(() => {
    if (messagesModalHoverId && Number(searchId) === packages[0].uploadId) {
      dispatch(openMessagesModal({ package: packages[0], }));
    }
  }, [dispatch, messagesModalHoverId, packages, searchId]);

  return (
    <>
      <S.Wrapper>
        <S.Logo src={logo} alt="" />
        {tabs.length > 1 &&
          <S.StyledTabs
            tabs={tabs}
            tab={tab}
            setTab={setTabHandler}
          />
        }
        {tabs.length === 1 && <div></div>}
        <S.StyledSearch
          placeholder="Поиск"
          value={searchValue}
          onChange={setSearchValue}
          onSearchFocus={searchId ? searchFocusHandler : undefined}
          onSearchBlur={searchHandler}
          onSearchButtonClick={searchHandler}
          onEnterKeyDown={searchHandler}
        />
        <NotificationsBlock />
        <UserBlock user={user} />
      </S.Wrapper>
      <Outlet />
    </>
  )
}

export default withUserCheck(HeaderTemplate, true);