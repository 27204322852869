// types
import { IShortUser } from "core/models/users/model.types";
import { FileType } from "core/types/simple.types";
import { StatusesTypes } from "../handbook/model.types";
import { IListReqResParams } from "../types/RequestProps.types";

export enum FilesType {
  photo = 'photo',
  photoSet = 'photoSet',
  video = 'video',
  videoSet = 'videoSet',
  mixedSet = 'mixedSet',
};

export type UploadTypes = 'photo' | 'photo_package' | 'video' | 'video_package' | 'package';

export interface IPackagesReq extends IListReqResParams {
  data: IPackage[],
}

interface IPackageCorrespondent extends Omit<IShortUser, 'id'> {
  userId: number,
}

export interface IPackage {
  uploadId: number,
  previewUrl: string | null,
  description: string,
  uploadDate: string,
  shootingDate: string,
  location: string,
  correspondent: IPackageCorrespondent,
  comment: string | null,
  alias: string | null,
  author: string | null,
  authorContact: string | null,
  lastMessageAuthor: string | null,
  lastMessageDate: string | null,
  editingStatus: StatusesTypes | null,
  editingStatusAuthor: string | null,
  mediaCount: number,
  size: number,
  uploadType: UploadTypes,
  isShootingLocationActual: boolean,
  isShootingDateActual: boolean,
  // verified: boolean,  // TODO: отсутствует
  medias: IPackageMedia[] | null,
  mediasURLs?: IPackageAPIFileData[],
};

export interface IPackageMedia {
  id: number,
  device: string,
  description: string | null,
  size: number,
  previewUrl: string,
  shootingDate: string,
  shootingLocation: string,
  title: string,
  mediaType: FilesType.photo | FilesType.video,
  // differences: boolean, // TODO: отсутствует
  originalMediaUrl?: string,
}

export interface IPackageAPIFileData {
  id: number,
  originalMediaUrl: string,
  title: string,
}

export interface IPackageFileData {
  title: string,
  file: FileType
}
export interface IDownloadReq {
  medias: IPackageAPIFileData[],
}

export interface IPackageStatusReq {
  status: StatusesTypes,
}

export interface IRotationMedia {
  mediaId: number,
  rotationAngle: number,
}

export enum EStatusesType {
  NEW = 'new',
  AT_WORK = 'at_work',
  REJECTED = 'rejected',
  PUBLISHED = 'published',
  SEND = 'send_to_social_networks',
}
