type WordsType = 'файл';

const choosePack = (word: WordsType): string[] => {
  switch (word) {
    case 'файл':
      return ['файл', 'файла', 'файлов'];
  }
}

const declensionWord = (count: number, word: WordsType): string => {
  const absCount = Math.abs(count);
  const pack = choosePack(word);
  const lastChar = Number(String(absCount).slice(-1));

  if (absCount > 9 && absCount < 21) return pack[2];
  if (lastChar > 1 && lastChar < 5) return pack[1];
  if (lastChar > 4 || lastChar === 0) return pack[2];
  return pack[0];
}

export const getFullDeclension = (count: number, word: WordsType): string => {
  return `${count} ${declensionWord(count, word)}`;
}

export default declensionWord;