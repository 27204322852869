// types
import { InputKeyboardEvent } from "core/types/simple.types";

export const isEnterKey = (e: InputKeyboardEvent) => {
  const key = e.key || e.code;

  return key === 'Enter';
}

export const createLinkAndExecute = (href: string, download: boolean = false, downloadTitle?: string) => {
  const link = document.createElement('a');
  link.href = href;
  if (download) link.setAttribute('download', downloadTitle || '');
  link.click();
}
