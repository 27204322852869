// types
import { FilterTypes, FilterUnionType, FiltersType } from "core/types/filter.types";

export const getIsFiltersActive = (filtersValues: Partial<FiltersType>) => {
  return {
    isAuthorActive: Boolean(filtersValues[FilterTypes.Author]?.length),
    isLocationActive: Boolean(filtersValues[FilterTypes.Location]?.length),
    isContentActive: Boolean(filtersValues[FilterTypes.Content]?.length),
    isStatusActive: Boolean(filtersValues[FilterTypes.Status]?.length),
    isPeriodActive: Boolean(filtersValues[FilterTypes.StartDate] || filtersValues[FilterTypes.EndDate]),
  };
}

export const getDisplayCancelButton = (filtersValues: Partial<FiltersType>, filterType: FilterUnionType['type']): boolean => {
  const { isAuthorActive, isLocationActive, isContentActive, isPeriodActive, isStatusActive } = getIsFiltersActive(filtersValues);

  switch (filterType) {
    case FilterTypes.Author: return isAuthorActive;
    case FilterTypes.Location: return isLocationActive;
    case FilterTypes.Content: return isContentActive;
    case FilterTypes.Status: return isStatusActive;
    case FilterTypes.Period: return isPeriodActive;
  }
}
