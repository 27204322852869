import styled from "styled-components";
// components
import OptionsMenu from "components/common/OptionsMenu";

export const OptionsCell = styled.div`
  position: relative;
  width: fit-content;
  cursor: default;
`;

export const StyledOptions = styled(OptionsMenu)`
  position: absolute;
  top: 20px;
  right: 0px;
`;