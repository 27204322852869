import styled, { css } from "styled-components";
// components
import Filter from 'components/common/Filter';
import TagsList from "components/lists/TagsList";
import { Button } from "components/UI";
// styles
import { simpleText, smallText, templateBorder, templatePaddings } from "styles/common";
import theme from "core/theme/theme";
import { getIconStyle } from '../../../styles/common';

export const Wrapper = styled.div`
  ${templateBorder};
  ${templatePaddings};
`;

export const FiltersWrapper = styled.div`
  min-height: 44px;
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`
  ${simpleText};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterWrapper = styled.div`
  position: relative;
  padding: 8px 6px 8px 12px;
  z-index: ${theme.zIndex.nav};
`;

interface FilterButtonProps extends FilterButtonStyleProps {
  $counter?: number,
}

const FilterButtonActiveStyle = css<FilterButtonProps>`
  color: ${theme.color.blue};
  gap: 0 24px;
  position: relative;
  
  &::before {
    content: ${({ $counter }) => `'${$counter}'`};
    position: absolute;
    top: 0;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    ${smallText};
    color: ${theme.color.white};
    background-color: ${theme.color.blue};
    border-radius: 100%;
  }

  &::after {
    color: ${theme.color.blue};
    font-weight: 600;
  }
`;

interface FilterButtonStyleProps {
  $openned: boolean,
}

const FilterButtonStyle = css<FilterButtonStyleProps>`
  display: flex;
  align-items: center;
  gap: 0 2px;
  ${simpleText};

  &::after {
    ${getIconStyle(theme.icons.chevronDown, 20)}
    transform: ${({ $openned }) => $openned ? 'scale(-1)' : ''};
  }
`;

export const FilterButton = styled.button<FilterButtonProps>`
  ${FilterButtonStyle};

  ${({ $counter }) => $counter && FilterButtonActiveStyle}
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0 20px;
  margin-right: 8px;
  margin-left: 20px;

  ${simpleText};
  
  &::after {
    content: '';
    width: 16px;
    height: 1px;
    background: #000;
  }
`;

export const TagsButton = styled.button<FilterButtonStyleProps>`
  ${FilterButtonStyle};
  margin-right: 21px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: -20px;
    width: 1px;
    height: 100%;
    background-color: ${theme.color.altoGray};
  }
`;

interface StyledFilterProps {
  readonly $placeOnRight?: boolean;
}

export const StyledFilter = styled(Filter) <StyledFilterProps>`
  position: absolute;
  z-index: ${theme.zIndex.menu};
  top: 40px;
  ${({ $placeOnRight = false }) => $placeOnRight
    ? 'left: auto; right: 0'
    : 'left: 0; right: auto'
  };
`;

export const StyledButton = styled(Button)`
  margin-left: 16px;
`

export const StyledTagsList = styled(TagsList)`
  padding: 4px 0 16px;
`;
