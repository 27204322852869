// types
import { RoutesType, routesPrivate } from 'core/constants/routes';
import { UserPermissionsType } from 'core/models/users/model.types';
import { WithDisplayPropertyType } from 'core/types/advanced.types';

export type tabType = {
  title: string,
  value: number,
  href?: RoutesType,
};

export const getCurrentTab = (pathname: RoutesType): number => {
  switch(pathname) {
    case '/downloads': return 1;
    case '/statistics': return 2;
    default: return 0;
  }
}

export const getMainTabs = (permissions: UserPermissionsType): tabType[] => {
  const tabs: WithDisplayPropertyType<tabType>[] = [
    {
      title: 'Контент',
      value: 1,
      href: routesPrivate.DOWNLOADS,
      display: permissions.downloads.read,
    },
    {
      title: 'Статистика',
      value: 2,
      href: routesPrivate.STATISTICS,
      display: permissions.statistic.read,
    },
  ];

  return tabs
    .filter(item => item.display)
    .map(({ title, value, href }) => ({ title, value, href, }));
};

export const notificationTabs: tabType[] = [
  {
    title: 'Уведомления',
    value: 1,
  },
  {
    title: 'Настройки',
    value: 2,
  },
];