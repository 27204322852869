import { useEffect, useState } from "react"
// utils
import { clearContentEditable } from "core/utils/clearContentEditable";

export interface ITemplateData {
  name: string,
  value: string,
  editable: boolean,
  slug?: string,
  insertAfter?: boolean,
  invisible?: boolean,
  deleteTemplate?: () => void,
}

interface IProps {
  value: string,
  templateData: ITemplateData[],
  onChange: (value: string) => void,
}

export const useContentEditableTemplate = ({
  value,
  templateData,
  onChange,
}: IProps): [string, IProps['onChange']] => {
  // Do not deep in this code, it's trash
  const templateDataStr = JSON.stringify(templateData);
  const spanOpenTagArr = templateData.map(item => `${!item.editable ? '<span contenteditable="false"' : '<p'} data-name="${item.name}">`);
  const spanCloseTag = '</span>';
  const pCloseTag = '</p>';
  const templateSubStrRegexpArr = spanOpenTagArr.map((item, i) => new RegExp(`${item}.+${!templateData[i].editable ? `${spanCloseTag}` : pCloseTag}`, 'g'));
  const [visibleValue, setVisibleValue] = useState(value);

  const onInputHandler = (innerHTML: string) => {
    let hiddenValue = innerHTML;
    let visibleValue = innerHTML;
    templateData.forEach((item, i) => {
      const templateSubStr = innerHTML.match(templateSubStrRegexpArr[i]) || null;

      visibleValue = visibleValue.replaceAll(/<p[^>]+><br><\/p>/g, '\n');

      if (!templateSubStr && item.deleteTemplate) {
        item.deleteTemplate();
      }

      if (templateSubStr && item.invisible) {
        hiddenValue = hiddenValue.replace(templateSubStr[0], '').trim();
      }
    });

    onChange(hiddenValue)
    setVisibleValue(visibleValue === '\n' ? '' : visibleValue);
  }

  useEffect(() => {
    let visibleValue = value;
    let hiddenValue = value;

    templateData.forEach((item, i) => {
      const templateSubStr = visibleValue.match(templateSubStrRegexpArr[i]) || '';
      const pureValue = visibleValue.replace(templateSubStr[0], '').trim() || '';
      const templateValue = item.value ? `${spanOpenTagArr[i]}${item.value}${!item.editable ? `${spanCloseTag}\n` : pCloseTag}` : '';

      if (item.insertAfter) {
        visibleValue = `${pureValue}${templateValue ? `${templateValue}` : ''}`;
      } else {
        visibleValue = `${templateValue ? `${templateValue}${pureValue === '' ? '\n' : ''}` : ''}${pureValue}`;
      }

      if (item.invisible) {
        hiddenValue = pureValue;
      } else {
        hiddenValue = visibleValue;
      }
    });

    const resultVisibleValue = clearContentEditable({ value: visibleValue, replaceNbsp: false, cutValue: false, });
    const resultHiddenValue = clearContentEditable({ value: hiddenValue, replaceNbsp: false, cutValue: false, });

    setVisibleValue(resultVisibleValue);

    if (value !== resultHiddenValue) {
      onChange(resultHiddenValue);
    }
  }, [templateDataStr]);

  return [visibleValue, onInputHandler];
}
