import { css } from "styled-components";
import theme, { ThemeIconsType } from "core/theme/theme";

export const templatePaddings = css`
  padding-left: 16px;
  padding-right: 16px;
`;

export const solidBorderProperty = `1px solid ${theme.color.altoGray}`;

export const templateBorder = css`
  border-bottom: ${solidBorderProperty};
`;

export const simpleText = css`
  color: ${theme.color.black};
  font-size: 14px;
  line-height: 20px;
`;


export const simpleBoldText = css`
  ${simpleText};
  font-weight: 700;
`;

export const superTitleText = css`
  color: ${theme.color.black};
  font-size: 22px;
  line-height: 28px;
`;

export const bigTitleText = css`
  color: ${theme.color.black};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

export const titleText = css`
  color: ${theme.color.black};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const smallText = css`
  font-size: 12px;
  line-height: 16px;
`;

export const smallBoldText = css`
  ${smallText};
  font-weight: 700;
`;

export const iconInputWrapperStyle = css`
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid ${theme.color.nobelGray};
  border-radius: 2px;
  background-color: ${theme.color.white};
`;

export const iconInputStyle = css`
  width: 100%;
  ${simpleText};

  &::placeholder {
    color: ${theme.color.nobelGray};
  }
`;

export const iconInputIconStyle = css`
  min-width: 20px;
  min-height: 20px;
  background: no-repeat center/cover;
`;

export const menuBoxShadow = css`
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
`;

export const TooltipPointerStyle = css`
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  left: calc(50% - 5px);
  transform: rotateZ(45deg);
  background-color: ${theme.color.black};
`;

export const visibleStyle = css`
  opacity: 1;
  z-index: ${theme.zIndex.auto};
  visibility: visible;
`;

export const visibleMenuStyle = css`
  opacity: 1;
  z-index: ${theme.zIndex.menu};
  visibility: visible;
`;

export const invisibleStyle = css`
  opacity: 0;
  z-index: ${theme.zIndex.none};
  visibility: hidden;
`;

export const getIconStyle = (icon: ThemeIconsType | '', size: number = 24, color: 'black' | 'white' = 'black' ) => {
  return css`
    content: '${icon}';
    font-family: "Tass-Icons";
    font-size: ${size}px;
    color: ${theme.color[color]};
  `;
}

export const getIconElemStyle: typeof getIconStyle = (icon, size, color) => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      ${getIconStyle(icon, size, color)}
    }
  `;
}

export const ellipsisStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
