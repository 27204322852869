import styled from "styled-components";
// components
import { Search } from "components/UI";
import { Tabs } from "components/common";
// styles
import { templateBorder, templatePaddings } from "styles/common";

export const Wrapper = styled.header`
  display: grid;
  align-items: center;
  grid-template-columns: auto 0.3fr repeat(2, min-content) minmax(165px, 0.1fr);
  grid-gap: 0 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  ${templateBorder};
  ${templatePaddings};
`;

export const Logo = styled.img``;

export const StyledTabs = styled(Tabs)`
  &>a {
    margin-bottom: -25px;
  }
`;

export const StyledSearch = styled(Search)`
  margin-right: 8px;
`;
