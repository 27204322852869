// types
import { APIIntputDateFormat, ISODateFormat } from "core/constants/dateFormats";
import { EventEntryType } from "core/models/events/model.types";
import { formatDate } from 'core/utils/date';

export const combineNotifications = (messages: EventEntryType[]): EventEntryType[][] => {
  const combined = messages.reduce<Record<string, EventEntryType[]>>((acc, item) => {
    const formattedDate = formatDate(item.timestamp, ISODateFormat, APIIntputDateFormat);

    return {
      ...acc,
      [formattedDate]: formattedDate in acc
        ? [...acc[formattedDate], item]
        : [item]
    }
  }, {});

  return Object.values(combined);
}