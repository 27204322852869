// types
import type { AxiosResponseDataType } from "core/utils/requestParser";
import { IAddMessageRes, IMessage } from "./model.types";
import { IdPropsType } from "../types/RequestProps.types";
// utils
import { mediaWeb } from "core/utils/axiosInstance";
import requestParser from "core/utils/requestParser";

const URL = 'packages'

export interface FetchDownloadCommentsReqProps extends IdPropsType { };

export const fetchPackageCommentsReq =
  requestParser(({ id }: FetchDownloadCommentsReqProps) => mediaWeb.get(`${URL}/${id}/messages/`)) as
  (props: FetchDownloadCommentsReqProps) => AxiosResponseDataType<IMessage[]>;

export interface AddMessageReqProps extends IdPropsType {
  message: string,
  answerMessageId: number | null,
};

export const addMessageReq =
  requestParser(({ id, ...data }: AddMessageReqProps) => mediaWeb.post(`${URL}/${id}/messages/`, data)) as
  (props: AddMessageReqProps) => AxiosResponseDataType<IAddMessageRes>;
  