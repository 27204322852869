import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// types
import { IStatistic } from 'core/models/statistics/model.types';
import { WithUserCheckType } from 'core/hocs/withUserCheck';
import { FilterTypes, FilterTypesSnakiefied, FiltersType } from 'core/types/filter.types';
// thunks
import { fetchStatisticsThunk } from 'core/models/statistics/thunks';
import { fetchUsersThunk } from 'core/models/users/thunks';
// actions
import { clearStatistics } from 'core/models/statistics/slice';
// hooks
import { usePermissionNavigate } from "core/hooks/usePermissionNavigate";
import { useAppDispatch } from 'core/store';
import { useStatisticsSelector } from 'core/store/selectorHooks';
// utils
import { clearQueryParam, objectToQuery } from 'core/utils/urlQuery';
import { formatDate, toLocaleString, UTCFormatDate, UTCFormatDateReverse } from 'core/utils/date';
import { bytesToGbytes } from 'core/utils/base/calcValues';
import { getOptionsInfo } from 'core/config/userData';
// config
import { emptyStatisticsFilters, getSearchStatisticsFilters, getStatisticsFilters, getUTCSearch } from "core/config/filters.config";
import { APIIntputDateFormat, ISODateFormat, userDateFormat, userDateTimeFormat } from 'core/constants/dateFormats';
// hocs
import { withFetch, withUserCheck } from 'core/hocs';
// components
import Article from "./components/Article";
import FiltersLine from "components/common/FiltersLine";
// styles
import * as S from './style';

const getFormattedPeriodStr = (startDateStr: string | undefined, finishDateStr: string | undefined): string => {
  if (!startDateStr || !finishDateStr) return '';

  const finishDate = new Date(finishDateStr);
  finishDate.setDate(finishDate.getDate() - 1);

  return (startDateStr && finishDateStr)
    ? `${UTCFormatDateReverse(startDateStr, userDateFormat)} – ${UTCFormatDateReverse(formatDate(toLocaleString(finishDate), userDateTimeFormat, ISODateFormat), userDateFormat)}`
    : '';
}

interface IStatisticsPage extends WithUserCheckType {
  data: IStatistic | null,
  children: React.ReactNode,
  isLoading: boolean,
}

const StatisticsPage = ({
  permissions,
  user,
  data,
  isLoading,
  children: loaderChild,
}: IStatisticsPage) => {
  usePermissionNavigate(permissions.statistic.read);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [fetchStatistics, setFetchStatistics] = useState(true);
  const { users, usersIsLoading, } = useStatisticsSelector(undefined);
  const usersOptions = users.map(({ id, fullName, }) => ({ id, value: (id).toString(), name: fullName, }))
  const formattedPeriodStr = getFormattedPeriodStr(data?.startDate, data?.finishDate);
  const filtersValues = getSearchStatisticsFilters(decodeURIComponent(location.search));

  const onFiltersChange = (filtersValues: Partial<FiltersType>) => {
    setFetchStatistics(false);
    navigate({ search: objectToQuery(location.search, filtersValues) });
  }

  const fetchStatisticsHandler = useCallback(() => {
    const finishDate = new Date(filtersValues[FilterTypes.EndDate] || '');
    finishDate.setDate(finishDate.getDate() + 1);

    dispatch(fetchStatisticsThunk({
      data: {
        userId: Number(filtersValues[FilterTypes.Author]),
        query: clearQueryParam(
          getUTCSearch(objectToQuery(location.search, { ...filtersValues, finishDate: UTCFormatDate(finishDate, APIIntputDateFormat) })),
          `${FilterTypesSnakiefied.Author}=${filtersValues[FilterTypes.Author]}`
        ),
      }
    }));
  }, [dispatch, filtersValues, location.search]);

  const confirmButtonHandler = () => {
    if (!filtersValues[FilterTypes.StartDate] || !filtersValues[FilterTypes.EndDate] || !filtersValues[FilterTypes.Author].length) {
      toast('Необходимо заполнить все фильтры');
    } else {
      fetchStatisticsHandler();
    }
  }

  const fetchUsersHandler = useCallback((fullName: string) => {
    dispatch(fetchUsersThunk({ data: { fullName } }));
  }, [dispatch]);

  useEffect(() => {
    if (fetchStatistics) {
      // don`t use higher level filtersValues because of useEffect infinite loop
      const filtersValues = getSearchStatisticsFilters(location.search);
      if (filtersValues[FilterTypes.StartDate] && filtersValues[FilterTypes.EndDate] && filtersValues[FilterTypes.Author].length) {
        fetchStatisticsHandler();
      }
    }
    setFetchStatistics(false);
    // double fetch if add fetchStatisticsHandler to dependence array
  }, [dispatch, fetchStatistics, location.search, user.id]);

  useEffect(() => {
    return () => {
      dispatch(clearStatistics());
    }
  }, [dispatch]);

  return (
    <>
      <FiltersLine
        filtersConfig={getStatisticsFilters({
          authorOptions: usersOptions,
          authorOptionsIsLoading: usersIsLoading,
          fetchAuthorOptions: fetchUsersHandler,
        })}
        filtersValues={filtersValues}
        title='Статистика'
        emptyFilters={emptyStatisticsFilters}
        hasControls={false}
        confirmButtonText='Показать статистику'
        confirmButtonHandler={confirmButtonHandler}
        onFiltersChange={onFiltersChange}
      />
      <S.Wrapper>
        {data ?
          <>
            <S.Title>Статистика ТАСС.АСИСТЕНТ {formattedPeriodStr}</S.Title>
            <Article title="Отчетный период:" value={formattedPeriodStr} />
            <Article title="Корреспондент:" value={data.correspondent.fullName} hoverOptions={getOptionsInfo(data.correspondent)} />
            <Article title="Количество отправленных материалов:" value={data.totalUploadCount} />
            <Article title="Количество фотографий:" value={data.totalPhotoCount} />
            <Article title="Количество видео:" value={data.totalVideoCount} />
            <Article title="Количество опубликованных фотографий:" value={data.publishedPhotoCount} />
            <Article title="Количество опубликованных видео:" value={data.publishedVideoCount} />
            <Article title="Общий вес материалов (всех пакетов за отчетный период):" value={`~${bytesToGbytes(data.totalMediaSize)} Гб`} />
          </>
          : !isLoading && <S.NoData>Выберите в фильтрах период и корреспондента</S.NoData>
        }
      </S.Wrapper>
      {loaderChild}
    </>
  )
}

export default withFetch(withUserCheck(StatisticsPage), 'statistics', { withFetch: false, withEmptyDataCheck: false, });
