import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
// routes
import RoutesComponent from "./routes";
// components
import Modal from "components/common/Modal";
import StyledToastContainer from "styles/toastContainer";
// styles
import { GlobalStyles, DayPickerStyles } from './styles';
import theme from "core/theme/theme";
import 'react-toastify/dist/ReactToastify.css';
// store
import store from "./core/store";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <GlobalStyles />
        <DayPickerStyles />
        {/* <ToastContainerStyles /> */}
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <RoutesComponent />
            <Modal />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
      <StyledToastContainer />
    </>
  );
}

export default App;
