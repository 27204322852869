import { NavigateFunction } from 'react-router-dom';
// api
import { SSOLoginReq, loginReq, logoutReq } from './auth.api';
// utils
import { createCookie, removeCookie } from 'core/utils/cookies/cookie';
import createAsyncThunkExtended from 'core/store/extendedThunk';
// config
import { routesPrivate, routesPublic } from 'core/constants/routes';

interface LoginActionProps {
  rememberMe: boolean,
  navigate: NavigateFunction
}

export const loginThunk = createAsyncThunkExtended(
  'auth/login',
  loginReq,
  (response, { rememberMe, navigate }: LoginActionProps) => {
    createCookie('token', response.accessToken, rememberMe ? response.expiresAt : undefined);
    createCookie('refreshToken', response.refreshToken);
    navigate(routesPrivate.DOWNLOADS);
    return response;
  }
);


interface SSOLoginActionProps {
  navigate: NavigateFunction
}

export const SSOLoginReqThunk = createAsyncThunkExtended(
  'auth/SSOLogin',
  SSOLoginReq,
  (response, { navigate }: SSOLoginActionProps) => {
    createCookie('token', response.accessToken, response.expiresAt);
    createCookie('refreshToken', response.refreshToken);
    navigate(routesPrivate.DOWNLOADS);
    return response;
  }
);

interface LogoutActionProps {
  navigate: NavigateFunction,
}

export const logoutThunkActionType = 'auth/logout';

export const logoutThunk = createAsyncThunkExtended(
  'auth/logout',
  logoutReq,
  (response, { navigate }: LogoutActionProps) => {
    removeCookie('token');
    navigate(routesPublic.AUTH);
    return response;
  }
);

interface LogoutActionProps {
  navigate: NavigateFunction,
}
