import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
// types
import { IPackage } from 'core/models/packages/model.types';
import { WithUserCheckType } from 'core/hocs/withUserCheck';
import { RotatePositionType } from 'core/types/advanced.types';
import { GalleryFileType } from 'core/models/modal/model.types';
import { IChangeOrientationTask, ITargetTask } from 'core/models/users/model.types';
// actions
import { setGalleryFile, setGalleryRotations } from 'core/models/modal/slice';
// thunks
import { changeMediaOrientationThunk, } from 'core/models/packages/thunks';
import { getTaskResultThunk } from 'core/models/users/thunks';
// hooks
import { useModalSelector, useUsersSelector } from 'core/store/selectorHooks';
import { useAppDispatch } from 'core/store';
import { useTimeout } from 'core/hooks/useTimeout';
// hocs
import { withModalFetch, withUserCheck } from 'core/hocs';
// utils
import { downloadFilesHandler } from 'core/models/packages/utils';
// components
import Counter from 'components/common/Counter';
import { Button } from 'components/UI';
// styles
import * as S from './style';

interface IGalleryModal extends WithUserCheckType {
  id: number,
  data: IPackage,
}

const GalleryModal = ({
  id,
  data,
  permissions,
}: IGalleryModal) => {
  const dispatch = useAppDispatch();
  const { galleryFileKey, galleryFile: file, galleryRotations } = useModalSelector(undefined);
  const [fileKey, setFileKey] = useState(galleryFileKey || 0);
  const [downloadIndicator, setDownloadIndicator] = useState(false);
  const fileRotation = galleryRotations[fileKey];
  const [rotatePosition, setRotatePosition] = useState<RotatePositionType>(0)
  const packageMedias = useMemo(() => data.medias || [], [data.medias]);
  const packageMediasURLs = useMemo(() => data.mediasURLs || [], [data.mediasURLs]);
  const { tasks } = useUsersSelector(undefined);
  const fileTask = tasks.find(task => (task.subTargetId === file?.id) && task.data?.type === 'changeOrientation') as ITargetTask<IChangeOrientationTask> | undefined;

  const orientationSavingToast = () => {
    toast("Происходит изменение ориентации медиа, повторите запрос чуть позже")
  }

  const changeMediaOrientation = useCallback(() => {
    setRotatePosition(0);
    if (file) {
      dispatch(changeMediaOrientationThunk({
        data: {
          uploadId: id,
          rotationMedia: [{
            mediaId: file.id,
            rotationAngle: -rotatePosition,
          }],
        },
        actionData: {
          uploadId: data.uploadId,
        },
      }));
    }
  }, [data.uploadId, dispatch, file, id, rotatePosition]);

  const [rotateTimer, clearTimer, changeMediaOrientationWithTimeout] = useTimeout(changeMediaOrientation, 2000);

  const onPrevClick = () => {
    if (rotateTimer) {
      clearTimer(rotateTimer)
      changeMediaOrientation();
    };
    setRotatePosition(0)

    if (fileKey === 0) {
      setFileKey(packageMedias.length - 1);
    } else {
      setFileKey(fileKey - 1)
    }
  }

  const onNextClick = () => {
    if (rotateTimer) {
      clearTimer(rotateTimer)
      changeMediaOrientation();
    };
    setRotatePosition(0)

    if (fileKey === (packageMedias.length - 1)) {
      setFileKey(0);
    } else {
      setFileKey(fileKey + 1)
    }
  }

  const downloadMedia = (file: GalleryFileType) => {
    if (rotateTimer) {
      clearTimer(rotateTimer)
      orientationSavingToast();
      changeMediaOrientation();
    } else {
      if (fileTask?.state === 'pending') {
        dispatch(getTaskResultThunk({ data: { taskId: fileTask.taskId }, actionData: {} }))
        setDownloadIndicator(true);
      } else {
        downloadFilesHandler({
          packageId: data.uploadId,
          medias: [{ ...file, originalMediaUrl: (fileTask?.data?.newOriginalUrl || file?.originalMediaUrl) as string }],
          archive: false,
          dispatch,
        });
      }
    }
  }

  useEffect(() => {
    dispatch(setGalleryRotations({ rotations: packageMedias.map(() => 0) }));
  }, [packageMedias, dispatch]);

  useEffect(() => {
    dispatch(setGalleryFile({ galleryFile: { ...packageMedias[fileKey], ...packageMediasURLs[fileKey] } }));
  }, [packageMedias, packageMediasURLs, fileKey, dispatch]);

  useEffect(() => {
    if (file && downloadIndicator && fileTask?.state === 'done') {
      setDownloadIndicator(false);
      downloadFilesHandler({
        packageId: data.uploadId,
        medias: [{ ...file, originalMediaUrl: fileTask.data?.newOriginalUrl as string }],
        archive: false,
        dispatch,
      });
    }
  }, [data.uploadId, dispatch, downloadIndicator, file, fileTask?.data?.newOriginalUrl, fileTask?.state]);

  useEffect(() => {
    if (rotatePosition !== 0 && file) {
      changeMediaOrientationWithTimeout();
    } else {
      if (rotateTimer) {
        clearTimer(rotateTimer)
      }
    }
  }, [rotatePosition, file, changeMediaOrientationWithTimeout]);

  const setRotation = () => {
    if (rotatePosition === 270) {
      setRotatePosition(0);
    } else {
      setRotatePosition((rotatePosition + 90) as RotatePositionType);
    }

    let fileRotationValue: RotatePositionType = 0;

    if (fileRotation === 270) {
      fileRotationValue = 0;
    } else {
      fileRotationValue = (fileRotation + 90) as RotatePositionType;
    }

    const updatedRotations = [
      ...galleryRotations.slice(0, fileKey),
      fileRotationValue,
      ...galleryRotations.slice(fileKey + 1),
    ];
    dispatch(setGalleryRotations({ rotations: updatedRotations }));
  }

  const refreshImg = () => {
    setRotatePosition((360 - fileRotation) as RotatePositionType);

    const updatedRotations = [
      ...galleryRotations.slice(0, fileKey),
      0 as RotatePositionType,
      ...galleryRotations.slice(fileKey + 1),
    ];
    dispatch(setGalleryRotations({ rotations: updatedRotations }));
  }

  return (
    <S.Wrapper>
      {file && file.id &&
        <>
          <S.StyledFileContent
            packageEntity={data}
            file={file}
            rotatePosition={fileRotation}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            setRotatePosition={setRotation}
            refreshImg={refreshImg}
          />
          <S.Operations>
            <Counter counter={fileKey + 1} total={packageMedias.length} />
            {permissions.downloads.upload &&
              <Button text='Скачать' onClick={() => downloadMedia(file)} />
            }
          </S.Operations>
        </>
      }
    </S.Wrapper>
  )
}

export default withModalFetch(withUserCheck(GalleryModal), 'gallery', { async: true });
