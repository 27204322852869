// types
import { AnyFunctionType, InputKeyboardEvent } from "core/types/simple.types";
// styles
import { InputField } from "./style";

export interface IInput {
  placeholder: string,
  type?: React.HTMLInputTypeAttribute,
  value?: string,
  autoFocus?: boolean,
  disabled?: boolean,
  readOnly?: boolean,
  onClick?: AnyFunctionType,
  onFocus?: AnyFunctionType,
  onBlur?: AnyFunctionType,
  onChange?: AnyFunctionType
  onKeyDown?: (e: InputKeyboardEvent) => void
}

const Input = ({
  type = 'text',
  ...rest
}: IInput) => {
  return (
    <InputField
      type={type}
      {...rest}
    />
  )
}

export default Input;