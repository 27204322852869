import styled from "styled-components";
// styles
import theme from "core/theme/theme";
import { getIconElemStyle } from "styles/common";

export const Wrapper = styled.div`
  display: flex;
  gap: 0 12px;
`;

export const Icon = styled.div`
  ${getIconElemStyle(theme.icons.chevronLeft, 16)};
  transform: rotateZ(180deg);
`;
