import { AnyAction, ThunkDispatch, createAsyncThunk } from "@reduxjs/toolkit";
// types
import { AxiosResponseDataType, IReqError, isResponseReqError } from "core/utils/requestParser";

export type ThunkProps<ReqProps, ActionData> = {
  data: ReqProps,
  actionData: ActionData,
}

interface ExtendedThunkAPI {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
}

const createAsyncThunkExtended = <Response, ReqProps, ActionData, SuccessActionResponse extends (Response | {data: Response})>(
  name: string,
  req: (props: ReqProps) => AxiosResponseDataType<Response>,
  successAction: (response: Response, data: ActionData, reqData: ReqProps, thunkAPI: ExtendedThunkAPI) => SuccessActionResponse,
) => {
  return createAsyncThunk<
    SuccessActionResponse,
    ThunkProps<ReqProps, ActionData>,
    {
      rejectValue: IReqError
    }
  >(
    name,
    async ({ data, actionData }, { rejectWithValue, dispatch, }) => {
      const response = await req(data);

      if (isResponseReqError(response)) {
        return rejectWithValue(response);
      }

      return successAction(response, actionData, data, { dispatch });
    }
  )
}

export const createAsyncThunkExtendedNoData = <Response>(
  name: string,
  req: () => AxiosResponseDataType<Response>,
  successAction?: (response: Response) => Response,
) => {
  return createAsyncThunk<
    Response,
    void,
    {
      rejectValue: IReqError
    }
  >(
    name,
    async (_, { rejectWithValue }) => {
      const response = await req();

      if (isResponseReqError(response)) {
        return rejectWithValue(response);
      }

      if (successAction) {
        return successAction(response);
      };

      return response;
    }
  )
}

export type ThunkPropsNoReqData<ActionData> = {
  actionData: ActionData,
}

export const createAsyncThunkExtendedNoReqData = <Response, ActionData>(
  name: string,
  req: () => AxiosResponseDataType<Response>,
  successAction?: (response: Response, data: ActionData, thunkAPI: ExtendedThunkAPI) => Response,
) => {
  return createAsyncThunk<
    Response,
    ThunkPropsNoReqData<ActionData>,
    {
      rejectValue: IReqError
    }
  >(
    name,
    async ({ actionData }, { rejectWithValue, dispatch }) => {
      const response = await req();

      if (isResponseReqError(response)) {
        return rejectWithValue(response);
      }

      if (successAction) {
        return successAction(response, actionData, { dispatch });
      };

      return response;
    }
  )
}

export type ThunkPropsNoActionData<ReqProps> = {
  data: ReqProps,
}

export const createAsyncThunkExtendedNoActionData = <Response, ReqProps>(
  name: string,
  req: (props: ReqProps) => AxiosResponseDataType<Response>,
  successAction?: (response: Response) => Response,
) => {
  return createAsyncThunk<
    Response,
    ThunkPropsNoActionData<ReqProps>,
    {
      rejectValue: IReqError
    }
  >(
    name,
    async ({ data }, { rejectWithValue }) => {
      const response = await req(data);

      if (isResponseReqError(response)) {
        return rejectWithValue(response);
      }

      if (successAction) {
        return successAction(response);
      };

      return response;
    }
  )
}

export default createAsyncThunkExtended;