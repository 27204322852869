import createAsyncThunkExtended, { createAsyncThunkExtendedNoActionData, createAsyncThunkExtendedNoData } from "core/store/extendedThunk";
// api
import { fetchUserByIdReq, fetchUserReq, fetchUsersReq, getTaskResultReq } from "./users.api";

export const fetchUserThunk = createAsyncThunkExtendedNoData(
  'user/fetch',
  fetchUserReq,
);

export const fetchUserByIdThunk = createAsyncThunkExtendedNoActionData(
  'user/id/fetch',
  fetchUserByIdReq,
);

export const fetchUsersThunk = createAsyncThunkExtendedNoActionData(
  'users/fetch',
  fetchUsersReq,
);

export const getTaskResultThunk = createAsyncThunkExtended(
  'task-result/get',
  getTaskResultReq,
  (response, _, { taskId }) => {
    return { data: { ...response, taskId } };
  }
);
