// types
import { NullableRefType } from 'core/types/simple.types';
// styles
import * as S from './style';

export interface IOptionsInfo {
  options: {
    title: string,
    value: string,
  }[],
  className?: string,
  optionsRef?: NullableRefType,
}

const OptionsInfo = ({
  options,
  className,
  optionsRef,
}: IOptionsInfo) => {
  return (
    <S.Wrapper className={className} ref={optionsRef}>
      {options.map(({ title, value }, key) =>
        <S.Item key={key}>
          <p>{title}</p>
          <p>{value}</p>
        </S.Item>
      )}
    </S.Wrapper>
  )
}

export default OptionsInfo;
