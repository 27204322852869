import { useEffect } from "react";
// actions
import { setErrorData } from "core/models/error/slice";
// hooks
import { useAppDispatch } from "core/store";

export const usePermissionNavigate = (permission: boolean) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!permission) {
      dispatch(setErrorData({ type: 'noPermError', }));
    }
  }, [dispatch, permission]);
}
