import { useCallback, useEffect, useState } from 'react';
// types
import { MimeTypes } from 'core/types/advanced.types';
// components
import VideoControls from './components/VideoControls';
// styles
import * as S from './style';

interface IVideo {
  src: string | undefined,
  mimeType: MimeTypes | '',
  maxWidth: number,
  maxHeight: number,
}

const Video = ({
  src,
  mimeType,
  maxWidth,
  maxHeight,
}: IVideo) => {
  const [videoTarget, setVideoTarget] = useState<HTMLVideoElement | null>(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const setVideoLoadedValue = useCallback(() => {
    setVideoLoaded(Boolean(videoTarget?.readyState && [2,3,4].includes(videoTarget?.readyState)))
  }, [videoTarget?.readyState]);

  useEffect(() => {
    videoTarget?.addEventListener('loadeddata', (e) => {
      setVideoLoadedValue();
    })

    return () => {
      videoTarget?.removeEventListener('loadeddata', setVideoLoadedValue);
    }
  }, [videoTarget, setVideoLoadedValue]);

  return (
    <S.Wrapper>
      <S.Video
        style={{ maxWidth, maxHeight, }}
        preload="auto"
        playsInline
        ref={ref => setVideoTarget(ref)}
        src={src}
      >
      </S.Video>
      {videoTarget && <VideoControls videoTarget={videoTarget} videoLoaded={videoLoaded} src={src} />}
    </S.Wrapper>
  )
}

export default Video;