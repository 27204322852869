// types
import { IPackage } from 'core/models/packages/model.types';
import { PreviewCellType } from 'core/types/previewCell.types';
import { AnyFunctionType, ClickEventType } from 'core/types/simple.types';
import type { DownloadMediaType } from 'components/lists/PackagesList/components/PackageItem';
// utils
import { getFullDeclension } from 'core/utils/declensionWord';
import { bytesToMbytes } from 'core/utils/base/calcValues';
// components
import { Checkbox } from 'components/UI';
// styles
import * as S from './style';

interface IPreviewCell {
  uploadId: number,
  size: IPackage['size'],
  mediaCount?: IPackage['mediaCount'],
  uploadType: IPackage['uploadType'],
  previewUrl: string,
  type?: PreviewCellType,
  checked?: boolean,
  className?: string,
  onDownload?: DownloadMediaType,
  onCheckboxClick?: AnyFunctionType,
  onFilesClick?: AnyFunctionType,
  onClick?: AnyFunctionType,
}

const PreviewCell = ({
  uploadId,
  size,
  mediaCount = 1,
  uploadType,
  previewUrl,
  type = 'simple',
  checked = false,
  className,
  onDownload,
  onCheckboxClick,
  onFilesClick,
  onClick,
}: IPreviewCell) => {

  const checkboxClickHandler = (e: ClickEventType) => {
    e.stopPropagation();

    if (onCheckboxClick) {
      onCheckboxClick();
    }
  }

  const downloadClickHandler = (e: ClickEventType) => {
    e.stopPropagation();

    if (onDownload) onDownload(false, [uploadId]);
  }

  const onFilesSetClick = (e: ClickEventType) => {
    e.stopPropagation();

    if (onFilesClick) {
      onFilesClick();
    }
  }

  const onPreviewClick = (e: ClickEventType) => {
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  }

  return (
    <S.Wrapper onClick={onPreviewClick} className={className || ''} $clickable={Boolean(onClick)} $previewUrl={previewUrl}>
      <S.MarksWrapper>
        {(type === 'differences' || type === 'diffAndVerif') && <S.Differences />}
        {(type === 'verified' || type === 'diffAndVerif') && <S.Verified>Проверено</S.Verified>}
        {type === 'checkboxAndDownload' &&
          <S.CheckboxWrapper $checked={checked}>
            <Checkbox
              checked={checked}
              onChange={checkboxClickHandler}
              onClick={(e) => e.stopPropagation()}
            />
          </S.CheckboxWrapper>
        }
      </S.MarksWrapper>
      {type === 'checkboxAndDownload' &&
        <S.Download type="button" onClick={downloadClickHandler} />
      }
      <S.FilesSet onClick={onFilesSetClick}>
        <S.FilesSetIcon $type={uploadType} />
        {mediaCount > 1 && <p>{getFullDeclension(mediaCount, 'файл')}</p>}
      </S.FilesSet>
      <S.FilesSize>
        <p>{bytesToMbytes(size)} МБ</p>
      </S.FilesSize>
    </S.Wrapper>
  )
}

export default PreviewCell;