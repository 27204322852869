import styled from "styled-components";
// styles
import { bigTitleText, simpleText } from "styles/common";
import theme from "core/theme/theme";

export const Wrapper = styled.div`
  padding: 20px 16px;
`;

export const Title = styled.h4`
  ${bigTitleText};
  margin-bottom: 16px;
`;

export const NoData = styled.p`
  ${simpleText};
  color: ${theme.color.silverGray};
  text-align: center;
`;
