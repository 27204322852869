import styled from "styled-components";

export const Form = styled.form`
  &>div {
    margin-bottom: 10px;
  }

  &>button {
    margin-top: 30px;
  }
`;