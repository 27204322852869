// types
import { IInputFilter } from "core/types/filter.types";
// components
import SimpleInput from "./components/SimpleInput";
import InputSelect from "./components/InputSelect";
// styles
import * as S from "./style";

interface InputFilterProps extends IInputFilter {
  initValue: string | undefined,
  setFilterValue: (value: string) => void,
}

const InputFilter = ({
  initValue,
  label,
  placeholder,
  multipleValue,
  options,
  optionsIsLoading,
  setFilterValue,
  fetchOptions = () => { },
}: InputFilterProps) => {

  return (
    <S.Wrapper>
      <S.Label>{label}</S.Label>
      {!options &&
        <SimpleInput
          placeholder={placeholder}
          initValue={initValue}
          multipleValue={multipleValue}
          setFilterValue={setFilterValue}
        />
      }
      {options &&
        <InputSelect
          placeholder={placeholder}
          initValue={initValue}
          options={options}
          optionsIsLoading={optionsIsLoading}
          setFilterValue={setFilterValue}
          fetchOptions={fetchOptions}
        />
      }
    </S.Wrapper>
  )
}

export default InputFilter;