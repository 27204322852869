// types
import { EventEntryType } from 'core/models/events/model.types';
// utils
import { UTCFormatDate } from 'core/utils/date';
import { useHandbookSelector } from 'core/store/selectorHooks';
// constants
import { userDateFormat, userTimeFormat } from 'core/constants/dateFormats';
// components
import { StatusEvent } from 'components/common';
// styles
import * as S from './style';

interface IDescription {
  historyEntry: EventEntryType
}

const Description = ({ historyEntry }: IDescription) => {
  switch (historyEntry.eventType) {
    case 'change_status': return <StatusEvent oldStatusValue={historyEntry.oldStatus} newStatusValue={historyEntry.newStatus} />
    case 'new_message': return <S.DescriptionText>{historyEntry.message.message || ''}</S.DescriptionText>
    case 'download': return <S.DescriptionText>{historyEntry.uploadMediaTitle?.join(', ') || ''}</S.DescriptionText>
    default: return <p></p>
  }
}

interface IHistoryList {
  history: EventEntryType[] | null,
  className?: string,
}

const HistoryList = ({
  history,
  className,
}: IHistoryList) => {
  const { handbook } = useHandbookSelector(undefined);

  if (!history) {
    return <></>
  }

  return (
    <S.Wrapper className={className}>
      <S.Line $header>
        <p>Действие</p>
        <p>Описание действия</p>
        <p>Редактор</p>
        <p>Редакция</p>
        <p>Дата</p>
        <p>Время</p>
      </S.Line>
      {history.map((item, key) =>
        <S.Line key={key}>
          <p>{handbook?.eventType[item.eventType]}</p>
          <Description historyEntry={item} />
          <p>{item.eventAuthor}</p>
          <p>{item.department}</p>
          <p>{UTCFormatDate(item.timestamp, userDateFormat)}</p>
          <p>{UTCFormatDate(item.timestamp, userTimeFormat)}</p>
        </S.Line>
      )}
    </S.Wrapper>
  )
}

export default HistoryList;